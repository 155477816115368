import {useState} from "react";
import {Link} from "react-router-dom";
import DocumentMeta from "react-document-meta";
import {getMetaData} from "../lib/helperFunctions";
import Select from "../components/Select";
import background from "../assets/images/background.png";
import NavBar from "./client/NavBar";
import ProductCards from "../components/ProductCards";
const styles = {
    display: "flex", 
    flexDirection: "column", 
    alignItems: "center", 
    backgroundImage: `url(${background})`, 
    backgroundPosition: 'top',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '100vw',
    height: '100vh'}
    
function Home() {
    const meta = getMetaData("Book learner's test, driver's licence & driving lessons near you");
    const provinces = ["Gauteng","Mpumalanga","Limpopo","Eastern Cape","Kwazulu Natal","North West","Western Cape","Northern Cape","Free State"];
    const [selected, setSelected] = useState(provinces[0]);
    return ( 
        <>
        <NavBar/>
        <div style={styles}>
            <DocumentMeta {...meta} />
            {/* <ProductCards /> */}
            <h1 style={{color: "white", textShadow: "3px 3px 12px #FB2576", fontSize: "4vw", textAlign: "center", marginTop: "33vh"}}>Get learner's & licence in your nearest city</h1>
            <Select itemsArray={provinces} selected={selected} onChange={e=>setSelected(e.target.value)} size="200px"/>
            <Link to={`/driving-schools/province/${selected}`}>
                <button className='btn btn-danger'>Find driving schools</button>
            </Link>
        </div>
        </>
     );
}

export default Home;


