import BookingForm from "../../components/BookingForm";
import { useFetchAuth, useCheckAdmin, useCheckSchool } from "../../hooks/auth";
import NavBar from "./NavBar";
const styles = {
    container: {padding: "10px", display: 'flex',  justifyContent:'center'},
}   
function BookingLicence() {
    useFetchAuth();
    useCheckAdmin();
    useCheckSchool();
    return ( 
    <>
    <NavBar />
    <div style={styles.container}>
        <BookingForm title="Licence"/>
    </div> 
    </>
        
    );
}

export default BookingLicence;