import React, { useState, useEffect } from "react";
import axios from "axios"; // Import axios for making API requests
import { Card, Container, Modal, Button } from "react-bootstrap";
import SubscriptionBanner from "./SubscriptionBanner";
import { Link } from "react-router-dom";
import { getFileRequest } from '../requests/apiRequests';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile  } from '@fortawesome/free-solid-svg-icons';
import CustomButton from "./CustomButton";
import { MdDone } from "react-icons/md";
import { CLIENT_RELATIVE_PATH, BASE_URL } from "../config/config";

function Videos({ videos, code, subscription, daysLeft, material }) {
  // State variables
  const initialCategory = videos.length > 0 ? videos[0].category : null;
  const [selectedCategory, setSelectedCategory] = useState(initialCategory);
  const [showModal, setShowModal] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [videoDurations, setVideoDurations] = useState({});

  // Extract unique categories
  const uniqueCategories = [...new Set(videos.map((video) => video.category))];

  // Filter videos based on the selected category
  const filteredVideos = selectedCategory
    ? videos.filter((video) => video.category === selectedCategory)
    : videos;

  // Extract video ID from YouTube video URL
  const extractVideoId = (videoUrl) => {
    const match = videoUrl.match(/\/embed\/([^?]+)/);
    return match ? match[1] : null;
  };

  // Fetch video duration
  const fetchVideoDuration = async (videoUrl) => {
    const videoId = extractVideoId(videoUrl);
    if (videoId) {
      try {
        const response = await axios.get(
          `https://www.youtube.com/watch?v=${videoId}`
        );
        const html = response.data;
        // const html = '<span><span class="ytp-time-current">0:13</span><span class="ytp-time-separator"> / </span><span class="ytp-time-duration">15:04</span></span>'
        // Parse HTML to extract video duration (using regular expression or other method)
        const duration = extractDurationFromHtml(html);
        return duration;
      } catch (error) {
        console.error("Error fetching video duration:", error);
        return null;
      }
    }
    return null;
  };

  // Parse HTML to extract video duration (dummy implementation)
  const extractDurationFromHtml = (html) => {
    // Regular expression to find duration information
    const durationRegex = /<span class="ytp-time-duration">([^<]+)<\/span>/;
    const match = html.match(durationRegex);
    return match ? match[1] : null;
  };

  // Fetch video durations for all videos
  useEffect(() => {
    const fetchDurations = async () => {
      const durations = {};
      for (const video of videos) {
        const duration = await fetchVideoDuration(video.video);
        durations[video.video] = duration;
      }
      setVideoDurations(durations);
    };
    fetchDurations();
  }, [videos]);

  // Handle video click
  const handleVideoClick = (video) => {
    setSelectedVideo(video);
    setShowModal(true);
  };

  // Handle modal close
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedVideo(null);
  };

  // Function to extract YouTube video thumbnail URL from embed code
  const extractThumbnailUrl = (videoUrl) => {
    const videoId = extractVideoId(videoUrl);
    return videoId
      ? `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`
      : null;
  };

  return (
    <>
      <Container className="container-custom">
        <div className="row mt-3">
          <SubscriptionBanner
            subscription={subscription}
            daysLeft={daysLeft}
            product={"Training Videos"}
          />
          {/* {(daysLeft <= 0 || subscription === false) && (
           <>
           <div className="col-12">
              <h5>
                Please upgrade your package to get access to all training videos
              </h5>
            </div>
           </> 
          )} */}
          {daysLeft >= 0 && subscription !== false && (
            <>
              <div className="categories-section mt-1">
                <h2 className="dashboard-title">Categories</h2>
                {uniqueCategories.map((category, i) => (
                  <Link
                    key={i}
                    onClick={() => setSelectedCategory(category)}
                    className={`category-link ${
                      selectedCategory === category ? "selected-category" : ""
                    }`}
                  >
                    {category}
                  </Link>
                ))}
              </div>
              <div className="videos-section mt-1">


                <div className="col-12">
                  <h2 className="dashboard-title" style={{ padding: "0px 0px", margin: "0px 0px" }}>
                    Training Videos ({selectedCategory})
                  </h2>
                </div>

                <div className="col-lg-12">
                  <strong>Study Material</strong>
                  {material.map(
                    (material, i) =>
                      material.category === filteredVideos[0].category && (
                        <div className="col-lg-6" key={i}>
                          <p className="my-2">
                            
                              <Link style={{ textDecoration: "none", }} target="_blank" to={getFileRequest(`/files/material/${material.filename}`)}>
                                {material.title} <FontAwesomeIcon icon={faFile} />
                              </Link>
                            
                          </p>
                        </div>
                      )
                  )}
                </div>

                {filteredVideos.map((video, i) => (
                  <>
                    <div className="col-lg-3 my-3" key={i}>
                      <Card
                        className="video-card"
                        onClick={() => handleVideoClick(video)}
                      >
                        {/* <Card.Header className="video-card-header">
                        
                      </Card.Header> */}
                      {/* <Card.Body></Card.Body> */}
                        <div className="video-card-body">
                          <img
                            src={extractThumbnailUrl(video.video)}
                            alt={video.title}
                            className="img-fluid"
                            style={{ width: "100%", height: "auto" }}
                          />
                        </div>
                        <Card.Footer>
                          {/* <small>Duration: {videoDurations[video.video]}</small> */}
                          <h6>{video.title}</h6>
                        </Card.Footer>
                      </Card>
                    </div>
                  </>
                ))}
              </div>
            </>
          )}
        </div>

        <Modal show={showModal} onHide={handleCloseModal} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>{selectedVideo?.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedVideo && (
              <iframe
                width="100%"
                height="400px"
                src={selectedVideo.video}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </>
  );
}

export default Videos;
