import React, { useState } from "react";
import Input from "./Input";
import CustomButton from "./CustomButton";
import Loader from "../components/Loader";
import { filesPostRequest } from "../requests/apiRequests";
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import { useFetchIPInfo } from "../hooks/auth";
const styles = {
  body: {
    display: "flex",
    flexDirection: "column",
    width: "70vw",
    backgroundColor: "white",
    borderRadius: "8px",
    padding: "20px 15px",
    margin: "30vh",
  },
  rightButton: {
    marginLeft: "5px",
  },
  error: {
    color: "red",
  },
};

const ClientRegisterForm = () => {
  const formData = new FormData();
  let navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [formFields, setFormFields] = useState({ type: "c", status: 0 });
  // const [profile_img, setCoverImage] = useState(null);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [ipInfo, setIpInfo] = useState(null);

  useFetchIPInfo(setIpInfo)

  // const validateImageExtension = (filename) => {
  //   const validExtensions = ["jpg", "jpeg", "png", "gif"];
  //   const extension = filename.split(".").pop().toLowerCase();
  //   return validExtensions.includes(extension);
  // };

  // const handleCoverImageInputChange = (event) => {
  //   const image = event.target.files[0];

  //   if (image) {
  //     const validExtensions = validateImageExtension(image.name);

  //     if (validExtensions) {
  //       const reader = new FileReader();

  //       reader.onload = function (e) {
  //         const img = new Image();
  //         img.src = e.target.result;

  //         img.onload = function () {
  //           const width = img.width;
  //           const height = img.height;

  //           if (
  //             width >= 500 &&
  //             width <= 600 &&
  //             height >= 500 &&
  //             height <= 600
  //           ) {
  //             setCoverImage(image);
  //           } else {
  //             toast.error(
  //               "Image dimensions must be between 500-600px (width) and 500-600px (height)",
  //               {
  //                 position: toast.POSITION.TOP_CENTER,
  //                 autoClose: 5000,
  //               }
  //             );

  //             setCoverImage(null); // Clear the image field
  //           }
  //         };
  //       };

  //       reader.readAsDataURL(image);
  //     } else {
  //       toast.error(
  //         "Invalid image format. Supported formats: jpg, jpeg, png, gif",
  //         {
  //           position: toast.POSITION.TOP_CENTER,
  //           autoClose: 5000,
  //         }
  //       );

  //       setCoverImage(null); // Clear the image field
  //     }
  //   }
  // };

  const validateStepOne = () => {
    const errors = {};

    // Validate email format using regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formFields.email) {
      errors.email = "Email address is required";
    } else if (!emailRegex.test(formFields.email)) {
      errors.email = "Invalid email address format";
    }

    // Validate password and confirm password match
    if (!formFields.password) {
      errors.password = "Password is required";
    } else if (formFields.password.length < 5) {
      errors.password = "Passwords must be at least 5 characters long";
    } else if (formFields.password !== formFields.password2) {
      errors.password2 = "Passwords do not match";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const validateStepTwo = () => {
    const errors = {};

    // Validate Name
    if (!formFields.name) {
      errors.name = "Name is required";
    }

    // Validate Surname
    if (!formFields.surname) {
      errors.surname = "Surname is required";
    }

    // Validate Cell Number
    if (!formFields.cell_number) {
      errors.cell_number = "Cell Number is required";
    }

    // Validate profile_img
    // if (!profile_img) {
    //   errors.profile_img = "Select Cover Picture";
    // }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const nextStep = () => {
    if (step === 1) {
      if (validateStepOne()) {
        setStep((prev) => prev + 1);
      }
    }
  };

  const prevStep = () => setStep((prev) => prev - 1);

  const handleChange = (event) => {
    setFormFields({ ...formFields, [event.target.name]: event.target.value });
    // formData.append(event.target.name, event.target.value);
  };

  const submitForm = async (e) => {
    if (validateStepTwo()) {
      setLoading(true);

      
      let mobileNumber = formFields.cell_number || '';

      if (mobileNumber.startsWith('0') && mobileNumber.length === 10) {
          mobileNumber = '27' + mobileNumber.slice(1); // Replace the leading '0' with '27'
      }

      if (!/^(?!\+)(\b27\d{9}\b)$/.test(mobileNumber)) {
        toast.error('Please enter a valid SA mobile number starting with 0 or the country code.', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000
        });
        return
      }

      // formData.append("ip_address", ipInfo.ip);
      // formData.append("country", ipInfo.country);
      // formData.append("user_province", ipInfo.region);
      // formData.append("user_city", ipInfo.city);
      formData.append("ip_address", "0.0.0.1");
      formData.append("country", "no-contry");
      formData.append("user_province", "no-province");
      formData.append("user_city", "no-city");

      // formData.append("profile_img", profile_img);

      Object.entries(formFields).forEach(([key, value]) => {
        formData.append(key, value);
      });

      try {
        const response = await filesPostRequest("/users/register", formData);
        const json = await response?.json();
        console.log(response);
      
        if (response.status === 200) {
          setLoading(false);
          setStep((prev) => prev - 1);

          // Get the saved URL from local storage
          const redirectPath = localStorage.getItem('redirectPath') || '/';

          if(redirectPath !== '/'){

              localStorage.removeItem('redirectPath');
              // Redirect the user back to the saved URL
              window.location.href = redirectPath;
          }

          navigate("/login");
      
          toast.success("User was registered successfully! Email to activate your account is sent to your Email!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 20000,
          });
      
        } else {
          setLoading(false);
          setStep((prev) => prev - 1);
          
          const errorMessage = json.message || "User was not registered!";
          toast.error(errorMessage, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
          });
        }
      } catch (error) {
        console.error(error);
      }
      
    }
  };

  // loading
  // if (loading) {
  //   return (
  //     <>
  //       <Loader />
  //     </>
  //   );
  // }
  switch (step) {
    case 1:
      return (
        <>
          <div style={styles.body}>
            <h4 className="text-center">1. Account Information</h4>
            {/* {loading && <Loader />} */}
            <hr />
            <Input
              label="Email address:"
              name="email"
              type="email"
              placeholder="Enter email"
              onChange={handleChange}
              value={formFields.email || ""}
            />
            {errors.email && <span style={styles.error}>{errors.email}</span>}
            <Input
              label="Password:"
              name="password"
              type="password"
              placeholder="Enter Password"
              onChange={handleChange}
              value={formFields.password || ""}
            />
            {errors.password && (
              <span style={styles.error}>{errors.password}</span>
            )}
            <Input
              label="Confirm Password:"
              name="password2"
              type="password"
              placeholder="Confirm Password"
              onChange={handleChange}
              value={formFields.password2 || ""}
            />
            {errors.password2 && (
              <span style={styles.error}>{errors.password2}</span>
            )}
            <hr />
            <div className="d-flex">
              <CustomButton text="Next" size={'md'} onClick={nextStep} disabled={loading} loading={loading}/>
            </div>
          </div>
        </>
      );
    case 2:
      return (
        <div style={styles.body}>
          <h4 className="text-center">2. Client Profile</h4>
          <hr />
          <Input
            label="Name:"
            type="text"
            placeholder="Enter Name"
            name="name"
            onChange={handleChange}
            value={formFields.name || ""}
          />
          {errors.name && <span style={styles.error}>{errors.name}</span>}
          <Input
            label="Surname:"
            type="text"
            placeholder="Enter Surname"
            name="surname"
            onChange={handleChange}
            value={formFields.surname || ""}
          />
          {errors.surname && <span style={styles.error}>{errors.surname}</span>}
          <Input
            label="Cell Number:"
            type="text"
            placeholder="Enter Cell Number"
            name="cell_number"
            onChange={handleChange}
            value={formFields.cell_number || ""}
          />
          {
            (() => {
              let mobileNumber = formFields.cell_number || '';
              if (mobileNumber.startsWith('0') && mobileNumber.length === 10) {
                mobileNumber = '27' + mobileNumber.slice(1); // Replace the leading '0' with '27'
              }

              return (
                mobileNumber.length > 0 && !/^(?!\+)(\b27\d{9}\b)$/.test(mobileNumber) &&
                <p className="alert alert-warning">
                  Please enter a valid SA mobile number starting with 0 or the country code.
                </p>
              );
            })()
          }

          {errors.cell_number && (
            <span style={styles.error}>{errors.cell_number}</span>
          )}
          {/* <Input
            label="Profile Picture:"
            type="file"
            name="profile_img"
            onChange={handleCoverImageInputChange}
          />
          {errors.profile_img && (
            <span style={styles.error}>{errors.profile_img}</span>
          )} */}

          <hr />
          <div className="d-flex">
            <CustomButton text="Prev" size={'md'} color="dark" onClick={prevStep} disabled={loading}/>
            <CustomButton
              text="Submit"
              size={'md'}
              style={styles.rightButton}
              onClick={submitForm}
              disabled={loading} loading={loading}
            />
          </div>
        </div>
      );
 
    default:
      return <h4 className="text-center">Form submitted successfully</h4>;
  }
};

export default ClientRegisterForm;
