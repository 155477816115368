import { Link } from 'react-router-dom';
import React from "react";
import StatusCard from '../../components/StatusCard';
import { MdPendingActions, MdApproval,
   MdAddCircle } from 'react-icons/md';
import { getMetaData } from "../../lib/helperFunctions";
import usePackages from "../../hooks/usePackages";
import { useAuthUser, useAuthHeader } from "react-auth-kit";
import { useFetchAuth, useCheckClient } from "../../hooks/auth";
import NavBar from './NavBar';
import { SCHOOL_RELATIVE_PATH } from '../../config/config';
const styles = {
  body: {
    padding: "100px 50px 50px 50px",
  },
};

function Packages() {
  useFetchAuth(); 
  useCheckClient();
  const user = useAuthUser()();
  const authHeader = useAuthHeader();
  const meta = getMetaData("Driving School | Partner with SimplifyDrive");
  const { packages, loading } = usePackages(authHeader());

  const getPackageTpye = (array, type) => {
      return array.filter(item => item.type === type);
  };

  const lessonPackageNum = getPackageTpye(packages, "lesson").length;
  const testPackageNum = getPackageTpye(packages, "test").length;

  const parentDir = `${SCHOOL_RELATIVE_PATH}/packages`;
  return (
    <div className="App">
      <NavBar/>
      <div className="dashboard-container">
        {/* <div className="dashboard-title-container">
          <h2 className="dashboard-title">Packages</h2>
        </div> */}
        
        <div className="dashboard-header">
          <div className="dashboard-links">
          <div className="dashboard-links">
            <Link to={SCHOOL_RELATIVE_PATH} className="dashboard-link">
              Dashboard
            </Link>
            <span className="dashboard-separator">/</span>
            <Link className="current-link">
            Packages
            </Link>
          </div>

          </div>
          <Link className="dashboard-link">
            <MdAddCircle size={35} />
          </Link>
        </div>
        <div className="row">
          <div className="col-sm-6 mt-1">
            <StatusCard href={`${parentDir}/lesson`} title="Lesson Packages" number={lessonPackageNum} icon={<MdPendingActions color='green'/>}/>
          </div>
          <div className="col-sm-6 mt-1">
              <StatusCard title="Test Packages" href={`${parentDir}/test`} number={testPackageNum} icon={<MdApproval color='#20c997'/>}/>
          </div>
                
        </div>  
        
      </div> 
      </div>
  );
}

export default Packages;
