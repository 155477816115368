// import {Table} from 'react-bootstrap';
import '../../App.css';

import { useAuthUser, useAuthHeader } from "react-auth-kit";
import { Link } from 'react-router-dom';
import React, { useState } from "react";
import StatusCard from '../../components/StatusCard';
import { MdPendingActions, MdApproval, MdAddTask,
   MdAddCircle } from 'react-icons/md';
   import useDetailedBookings from "../../hooks/useDetailedBookings";
   import { useFetchAuth, useCheckClient } from "../../hooks/auth";
import NavBar from './NavBar';
import { SCHOOL_RELATIVE_PATH } from '../../config/config';
export default function ScheduledBookings() {

  useFetchAuth(); 
  useCheckClient();

  const user = useAuthUser()();
  const authHeader = useAuthHeader();
  const lesson = useDetailedBookings("lesson", authHeader());
  const learners = useDetailedBookings("learners", authHeader());
  const licence = useDetailedBookings("licence", authHeader());

  const getScheduled = (array, scheduled) => {
      return array.filter(item => item.scheduled === scheduled);
  };

  const lessonNum = getScheduled(lesson, 1).length;
  const learnersNum = getScheduled(learners, 1).length;
  const licenceNum = getScheduled(licence, 1).length;



  const parentDir = `${SCHOOL_RELATIVE_PATH}/scheduled-bookings`;
    return ( 
      <div className="App">
        <NavBar />
      <div className="dashboard-container">
        {/* <div className="dashboard-title-container">
          <h2 className="dashboard-title">Scheduled Bookings</h2>
        </div> */}
        
        <div className="dashboard-header">
          <div className="dashboard-links">
          <div className="dashboard-links">
            <Link to={SCHOOL_RELATIVE_PATH} className="dashboard-link">
              Dashboard
            </Link>
            <span className="dashboard-separator">/</span>
            <Link className="current-link">
            Scheduled Bookings
            </Link>
          </div>

          </div>
          <Link className="dashboard-link">
            <MdAddCircle size={35} />
          </Link>
        </div>
        <div className="row">
          <div className="col-sm-4 mt-1">
            <StatusCard href={`${parentDir}/lesson`} title="Scheduled Lesson Bookings" number={lessonNum} icon={<MdPendingActions color='green'/>}/>
          </div>
          <div className="col-sm-4 mt-1">
              <StatusCard title="Scheduled Leaners Bookings" href={`${parentDir}/learners`} number={learnersNum} icon={<MdApproval color='#20c997'/>}/>
          </div>
          <div className="col-sm-4 mt-1">
            <StatusCard title="Scheduled Licence Bookings" href={`${parentDir}/licence`} number={licenceNum} icon={<MdAddTask color='#6f42c1'/>}/>
          </div>
                
        </div>  
        
      </div> 
      </div>   
     );
}

