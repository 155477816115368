// import {Table} from 'react-bootstrap';
import '../../App.css';

import { useAuthUser, useAuthHeader } from "react-auth-kit";
import { Link, useParams } from 'react-router-dom';
import React, { useState } from "react";

import EnhancedTable from "../../components/EnhancedTable";
import useDetailedBookings from "../../hooks/useDetailedBookings";
import { useFetchAuth, useCheckClient } from "../../hooks/auth";
import { getFileRequest } from '../../requests/apiRequests';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { MdAddCircle } from 'react-icons/md';
import NavBar from './NavBar';
import { SCHOOL_RELATIVE_PATH } from '../../config/config';

export default function DetailedScheduledBookings() {

  useFetchAuth(); 
  useCheckClient();

  const user = useAuthUser()();
  const authHeader = useAuthHeader();
  const { type } = useParams();
  const [currentPage, setCurrentPage] = useState(1);

  const bookings = useDetailedBookings(type, authHeader());

  const getScheduled = (array, scheduled) => {
    return array.filter(item => item.scheduled === scheduled);
};

const dbookings = getScheduled(bookings, 1);

  function handlePageChange(pageNumber) {
    setCurrentPage(pageNumber);
  }


  const onDeleteBooking = async (id) => {

  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
  
    const options = {
      // weekday: "long",
      day: "numeric",
      month: "long",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
  
    if (!isNaN(date)) {
      // Check if the date is valid
      return date.toLocaleDateString(undefined, options);
    } else {
      // If the date is not valid, return it as is
      return dateString;
    }
  };


  const BookingsData = (bookings, onDeleteBooking) => {
    return bookings.map((booking, i) => {
      const formattedDate = formatDate(booking.date_created);
      
      const commonProperties = {
        id: i + 1,
        type: booking.type.charAt(0).toUpperCase() + booking.type.slice(1),
        price: `R ${booking.price_booked_at.toFixed(2)}`,
        date_booked: formattedDate,
        payment: (
          <>
            {booking.paid === 1 ? (
              <button className="btn btn-outline-success border border-success m-1">
                <i className="fa fa-paper-plane" aria-hidden="true"></i> Paid In Full
              </button>
            ) : (
              <button className="btn btn-outline-danger border border-danger m-1">
                <i className="fa fa-paper-plane" aria-hidden="true"></i> No Paid
              </button>
            )}
          </>),
        actions: (
          <>
            <Link to={`${SCHOOL_RELATIVE_PATH}/edit-booking/${booking.booking_ID}/${booking.type}`} className="btn btn-outline-primary border border-primary m-1"><i className="fa fa-time" aria-hidden="true"></i> Edit</Link>
            <button onClick={() => onDeleteBooking(booking.booking_ID)} className="btn btn-outline-danger border border-danger m-1"><i className="fa fa-trash"  aria-hidden="true"></i> Delete</button>
          </>
        ),
      };
  
      if (booking.type != "lesson") {
        const idCopyLink = (
          <Link
            target='_blank'
            to={getFileRequest(`/files/image/${booking.ID_copy}`)}
            className="link-style"
          >
            ID Copy <FontAwesomeIcon icon={faDownload} />
          </Link>
        );
  
        return {
          ...commonProperties,
          days: booking.days || "None",
          start_date: formatDate(booking.start_date),
          end_date: formatDate(booking.end_date),
          id: idCopyLink,
          date: formatDate(booking.datee),
          time: booking.timee,
        };
      } else {
        return {
          ...commonProperties,
          days: booking.days || "None",
          start_date: formatDate(booking.start_date),
          end_date: formatDate(booking.end_date),
          start_time: formatDate(booking.start_time),
          end_time: formatDate(booking.end_time),
        };
      }
    });
  };
  const columns = type === "lesson"
  ? ["Type", "Price", "Date",  "Date_Booked", "Payment", "Days", "Start_Date", "End_Date", "Start_Time", "End_Time", "Actions"]
  : ["Type", "Price", "Date_Booked", "Payment", "Days", "Start_Date", "End_Date", "ID", "Date", "Time", "Actions"];

  return (

    <div className="App">
      <NavBar />
      <div className="dashboard-container">
        {/* <div className="dashboard-title-container">
          <h2 className="dashboard-title">Detailed Scheduled Bookings</h2>
        </div> */}
        
        <div className="dashboard-header">
          <div className="dashboard-links">
          <div className="dashboard-links">
            <Link to={SCHOOL_RELATIVE_PATH} className="dashboard-link">
              Dashboard
            </Link>
            <span className="dashboard-separator">/</span>
            <Link to={`${SCHOOL_RELATIVE_PATH}/scheduled-bookings`} className="dashboard-link">
            Scheduled Bookings
            </Link>
            <span className="dashboard-separator">/</span>
            <Link className="current-link">
            Scheduled {type.charAt(0).toUpperCase() + type.slice(1)} bookings
            </Link>
          </div>

          </div>
          <Link className="dashboard-link">
            <MdAddCircle size={35} />
          </Link>
        </div>

        <EnhancedTable 
          data={BookingsData(dbookings, onDeleteBooking)}
          columns={columns}
          title={"Scheduled "+ type.charAt(0).toUpperCase() + type.slice(1) +" bookings"}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        
        />

    </div>
    </div>
  );
}

