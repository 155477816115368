import QuoteForm from "../../components/QuoteForm";
import { useFetchAuth, useCheckAdmin, useCheckSchool } from "../../hooks/auth";
const styles = {
    container: {padding: "10px", display: 'flex',  justifyContent:'center'},
}   
function QuoteLicence() {
    useFetchAuth();
    useCheckAdmin();
    useCheckSchool();
    return ( 
        <div style={styles.container}>
            <QuoteForm title="Licence"/>
        </div> 
    );
}

export default QuoteLicence;