import {Button} from 'react-bootstrap';
function CustomButton({disabled=false, text, onClick, color, size, style, className, loading}) {
    return(
        <Button disabled={disabled} type='submit' style={style} variant={(color == undefined) ? "danger" : color} className={`btn btn-${(size == undefined) ? "sm" : size} ${className}`} onClick={onClick}>
            {disabled && (
                <span className="mx-1"></span>
            )}

            {loading && (
                <span className="spinner-border spinner-border-sm mx-2"></span>
            )}

            {text}
        </Button>
    )
}
export default CustomButton;