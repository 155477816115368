import { Link } from 'react-router-dom';
import React, { useState, useEffect } from "react";
import Button from './CustomButton';
import useProducts from "../hooks/useProducts";
import { useAuthHeader } from "react-auth-kit";
import { CLIENT_RELATIVE_PATH, BASE_URL } from '../config/config';

function SubscriptionBanner({ subscription, daysLeft, product }) {
  const authHeader = useAuthHeader();
  const [price, setPrice] = useState(0.00);
  const { products } = useProducts(authHeader());

  useEffect(() => {
    if (products.length > 0) {
      const myProduct = products.find(my_product => my_product.name === product);
      if (myProduct) {
        setPrice(myProduct.price);
      }
    }
  }, [products, product]);

  //Remove space and caps in the product name
  const formattedProduct = product.toLowerCase().replace(/\s+/g, '-');
  return (
    <>
      {!subscription && (
        <div className='marketing'>
          <p>
            <strong>
              Get full access to {product} {product === "Learner's Simulation Test" && 'with added time to practice questions'} for only R{price.toFixed(2)}. Access is for 30 days only!
            </strong>
            <strong>
              <Link to={`${BASE_URL + CLIENT_RELATIVE_PATH}/subscribe/${formattedProduct}`}>
                <Button text={"Upgrade"} color="dark" />
              </Link>
            </strong>
          </p>
        </div>

        
      )}

      {subscription && (
        <div className='subscribed'>
          <p>
            <strong>
              You are fully subscribed. Your subscription is left with {daysLeft} days! Enjoy your {product}!
            </strong>
          </p>
        </div>
      )}
    </>
  );
}

export default SubscriptionBanner;
