import React from 'react';
import Slider from 'react-slick';
import { Container, Card } from 'react-bootstrap';
import { useAuthHeader } from 'react-auth-kit';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const styles = {
    formContainer: { width: "100%", padding: "0 0px" },
    heading: { textAlign: "center", marginBottom: "0px" },
    testimonialCard: { textAlign: "center", padding: "0px" },
    testimonialImage: { width: "100%", borderRadius: "0%", margin: "0 auto", objectFit: "cover" }
};

const testimonials = [
    {
        image: require("../assets/signs/Sign-O9.png"),
        name: "John Doe",
        text: "This service is fantastic! It changed my life."
    },
    {
        image: require("../assets/signs/Sign-O9.png"),
        name: "Jane Smith",
        text: "I can't believe how easy it was to use this platform. Highly recommended!"
    },
    {
        image: require("../assets/signs/Sign-O9.png"),
        name: "Bob Johnson",
        text: "The support team is amazing. They helped me every step of the way."
    },
    {
        image: require("../assets/signs/Sign-O9.png"),
        name: "Alice Brown",
        text: "The features are exactly what I needed. Great job!"
    },
    {
        image: require("../assets/signs/Sign-O9.png"),
        name: "Charlie White",
        text: "I will definitely be using this service again. Wonderful experience!"
    },
    {
        image: require("../assets/signs/Sign-O9.png"),
        name: "Eve Black",
        text: "The user interface is so intuitive and easy to use."
    },
];

const testimonialImages = Array.from({ length: 21 }, (_, i) => ({
    image: require(`../assets/testimonials/${i + 1}.png`)
}));

function Testimony({ slidesToShow }) {
    const authHeader = useAuthHeader();

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: slidesToShow,
        slidesToScroll: slidesToShow,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: Math.min(slidesToShow, 3),
                    slidesToScroll: Math.min(slidesToShow, 3),
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: Math.min(slidesToShow, 2),
                    slidesToScroll: Math.min(slidesToShow, 2),
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const handleImageClick = (image) => {
        window.open(image, "_blank");
    };

    return (
        <Container fluid style={styles.formContainer}>
            
            <h2 className='mb-3' style={styles.heading}>Our Happy Customers</h2>
            <Slider {...settings}>
                {testimonialImages.map((testimonialImage, index) => (
                    <div key={index}>
                        <Card style={styles.testimonialCard}>
                            <Card.Img
                                variant="top"
                                src={testimonialImage.image}
                                style={styles.testimonialImage}
                                alt={`Testimonial ${index + 1}`}
                                onClick={() => handleImageClick(testimonialImage.image)}
                            />
                        </Card>
                    </div>
                ))}
            </Slider>
        </Container>
    );
}

export default Testimony;
