import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthUser, useIsAuthenticated } from 'react-auth-kit'
import axios from "axios";

export function useFetchAuth() {

  let navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated()
  const auth = useAuthUser()()

  useEffect(() => {

    if(!isAuthenticated()){
      navigate("/login");
    }else{
      
    }
    
  }, []);

}


export function useCheckAdmin() {

  let navigate = useNavigate();
  const auth = useAuthUser()()

  useEffect(() => {
    if(auth?.type === 'a'){
      navigate("/admin");
    }
  }, []);

}

export function useCheckSchool() {

  let navigate = useNavigate();
  const auth = useAuthUser()()

  useEffect(() => {
    if(auth?.type === 's'){
      navigate("/driving-school");
    }
  }, []);

}


export function useCheckClient() {

  let navigate = useNavigate();
  const auth = useAuthUser()()

  useEffect(() => {
    if(auth?.type === 'c'){
      navigate("/dashboard");
    }
  }, []);

}

export function useFetchIPInfo(setIpInfo) {

  const fetchIPInfo = async () => {
    try {
      //'API_KEY' the actual API key you obtained from ipinfo.io
      const apiKey = 'd52573c355a4e2';
      // const response = await axios.get(`https://ipinfo.io?token=${apiKey}`);
      // setIpInfo(response.data);
    } catch (error) {
      console.error('Error fetching IP information:', error);
    }
  }

  useEffect(() => {
    fetchIPInfo()
  }, []);

}