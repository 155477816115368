import React from 'react';

function Loader() {
    const styles = {
        loaderContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        },
        spinner: {
            width: '40px',
            height: '40px',
            border: '4px solid #f3f3f3',
            borderRadius: '50%',
            borderTop: '4px solid red',
            animation: 'spin 1s linear infinite',
        },
        loadingText: {
            marginTop: '10px',
            fontSize: '18px',
            color: 'red',
        },
        keyframes: `
            @keyframes spin {
                0% { transform: rotate(0deg); }
                100% { transform: rotate(360deg); }
            }
        `
    };

    return (
        <div style={styles.loaderContainer}>
            <style>
                {styles.keyframes}
            </style>
            <div style={styles.spinner}></div>
            <p style={styles.loadingText}>Loading...</p>
        </div>
    );
}

export default Loader;
