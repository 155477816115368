// import {Table} from 'react-bootstrap';
import '../../App.css';

import { useAuthUser, useAuthHeader } from "react-auth-kit";
import { Link, useParams } from 'react-router-dom';
import React, { useState } from "react";
import {  MdAddCircle } from 'react-icons/md';
import EnhancedTable from "../../components/EnhancedTable";
import useDetailedBookings from "../../hooks/useDetailedBookings";
import { useFetchAuth, useCheckAdmin, useCheckSchool } from "../../hooks/auth";
import { getFileRequest } from '../../requests/apiRequests';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import NavBar from './NavBar';
import { CLIENT_RELATIVE_PATH } from "../../config/config";

export default function ClientDetailedBookings() {

  useFetchAuth();
  useCheckAdmin();
  useCheckSchool();

  const user = useAuthUser()();
  const authHeader = useAuthHeader();
  const { type } = useParams();
  const [currentPage, setCurrentPage] = useState(1);

  const bookings = useDetailedBookings(type, authHeader());

  const getScheduled = (array, scheduled) => {
    return array.filter(item => item.scheduled === scheduled);
};

const dbookings = bookings;

  function handlePageChange(pageNumber) {
    setCurrentPage(pageNumber);
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
  
    const options = {
      // weekday: "long",
      day: "numeric",
      month: "long",
      year: "numeric",
      // hour: "2-digit",
      // minute: "2-digit",
    };
  
    if (!isNaN(date)) {
      // Check if the date is valid
      return date.toLocaleDateString(undefined, options);
    } else {
      // If the date is not valid, return it as is
      return dateString;
    }
  };


  const BookingsData = (bookings) => {
    return bookings.map((booking, i) => {
      const formattedDate = formatDate(booking.date_created);
      
      const commonProperties = {
        id: i + 1,
        type: booking.type.charAt(0).toUpperCase() + booking.type.slice(1),
        price: `R ${booking.price_booked_at.toFixed(2)}`,
        // date: formattedDate,
        actions: (
          <>
            {booking.paid === 1 ? (
              <button className="btn btn-outline-success border border-success m-1">
                <i className="fa fa-paper-plane" aria-hidden="true"></i> Paid In Full
              </button>
            ) : (
              <Link to={`${CLIENT_RELATIVE_PATH}/booking/school/${booking.school_ID}/${booking.type == "lesson" ? "lesson" : "test"}/${booking.package_ID}/${booking.booking_ID}`}>
                <button className="btn btn-outline-primary border border-primary m-1">
                  <i className="fa fa-paper-plane" aria-hidden="true"></i> Complete Payment
                </button>
              </Link>
            )}
          </>
        ),
      };
  
      if (booking.type != "lesson") {
        const idCopyLink = (
          <Link 
            target='_blank'
            to={getFileRequest(`/files/image/${booking.ID_copy}`)}
            className="link-style"
          >
            ID Copy <FontAwesomeIcon icon={faDownload} />
          </Link>
        );
  
        return {
          ...commonProperties,
          days: booking.days || "None",
          start_date: formatDate(booking.start_date),
          end_date: formatDate(booking.end_date),
          id: idCopyLink,
          date: formatDate(booking.datee),
          time: booking.timee,
        };
      } else {
        return {
          ...commonProperties,
          days: booking.days || "None",
          start_date: formatDate(booking.start_date),
          end_date: formatDate(booking.end_date),
        };
      }
    });
  };
  const columns = type === "lesson"
        ? ["Type", "Price", "Days", "Start_Date", "End_Date", "Actions"]
        : ["Type", "Price", "Days", "Start_Date", "End_Date", "ID", "Date","Time", "Actions"];

  const parentDir = CLIENT_RELATIVE_PATH;
  return (
    <>
    <NavBar/>
    <div className="App">
      <div className="dashboard-container">
        {/* <div className="dashboard-title-container">
          <h2 className="dashboard-title">Detailed Bookings</h2>
        </div> */}
        
        <div className="dashboard-header">
          <div className="dashboard-links">
          <div className="dashboard-links">
            <Link to={`/dashboard`} className="dashboard-link">
              Dashboard
            </Link>
            <span className="dashboard-separator">/</span>
            <Link to={`${parentDir}/bookings`} className="dashboard-link">
              Total Bookings
            </Link>
            <span className="dashboard-separator">/</span>
            <Link className="current-link">
            Total {type.charAt(0).toUpperCase() + type.slice(1)} Bookings
            </Link>
          </div>

          </div>
          <Link className="dashboard-link">
            <MdAddCircle size={35} />
          </Link>
        </div>

        <EnhancedTable 
          data={BookingsData(dbookings)}
          columns={columns}
          title={`Total ${type.charAt(0).toUpperCase() + type.slice(1)} Bookings`}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />

      </div>
    </div>
    </>   
  );
}

