import React from 'react';
import { Form } from 'react-bootstrap';

function TextArea({ label, required, placeholder, name, value, onChange, rows }) {
  return (
    <Form.Group className="mb-3" controlId={label}>
      <Form.Label><b>{label}</b></Form.Label>
      <Form.Control as="textarea" placeholder={placeholder} required={required === undefined} name={name} value={value} onChange={onChange} rows={rows || 3} />
    </Form.Group>
  );
}

export default TextArea;
