import {useEffect, useState} from 'react';
import {authenticatedGetRequest} from '../requests/apiRequests'
function usePackages(token, type="") {
    const [packages,setPackages] = useState([]);
    const [loading, setLoading] = useState(false);
    const getPackages = async () => {
        try {
          setLoading(true); // Set loading before sending API request

          let response, json;

          if (type === '') {
            ({ response, json } = await authenticatedGetRequest(`/packages`, token));
          } else {
            ({ response, json } = await authenticatedGetRequest(`/packages/${type}`, token));
          }
          
          if(response.status === 200){
            setPackages(json);
         }else if(response.status === 401){
          console.log("access denied");
         }
       } catch (error) {
         console.error(error);
       }
       setLoading(false); // Stop loading
     }
     useEffect(() => {
        getPackages();
      }, []);    
    return ( {packages, loading} );
}

export default usePackages;