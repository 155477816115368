import React from "react";
import SubscribeForm from "../../components/SubscribeForm";
import NavBar from "./NavBar";
import { useParams } from "react-router-dom";
import {getMetaData} from "../../lib/helperFunctions";
import DocumentMeta from "react-document-meta";

function Subscribe() {
  const { product } = useParams();  // Destructure to get the product parameter
  const meta = getMetaData(`Subscribe for ${product.replace(/\b\w/g, (char) => char.toUpperCase()).replace(/-(.)/g, (_, char) => ' ' + char.toUpperCase()).replace(/'s /g, "'s ").replace(/'S /g, "'s ")}`);
  return (
    <>
      <NavBar />
      <div className="App">
      <DocumentMeta {...meta} />
        <SubscribeForm product={product} /> 
      </div>
    </>
  );
}

export default Subscribe;
