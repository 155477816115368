import React from "react";
import { getMetaData } from "../../lib/helperFunctions";
import { useAuthUser, useAuthHeader } from "react-auth-kit";
import { useFetchAuth } from "../../hooks/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import NavBar from "./NavBar";
import ProductCards from "../../components/ProductCards";

const styles = {
  body: {
    padding: "100px 50px 50px 50px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
  heading: {
    fontSize: "36px",
    color: "#0cb004", // Text color for return message
    marginBottom: "20px",
  },
  description: {
    fontSize: "18px",
    marginBottom: "20px",
  },
  icon: {
    fontSize: "48px",
    color: "#0cb004", // Color of the success icon
    marginBottom: "20px",
  },
};

function Return() {
  // useFetchAuth();

  const user = useAuthUser()();
  const authHeader = useAuthHeader();
  const meta = getMetaData("Client | Partner with SimplifyDrive");

  return (
    <>
      <NavBar />
      <div style={styles.body}>
        <FontAwesomeIcon icon={faCheckCircle} style={styles.icon} />
        <h1 style={styles.heading}>Payment Successful</h1>
        <p style={styles.description}>
          Your payment has been successful. Thank you for your support.
        </p>
        {/* Advertising Links as Cards */}
        <ProductCards />
      </div>
    </>
  );
}

export default Return;
