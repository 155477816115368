import Carousel from 'react-bootstrap/Carousel';
import { getFileRequest } from '../requests/apiRequests';
function SchoolCarousel(props) {
    const {slider_images} = props;
  return (
    <Carousel style={{ margin: "10px 0px"}}>
        {slider_images.map(slider_img =>
            <Carousel.Item key={slider_img}>
                <img
                className="d-block w-100"
                src={getFileRequest(`/files/image/${slider_img}`)}
                alt="First slide"
                style={{borderRadius: 8, width: '100%'}}
                />
                {/* <Carousel.Caption> */}
                {/* <h3>First slide label</h3> */}
                {/* <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
                {/* </Carousel.Caption> */}
            </Carousel.Item>
      )}
    </Carousel>
  );
}

export default SchoolCarousel;