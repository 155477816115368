import React from 'react';
import NavBar from './NavBar';
import Card from 'react-bootstrap/Card';

function PrivacyPolicy(props) {
    return (
        <>
        <NavBar />
        <Card style={{ width: '10vm', display: 'flex', height: '100%', padding:"20px 10px", margin:"10px 10px"}}>
        <div className="container" style={{color: '#4b4b4b'}}>
            
            <h1 className='text-center'>Privacy Policy for SimplifyDrive</h1>
            <div className='text-center text-muted'><i><b>Effective Date: 25/01/2024</b></i></div>
            <br/>
            Thank you for using <i><b>SimplifyDrive</b></i> ("we," "us," or "our"). This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our web application. By accessing or using SimplifyDrive, you consent to the practices described in this Privacy Policy.
            <br/><br/>

            <h3>1. Information We Collect</h3>
            <b>1.1 Personal Information:</b><br/>
            To provide our simulation services and connect you with driving schools, we may collect personal information, including but not limited to your name, email address, phone number, and location.
            <br/>
            <b>1.2 Usage Information:</b><br/>
            We may collect information about your interactions with our web app, such as the pages you visit, the features you use, and the time and duration of your activities.
            <br/>
            <b>1.3 Device Information:</b><br/>
            We may collect information about the device you use to access our web app, including the device type, operating system, and browser type.
            <br/><br/>
            
            <h3>2. How We Use Your Information</h3>
            <b>2.1 Simulation Services:</b><br/>
            Your personal information may be used to provide you with learner's test simulations and other related services offered by SimplifyDrive.
            <br/>
            <b>2.2 Quotations from Driving Schools:</b><br/>
            If you choose to submit your contact details, we may share this information with driving schools in your area to provide you with free quotations.
            <br/>
            <b>2.3 Communication:</b><br/>
            We may use your contact details to communicate with you about your account, updates to our services, and promotional offers.
            <br/><br/>

            <h3>3. Information Sharing</h3>
            <b>3.1 Driving Schools:</b><br/>
            We may share your contact details with driving schools to facilitate the process of providing you with free quotations.
            <br/>
            <b>3.2 Legal Compliance:</b><br/>
            We may disclose your information if required by law or in response to a subpoena or similar legal process.
            <br/><br/>

            <h3>4. Security</h3>
            We take reasonable measures to protect your information from unauthorized access, disclosure, alteration, and destruction. However, please note that no method of transmission over the internet or electronic storage is 100% secure.
            <br/><br/>
            <h3>5. Your Choices</h3>
            <b>5.1 Opt-Out:</b><br/>
            You may opt-out of receiving promotional emails from us by following the instructions provided in the email.
            <br/>
            <b>5.2 Updating Your Information:</b><br/>
            You can update or correct your personal information by contacting us at <b>info@simplifydrive.co.za</b>.
            <br/><br/>

            <h3>6. Children's Privacy</h3>
            SimplifyDrive is not intended for children under the age of 13, and we do not knowingly collect personal information from children.
            <br/><br/>

            <h3>7. Changes to This Privacy Policy</h3>
            We reserve the right to update this Privacy Policy at any time. We will notify you of any changes by posting the new Privacy Policy on this page.
            <br/><br/>

            <h3>8. Contact Us</h3>
            If you have any questions or concerns about this Privacy Policy, please contact us at <b>info@simplifydrive.co.za</b>.<br/>
            By using <i><b>SimplifyDrive</b></i>, you agree to the terms and conditions of this Privacy Policy.            
        </div>
        </Card>
        </>
        
    );
}

export default PrivacyPolicy;