import {useState} from 'react';

import {useParams, Link} from "react-router-dom";
import SchoolCarousel from '../components/SchoolCarousel';
import Reviews from '../components/Reviews';
import LessonCard from '../components/LessonCard';
import {Card, Nav} from 'react-bootstrap';
import useSchoolPackages from '../hooks/useSchoolPackages';
import useSchool from '../hooks/useSchool';
import CustomButton from '../components/CustomButton';
import CustomModal from '../components/CustomModal';
import Alert from '../components/CustomAlert';
import { MdKeyboardReturn } from 'react-icons/md';
import QuoteForm from '../components/QuoteForm';
import Loader from '../components/Loader';
import DocumentMeta from "react-document-meta";
import {getMetaData} from "../lib/helperFunctions";
import '../App.css';
import '../Custom.css';
import NavBar from './client/NavBar';


const styles = {
  container: {padding: "10px"},
}    
function AboutSchool() {
  const {id} = useParams();
  const {packages} = useSchoolPackages(id, "lesson");
  const {school, sliders, loading} = useSchool(id);

  const meta = getMetaData(school.school_name + " | Verified by SimplifyDrive");

  const [showLearnersModal, setShowLearnersModal] = useState(false);
  const [showLicenceModal, setShowLicenceModal] = useState(false);
  const handleLearnersModal =  () => {setShowLearnersModal(prev => !prev);} 
  const handleLicenceModal =  () => {setShowLicenceModal(prev => !prev);} 
  const learnersContent = (
    <>
      <Card.Title><u>Learner's Test</u></Card.Title>
      <Card.Text>
      Before you may begin learning to drive, you must have a valid learner's permit. 
      You must pass a theory test on the Rules of the Road, Road Signs and Markings, and Vehicle Controls in order to obtain a Learner's License. 
      For a motorcycle learner's permit, you must be 16 years old; for a learner's permit for a light motor vehicle, you must be 17 years old; 
      and for all other motor vehicles, including motorcycles with larger engines, you must be 18 years old.
      </Card.Text>
      <hr/>
      <CustomButton text="Get Quote" onClick={handleLearnersModal}/>
    </>
  );
  const licenceContent = (
    <>
      <Card.Title><u>Driver's Licence</u></Card.Title>
      <Card.Text>
      Obtaining a driving license
      <li>Make sure you are at least 18 years old and have a current learner's permit before applying. If you are older than 16, however, you can apply for a license to operate a motorcycle (up to 125 CC).</li>
      <li>On the day of your appointment, have your eyes examined at the driving license testing facility.</li>
      <li>Make sure you have two 45mm x 35mm black and white photos that are exact.</li>
      <li>Ensure that your face is easily discernible and that the background is light and shadow-free.
      If your religion requires it, you may cover your hair, but you may not cover your face.</li>
      <li>Printing your photo on photographic paper is recommended.</li>
      <li>To get the greatest quality, ask a professional photographer to snap your pictures.</li>
      </Card.Text>
      <hr/>
      <CustomButton text="Get Quote" onClick={handleLicenceModal}/>
    </>
  );
  const lessonContent = (
    <>
      <Card.Title><u>Driving Lesson(s)</u></Card.Title>
      <Card.Text>
      {(packages.length==0) ?   
                <Alert heading="Sorry, no added driving lessons yet" text="There are no driving lessons added by this driving school, please check again later." color="info"/>       
                : 
        <div style={{maxHeight: "53vh", overflowY: "scroll"}} className="Custom">
          <div  className="grid-style-lesson">
            {packages && packages.map(packageOpt => <LessonCard packageOpt={packageOpt} key={packageOpt.package_ID}/>)}  
          </div>
        </div>
        }
      </Card.Text>
    </>
  );
  const reviewContent = (
      <Reviews school={school}/>
  );  
  const [body, setBody] = useState(learnersContent);
    // loading
    if(loading){return(<div className="App"><Loader/></div>)} 
    return (
      <>
      <NavBar/>
      <div  className="App">
        <DocumentMeta {...meta} />        
        {school &&
        <div style={styles.container}>
        <Link to={`/driving-schools/province/${school.province}`} className='btn btn-danger'>
            <small><MdKeyboardReturn/> Back</small>
        </Link>          
          <div className='row'>
            {/* <h2 style={{textAlignment: 'center'}}>{school.school_name}</h2> */}
            <div className='col-lg-7 col-12'>
              <SchoolCarousel slider_images={sliders}/>
              <p>{school.about_school}</p>
            </div>
            <div className='col-lg-5 col-12'>
              <Card style={{ margin: "10px 0px"}}>
                <Card.Header>
                  <Nav variant="tabs" defaultActiveKey="#learners">
                    <Nav.Item>
                      <Nav.Link href="#learners" onClick={() => {setBody(learnersContent);}}>Learner's</Nav.Link>
                    </Nav.Item>                 
                    <Nav.Item>
                      <Nav.Link href="#licence" onClick={() => {setBody(licenceContent);}}>Licence</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link href="#lesson" onClick={() => {setBody(lessonContent);}}>Lesson</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link href="#review" onClick={() => {setBody(reviewContent);}}>Reviews</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Card.Header>
                <Card.Body>
                  {body}
                  <CustomModal showModal={showLearnersModal} handleClose={()=>setShowLearnersModal(prev=>!prev)} title="Learner's Quotation" bodyContent={<QuoteForm school_id={id} onSubmit={()=>setShowLearnersModal(false)}/>}/>
                  <CustomModal showModal={showLicenceModal} handleClose={()=>setShowLicenceModal(prev=>!prev)} title="Licence Quotation" bodyContent={<QuoteForm school_id={id} isLicence={true} onSubmit={()=>setShowLicenceModal(false)}/>}/>
                </Card.Body>
              </Card>
            </div>            
          </div>
        </div>
        }
      </div>
      </>
      
     );
}

export default AboutSchool;