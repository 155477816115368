import { Link } from 'react-router-dom';
import React, { useState } from "react";
import { getMetaData } from "../../lib/helperFunctions";
import EnhancedTable from "../../components/EnhancedTable";
import useQuotes from "../../hooks/useQuotes";
import { useAuthUser, useAuthHeader } from "react-auth-kit";
import { useFetchAuth, useCheckClient } from "../../hooks/auth";
import { MdAddCircle } from 'react-icons/md';
import NavBar from './NavBar';
import { SCHOOL_RELATIVE_PATH } from "../../config/config";
const styles = {
  body: {
    padding: "100px 50px 50px 50px",
  },
};

function Quotes() {
  useFetchAuth(); 
  useCheckClient();
  
  const user = useAuthUser()();
  const authHeader = useAuthHeader();
  const [currentPage, setCurrentPage] = useState(1);
  const meta = getMetaData("Admin | Partner with SimplifyDrive");
  const {quotations, loading} = useQuotes(authHeader());
  console.log(quotations)
  
  function handlePageChange(pageNumber) {
    setCurrentPage(pageNumber);
  }
  const onDeleteQuotation = async (id) => {

  }

  const quotationsData = (quotations, onDeleteQuotation) => {
    return quotations.map((quotation, i) => ({
      id: i + 1,
      name: quotation.client_name,
      cell: quotation.cell_number,
      email: quotation.client_email,
      type: quotation.type,
      actions: (
        <>
          <button onClick={() => onDeleteQuotation(quotation.school_ID)} className="btn btn-outline-danger border border-danger m-1"><i className="fa fa-trash"  aria-hidden="true"></i> Delete</button>
        </>
      ),
    }));
  };
  const parentDir = SCHOOL_RELATIVE_PATH;
  return (
    <div className="App">
      <NavBar />
      <div className="dashboard-container">
        {/* <div className="dashboard-title-container">
          <h2 className="dashboard-title">Quotation Leads</h2>
        </div> */}
        
        <div className="dashboard-header">
          <div className="dashboard-links">
          <div className="dashboard-links">
            <Link to={SCHOOL_RELATIVE_PATH} className="dashboard-link">
              Dashboard
            </Link>
            <span className="dashboard-separator">/</span>
            <Link className="current-link">
              Total Leads
            </Link>
          </div>

          </div>
          <Link className="dashboard-link">
            <MdAddCircle size={35} />
          </Link>
        </div>

      <EnhancedTable 
        data={quotationsData(quotations, onDeleteQuotation)}
        columns={["ID", "Name", "Cell", "Email", "Type", "Actions"]}
        title="Quotations"
        currentPage={currentPage}
        onPageChange={handlePageChange}
      />
    </div>
    </div>
  );
}

export default Quotes;
