import React from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useAuthUser } from 'react-auth-kit';

const PrivateRoute = ({ children }) => {
  const navigate = useNavigate();
  const auth = useAuthUser()();

  if (auth === null) {
    // Save the current location to redirect the user after login
    localStorage.setItem('redirectPath', window.location.pathname);

    // Use the `navigate` function outside the rendering phase
    setTimeout(() => {
      navigate('/login'); // Redirect to the login page after the setTimeout
    }, 0);

    // Return a placeholder or null to avoid rendering the child routes
    return null;
  }

  // Render the child routes if authenticated
  return <>{children}</>;
};

export default PrivateRoute;
