import { Pie } from 'react-chartjs-2';
import {Chart as ChartJS} from 'chart.js/auto';
function PieChart(props)
{
    const {chartData, optionsData} = props;
    return ( 
        <Pie data={chartData} options={optionsData}/>    
     );
}

export default PieChart;