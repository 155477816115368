import { useEffect, useState } from 'react'; 
import { authenticatedGetRequest } from '../requests/apiRequests';
function useSubscriptions(token, limit=10000) {
    const [subscriptions,setSubcriptions] = useState({});  
    const [subscriptionsLength, setSubscriptionsLength] = useState(false);  
    const getSubcriptions = async () => {
        try {
          const {response, json} = await authenticatedGetRequest(`/clients/subscriptions/${limit}`, token);
          if(response.status !== 404){
            const result = json;
            setSubcriptions(result);
            setSubscriptionsLength(result.length)
          }else{
            setSubcriptions({});  
          }
          
        } catch (error) {
          console.error(error);
        }
      }

    useEffect(() => {
      getSubcriptions()
    }, []);    

    return ( {subscriptions, subscriptionsLength} );
}

export default useSubscriptions;