import {useState, useEffect} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import Input from './Input';
import TextArea from './TextArea';
import CustomButton from './CustomButton';
import usePackages from "../hooks/usePackages";
import { ToastContainer, toast } from 'react-toastify';
import {getMetaData} from "../lib/helperFunctions";
import { authenticatedPatchRequest } from '../requests/apiRequests';
import {useAuthUser, useAuthHeader} from 'react-auth-kit';
import { MdAddCircle } from 'react-icons/md';
const styles = {
  body: {
      display: "flex", 
      flexDirection: "column", 
      width: '40vw',
      backgroundColor: "white",
      borderRadius: "8px",
      padding: "20px 15px",
      margin: "30vh"
  },
  rightButton: {
      marginLeft: "5px"
  }
}

function EditPackageForm() {
  const navigate = useNavigate();
  const user = useAuthUser()();
  const authHeader = useAuthHeader();
  const { packageId } = useParams();
  const { packageType } = useParams()
  const [packageName, setPackageName] = useState("");
  const [price, setPrice] = useState(0);
  const [loading, setLoading] = useState(true);
  const [test_type, setTestType] = useState("Initial");
  const [description, setDescription] = useState("Initial");
  const [lesson_name, setName] = useState("");
  const [lesson_num, setLessonNum] = useState("");
  const [features, setFeatures] = useState("");

  const { packages} = usePackages(authHeader(), packageType);

  useEffect(() => {
    const myPackage = packages.find((package1) => package1.package_ID == packageId);
    if (myPackage) {
      setPackageName(myPackage.type);
      setPrice(myPackage.price);

      if (packageType === 'lesson') {
        setName(myPackage.name);
        setLessonNum(myPackage.lesson_num);
        setFeatures(myPackage.features);
        
      } else {
        setDescription(myPackage.description);
        setTestType(myPackage.test_type);
      }
    }

    if(packageName === '' && price < 1){
      
    }


  }, [packages, packageId]);
  
  // alert(description)

  const formattedDate = new Date().toISOString().substring(0, 10);
  const meta = getMetaData("Driving School | Partner with SimplifyDrive");

  const handleSubmit = async (e) => {

    setLoading(true)
  
    if(price === ""){
  
      toast.error('Please provide price!', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 5000
      });
  
      return
  
    }
  
  
    const formFields = packageName === "lesson" ? 
      
    {
      school_ID: user?.userID,
      price: price,
      lesson_name: lesson_name,
      lesson_num: lesson_num,
      features: features,
      package_type: packageType,
      type: packageName,
      date_added: formattedDate,
    }
    : 
    {
      school_ID: user?.userID,
      price: price,
      description: description,
      package_type: packageType,
      type: test_type,
      date_added: formattedDate,
    }
    ;
  
    try{
      const response =  await authenticatedPatchRequest(`/packages/${packageId}`, formFields, authHeader());
      console.log(response);
        
      if(response.status === 200){
        setLoading(false)
        toast.success('Package edited successfully!', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000
        });
  
        setPrice("")
        setDescription("")
        setName("")
        setLessonNum("")
        setFeatures("")
  
      }else{
        toast.error('There was an error in editing the package!', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000
        });
      }
        
        
    } catch (error) {
      console.error(error);
    }
  }
  const parentDir = "/driving-school";  
  return ( 
    <>
    <ToastContainer />
    
    
      <div className="App" style={styles.body}>
      <div className="dashboard-container">
        {/* <div className="dashboard-title-container">
          <h2 className="dashboard-title">Edit {packageName}</h2>
        </div> */}
        
        <div className="dashboard-header">
          <div className="dashboard-links">
          <Link to={`${parentDir}`} className="dashboard-link">
              Dashboard
            </Link>
            <span className="dashboard-separator">/</span>
            <Link to={`${parentDir}/packages/${packageName}`} className="dashboard-link">
            Packages
            </Link>
            <span className="dashboard-separator">/</span>
            <Link className="current-link">
            Edit {packageName}
            </Link>
          </div>
        </div>
        <hr />
        <Input label="Price:" name="Price" type="number" min="1" placeholder="Enter Price" value={price} onChange={(e) => setPrice(e.target.value)}/>
        {packageName !== "lesson" ? 
        (<>
        <TextArea label="Description:" name="Description" type="text" placeholder="Enter Description" value={description} onChange={(e) => setDescription(e.target.value)}/>
        </>)
         : 

         (<>
          <Input label="Name:" name="Name" type="text" placeholder="Enter Name" value={lesson_name} onChange={(e) => setName(e.target.value)}/>
          <Input label="Number of Lessons:" name="Number of Lessons" type="number" min="1" placeholder="Enter Number of Lessons" value={lesson_num} onChange={(e) => setLessonNum(e.target.value)}/>
          <TextArea label="Features (comma separated):" name="Features" type="text" placeholder="Enter Features" value={features} onChange={(e) => setFeatures(e.target.value)}/>
          </>) 
          
          }


        <div className="d-flex">
          <CustomButton text="Edit Package" color={`primary`} onClick={handleSubmit}/>
        </div>
    </div>
    </div>
    </>
     );
}

export default EditPackageForm;