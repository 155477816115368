import { useState } from 'react';
import {Form, Badge} from "react-bootstrap";
import Button from "./CustomButton";
import Select from "./Select";
import Chip from './Chip';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { postRequest } from '../requests/apiRequests';
const styles = {
    formContainer: {maxWidth: "500px"},
    heading: {textAlign: "center", marginBottom: "30px"} 
}    
function MultiRequestsForm({selectedSchools, onDelete, onSubmit}) {
    const requestTypes = ["learners","licence"];
    const [selected, setSelected] = useState("learners");
    const [formData, setFormData] = useState({type: selected});
    const [loading, setLoading] = useState(false);
    const handleChange = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
      };
      const handleSubmit = async(e) => {
        e.preventDefault();
        setLoading(true);

        let mobileNumber = formData.cell_number || '';
        if (mobileNumber.startsWith('0') && mobileNumber.length === 10) {
            mobileNumber = '27' + mobileNumber.slice(1); // Replace the leading '0' with '27'
        }
  
        if (!/^(?!\+)(\b27\d{9}\b)$/.test(mobileNumber)) {
          toast.error('Please enter a valid SA mobile number starting with 0 or the country code.', {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000
          });
          return
        }

        
        try {
            const results = await Promise.all(
                selectedSchools.map(async (school) => {
                    const fullRequestData = { ...formData, school_ID: school.school.user_ID };
                    const response = await postRequest('/quote', fullRequestData);
                    console.log(response);
                    return response.status === 200;
                })
            );
        
            const successfulRequests = results.filter((result) => result).length;
            const failedRequests = results.length - successfulRequests;
        
            setLoading(false);
        
            if (successfulRequests > 0) {
                toast.success(`Quotations sent to your email! (${successfulRequests} successful)`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000
                });
            }
        
            if (failedRequests > 0) {
                toast.error(`Failed to generate quotations for ${failedRequests} school(s)`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000
                });
            }
        
            // const response =  await postRequest('/quote', formData);
            // console.log(response);
            // if(response.status !== 404){
            //     setFormData(json);
            //   }else{
            //     setFormData([]);
            //   }
            // Delay the execution of onSubmit by 5 seconds
            setTimeout(() => {
                onSubmit(); // hide modal after 5 seconds
            }, 5000);
        } catch (error) {
            console.error('Error while sending requests:', error);
            setLoading(false);
            toast.error('An error occurred while sending requests', {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 5000
            });
          }
          //setLoading(false); // Stop loading
        }             
    return ( 
        <>
        <ToastContainer />
        <div style={styles.formContainer}>
            <Form>
                <Form.Group controlId="name" className="mb-3">
                    <Form.Label><b>Name:</b></Form.Label>
                    <Form.Control type="text" name="client_name" required placeholder="Name" onChange={handleChange} value={formData.client_name || ''}/>
                </Form.Group>
                <Form.Group controlId="contacts:" className="mb-3">
                    <Form.Label><b>Cell Number:</b></Form.Label>
                    <Form.Control type="text" name="cell_number" required placeholder="Cell Number" onChange={handleChange} value={formData.cell_number || ''}/>
                    {
                    (() => {
                    let mobileNumber = formData.cell_number || '';
                    if (mobileNumber.startsWith('0') && mobileNumber.length === 10) {
                        mobileNumber = '27' + mobileNumber.slice(1); // Replace the leading '0' with '27'
                    }

                    return (
                        mobileNumber.length > 0 && !/^(?!\+)(\b27\d{9}\b)$/.test(mobileNumber) &&
                        <p className="alert alert-warning">
                        Please enter a valid SA mobile number starting with 0 or the country code.
                        </p>
                    );
                    })()
                }
                </Form.Group>
                <Form.Group controlId="email" className="mb-3">
                    <Form.Label><b>Email Address:</b></Form.Label>
                    <Form.Control type="text" name="client_email" required placeholder="Email" onChange={handleChange} value={formData.client_email || ''}/>
                </Form.Group>  
                <hr/> 
                <Form.Group controlId="city:" className="mb-3">
                    <Select itemsArray={requestTypes} label="Request type:" onChange={e=>{setSelected(e.target.value); setFormData({ ...formData, type: e.target.value })}} selected={selected}/>
                </Form.Group>                             
                {selectedSchools.map((school, index)=>
                    <Chip text={school.school.school_name} onClick={()=>onDelete(index)} key={index}/>
                    )}
                <hr/>
                <Button disabled={loading} text="Send Requests" size="md" onClick={handleSubmit}/>      
            </Form>
        </div> 
        </>
           
     );
}

export default MultiRequestsForm;

