import { Link } from 'react-router-dom';
import React, { useState } from "react";
import { getMetaData } from "../../lib/helperFunctions";
import EnhancedTable from "../../components/EnhancedTable";
import { MdAddCircle } from 'react-icons/md';
import useSchoolsStatus from "../../hooks/useSchoolsStatus";
import { useAuthUser, useAuthHeader } from "react-auth-kit";
import { authenticatedPatchRequest } from '../../requests/apiRequests';
import { useFetchAuth, useCheckSchool, useCheckClient } from "../../hooks/auth";
import { ADMIN_RELATIVE_PATH } from '../../config/config';
import { Nav } from 'react-bootstrap';
import NavBar from './NavBar';
const styles = {
  body: {
    padding: "100px 50px 50px 50px",
  },
};

function PendingSchools() {
  useFetchAuth();
  useCheckSchool(); 
  useCheckClient();
  
  const user = useAuthUser()();
  const authHeader = useAuthHeader();
  const [currentPage, setCurrentPage] = useState(1);
  const meta = getMetaData("Admin | Partner with SimplifyDrive");
  const pendingSchools = useSchoolsStatus(0, authHeader());
  const schools = pendingSchools.schools;
  console.log(pendingSchools)
  
  function handlePageChange(pageNumber) {
    setCurrentPage(pageNumber);
  }
  const onDeletePendingSchool = async (id) => {

  }

  const onApprovePendingSchool = async (id) => {

    const formFields = {
      status: 1,
    };
    await authenticatedPatchRequest(`/schools/${id}`, formFields, authHeader())

  }

  const pendingSchoolsData = (schools, onApprovePendingSchool) => {
    return schools.map((school, i) => ({
      id: i + 1,
      name: school.school_name,
      city: school.city,
      province: school.province,
      actions: (
        <>
          <button onClick={() => onApprovePendingSchool(school.user_ID)} className="btn btn-outline-success border border-success m-1"><i className="fa fa-tick"  aria-hidden="true"></i> Approve</button>
          <Link to={`${ADMIN_RELATIVE_PATH}/edit-school/${school.user_ID}`} className="btn btn-outline-primary border border-primary m-1"><i className="fa fa-eye" aria-hidden="true"></i> View</Link>
          {/* <button onClick={() => onDeletePendingSchool(school.user_ID)} className="btn btn-outline-danger border border-danger m-1"><i className="fa fa-trash"  aria-hidden="true"></i> Delete</button> */}
        </>
      ),
    }));
  };
  return (
    <>
     <NavBar/>
    <div className="App">
      
      <div className="dashboard-container">
        
        <div className="dashboard-header">
          <div className="dashboard-links">
          <div className="dashboard-links">
            <Link to={ADMIN_RELATIVE_PATH} className="dashboard-link">
              Dashboard
            </Link>
            <span className="dashboard-separator">/</span>
            <Link className="current-link">
              Pending Bookings
            </Link>
          </div>

          </div>
          <Link className="dashboard-link">
            <MdAddCircle size={35} />
          </Link>
        </div>
      <EnhancedTable 
        data={pendingSchoolsData(schools, onApprovePendingSchool)}
        columns={["ID", "Name", "City", "Province", "Actions"]}
        title="Pending Schools"
        currentPage={currentPage}
        onPageChange={handlePageChange}
      
      />
    </div>
    </div>
    </>
   
  );
}

export default PendingSchools;
