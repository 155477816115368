import React, { useState } from 'react';
import Input from './Input';
import TextArea from './TextArea';
import CustomButton from './CustomButton';
import Loader from '../components/Loader';
import { authenticatedPostRequest } from '../requests/apiRequests';
import { Link, useParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useAuthUser, useAuthHeader} from 'react-auth-kit';
import { SCHOOL_RELATIVE_PATH } from '../config/config';
const styles = {
    body: {
        display: "flex", 
        flexDirection: "column", 
        width: '40vw',
        backgroundColor: "white",
        borderRadius: "8px",
        padding: "20px 15px",
        margin: "30vh"
    },
    rightButton: {
        marginLeft: "5px"
    }
}
const PackageForm = () => {
  const { packageName } = useParams();
  const [price, setPrice] = useState(1)
  const [description, setDescription] = useState("")
  const [loading, setLoading] = useState(false);
  const [lesson_name, setName] = useState("")
  const [lesson_num, setLessonNum] = useState("")
  const [features, setFeatures] = useState("")

  const packageType = packageName === "lesson" ? "lesson" : "test";

  const user = useAuthUser()();
  const authHeader = useAuthHeader()

  const formattedDate = new Date().toISOString().substring(0, 10);

  const handleSubmit = async (e) => {

    setLoading(true)

    if(price === ""){

      toast.error('Please provide price!', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 5000
      });

      return

    }


    const formFields = packageName === "lesson" ? 
    
    {
      school_ID: user?.userID,
      price: price,
      lesson_name: lesson_name,
      lesson_num: lesson_num,
      features: features,
      package_type: packageType,
      type: packageName,
      date_added: formattedDate,
    }
    : 
    {
      school_ID: user?.userID,
      price: price,
      description: description,
      package_type: packageType,
      type: packageName,
      date_added: formattedDate,
    }
    ;

    try{
      const response =  await authenticatedPostRequest('/packages/', formFields, authHeader());
      console.log(response);
      
      if(response.status === 200){
        setLoading(false)
        toast.success('Package Added successfully!', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000
        });

        setPrice("")
        setDescription("")
        setName("")
        setLessonNum("")
        setFeatures("")

      }else{
        toast.error('There was an error in adding the package!', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000
        });
      }
      
      
    } catch (error) {
      console.error(error);
    }
  }
  // loading
  if(loading){return(<div className="App"><Loader/></div>)} 
  return (
    <>
      <ToastContainer />
      <div style={styles.body}>
        
        <h4 className='text-center'>Add {packageName}</h4>
        <div className="dashboard-header">
          <div className="dashboard-links">
          <Link to={SCHOOL_RELATIVE_PATH} className="dashboard-link">
              Dashboard
            </Link>
            <span className="dashboard-separator">/</span>
            <Link className="current-link">
            Add {packageName}
            </Link>
          </div>
        </div>
        <hr />
        <Input label="Price:" name="Price" type="number" min="1" placeholder="Enter Price" value={price} onChange={(e) => setPrice(e.target.value)}/>
        {packageName !== "lesson" ? 
        (<>
        <TextArea label="Description:" name="Description" type="text" placeholder="Enter Description" value={description} onChange={(e) => setDescription(e.target.value)}/>
        </>)
         : 

         (<>
          <Input label="Name:" name="Name" type="text" placeholder="Enter Name" value={lesson_name} onChange={(e) => setName(e.target.value)}/>
          <Input label="Number of Lessons:" name="Number of Lessons" type="number" min="1" placeholder="Enter Number of Lessons" value={lesson_num} onChange={(e) => setLessonNum(e.target.value)}/>
          <TextArea label="Features (comma separated):" name="Features" type="text" placeholder="Enter Features" value={features} onChange={(e) => setFeatures(e.target.value)}/>
          </>) 
          
          }


        <div className="d-flex">
          <CustomButton text="Add Package" color={`primary`} onClick={handleSubmit}/>
        </div>
      </div>
    </>
    
  );
};

export default PackageForm;
