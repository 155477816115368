import React, { useState, useEffect } from 'react';
import { authenticatedPostRequest, authenticatedPatchRequest } from '../../requests/apiRequests';
import { useAuthUser, useAuthHeader } from 'react-auth-kit'; // Import necessary auth hooks
import { toast } from 'react-toastify'; // Import toast for notifications
import useClient from "../../hooks/useClient";
import { useNavigate } from "react-router-dom";

const AutoSubscribe = () => {
  const navigate = useNavigate();
  const user = useAuthUser()(); // Get authenticated user
  const authHeader = useAuthHeader(); // Get authentication headers

  const [name, setName] = useState("");
  const [fname, setFName] = useState("");
  const [lname, setLName] = useState("");
  const [cell, setCell] = useState("");
  const [email, setEmail] = useState("");
  
  const userId = user?.userID;
  const { client } = useClient(authHeader());

  useEffect(() => {
    const myClient = client.find((client) => client.user_ID == userId);
    if (myClient) {
      
      // Check if myClient.bonus is equal to 0
      if (myClient.bonus === 0) {
        const handleSubmit = async (subscriptionType, subscriptionPrice) => {
          const datenow = Date.now();

          try {
            const formFields = {
              client_ID: userId, // Assuming user?.userID contains the user's ID
              subscription_name: subscriptionType,
              client_email: myClient.email,
              client_name: myClient.name + " " + myClient.surname,
              paid: 1,
              bonus: "Yes",
              m_payment_id: String(datenow), 
              price: subscriptionPrice.toFixed(2), // Assuming price is available in the scope
            };

            const response = await authenticatedPostRequest('/clients/subscribe', formFields, authHeader());

            console.log(response);

            if (response.status === 200) {
              toast.success(`Subscription to ${subscriptionType} successful!`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 5000
              });

              // Navigate after the second subscription is done
              if (subscriptionType === "Training Videos") {
                navigate("/dashboard");
              }

            } else {
              toast.error(`There is an error in subscription to ${subscriptionType}!`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 5000
              });
            }
          } catch (error) {
            console.error('Error while sending requests:', error);
            toast.error(`An error occurred while subscribing to ${subscriptionType}`, {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 5000
            });
          }
        };

        // Call handleSubmit function twice for each subscription type
        handleSubmit("Learner's Simulation Test", 50);
        handleSubmit("Training Videos", 150);

      }else{

        toast.error(`Your subcription bonus has expired!`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000
        });

        navigate("/dashboard");

      }
    }
  }, [client, userId]);

  // Return null or any other content as needed
  return null;
};

export default AutoSubscribe;
