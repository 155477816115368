import {Badge} from "react-bootstrap";
import {shorterText} from "../lib/helperFunctions";
const styles = {
    closeBtn: {
        backgroundColor: "#D3D3D3", 
        padding: "1px 5px", 
        borderRadius: "8px", 
        cursor: "pointer"
    }
}
function Chip({text, onClick}) {
    return(
        <Badge bg="warning" text="dark" className="m-1 p-1">
            {shorterText(text)} 
            <span style={styles.closeBtn} onClick={onClick}>X</span>
        </Badge>
    )
}
export default Chip;