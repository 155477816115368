import { useEffect, useState } from 'react';
import { authenticatedGetRequest } from '../requests/apiRequests';
function useQuotes(token) {
    const [quotations, setQuotations] = useState([]);
    const [loading, setLoading] = useState(false);
    const getQuotations = async () => {  
        try {
          setLoading(true); // Set loading before sending API request
          const {response, json} = await authenticatedGetRequest(`/quote`, token);
          if(response.status !== 404){
            setQuotations(json);
          }else{
            setQuotations([]);
          }
        } catch (error) {
          console.error(error);
        }
        setLoading(false); // Stop loading
      }
    useEffect(() => {
        getQuotations();
        }, []);
    return ( {quotations,loading} );
}

export default useQuotes;