import {Link} from "react-router-dom";
function StatusCard(props) {
    const {title, number, icon, href} = props;
    console.log(href);
    return ( 
        <div className="card border-0">
            <div className="card-body">
                <p>{title}</p>
                <h6 className="d-flex justify-content-between" style={{fontSize: "40px"}}>
                    <Link to={href} style={{textDecoration:"none"}}>{number}</Link> 
                    <span className="d-flex align-items-end">{icon}</span>
                </h6>
            </div>
        </div>
     );
}

export default StatusCard;