import { Link } from 'react-router-dom';
import React, { useState } from "react";
import { getMetaData } from "../../lib/helperFunctions";
// import EnhancedTable from "../../components/EnhancedTable";
import ClientsTable from '../../components/ClientsTable';
import { MdAddCircle } from 'react-icons/md';
import useClient from "../../hooks/useClient";
import { useAuthUser, useAuthHeader } from "react-auth-kit";
import { authenticatedPatchRequest } from '../../requests/apiRequests';
import { useFetchAuth, useCheckSchool, useCheckClient } from "../../hooks/auth";
import { ADMIN_RELATIVE_PATH } from '../../config/config';
import { Nav } from 'react-bootstrap';
import NavBar from './NavBar';
const styles = {
  body: {
    padding: "100px 50px 50px 50px",
  },
}; 

function ActiveClients() {
  useFetchAuth();
  useCheckSchool(); 
  useCheckClient();
  
  const user = useAuthUser()();
  const authHeader = useAuthHeader();
  const [currentPage, setCurrentPage] = useState(1);
  const meta = getMetaData("Admin | Partner with SimplifyDrive");
  const {client} = useClient(authHeader());

  
  function handlePageChange(pageNumber) {
    setCurrentPage(pageNumber);
  }

  const getActiveClients = (array, active) => {
    return array.filter(item => item.status === active);
  };

  const activeClients = getActiveClients(client, 1);
 
  const getClientsData = (activeClients) => {
    return activeClients.map((client, i) => ({
      name: client.name,
      surname: client.surname,
      cell_number: client.cell_number,
    }));
  };
  const clientsData = getClientsData(activeClients);
  return (
    <>
    <NavBar/>
    <div>
      
      <div className="dashboard-container">
        
        <div className="dashboard-header">
          <div className="dashboard-links">
          <div className="dashboard-links">
            <Link to={ADMIN_RELATIVE_PATH} className="dashboard-link">
              Dashboard
            </Link>
            <span className="dashboard-separator">/</span>
            <Link className="current-link">
              Active Clients
            </Link>
          </div>

          </div>
          <Link className="dashboard-link">
            <MdAddCircle size={35} />
          </Link>
        </div>
        {(clientsData.length > 0) &&        
          <ClientsTable 
            data={clientsData}
            columns={Object.keys(clientsData[0])}
            title="Active Clients"
            currentPage={currentPage}
            onPageChange={handlePageChange}
          
          />      
        }
    </div>
    </div>
    </>
  
  );
}

export default ActiveClients;
