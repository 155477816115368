import {useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import background from "../../assets/images/background.png";
import DocumentMeta from "react-document-meta";
import {getMetaData} from "../../lib/helperFunctions";
import CustomButton from '../../components/CustomButton';
import Navigation from "../../components/Navigation";
import {Form} from 'react-bootstrap';
import Input from "../../components/Input";
import CheckBox from "../../components/CheckBox";
import { postRequest } from "../../requests/apiRequests";
import { useSignIn } from "react-auth-kit";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NavBar from "./NavBar";
const styles = {
    body: {
        display: "flex", 
        flexDirection: "column", 
        alignItems: "center", 
        backgroundImage: `url(${background})`, 
        backgroundPosition: 'top',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: '100vw',
        height: '100vh'
    },
    title: {
        color: "white", 
        textShadow: "3px 3px 12px #FB2576", 
        fontSize: "4vw", 
        textAlign: "center", 
        marginTop: "22vh"
    },
    form: {
        backgroundColor: "rgba(255, 255, 255, 0.9)",
        padding: "20px 15px",
        borderRadius: "8px",
        width: "380px",
        margin: "2em 0"
    }
}

function Login() {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [pass, setPass] = useState("");
    const meta = getMetaData("Login as a driving school");
    const signIn = useSignIn();
    const onSubmit = async(e) => {
        e.preventDefault();
        const credentials = {"email": email, "password": pass, "type": "a"};
        try{
            const response = await postRequest('/users/login', credentials);
            const json = await response?.json();
            console.log(response);
            if(response.status === 200){

                signIn({
                    token: json.token,
                    expiresIn: 1000,
                    tokenType: "Bearer",
                    authState: {userID:json.userID, email: email, type: json.type}
                });

                navigate("/admin");  

            }else{ console.log("Failed to login"); toast.error('Login failed. credentials do not match!', {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 5000
              }); }
        }catch (error) {
            console.error(error);
            toast.error('Login failed. credentials do not match!', {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 5000
              });
        }
    }
    return ( 
        <>
        <NavBar/>
        <ToastContainer />
        <div style={styles.body}>
        <DocumentMeta {...meta} />
        <h1 style={styles.title}>Login</h1>
        <Form style={styles.form}>
            <Input label="Email address" type="email" placeholder="Enter email" onChange={(e)=>{setEmail(e.target.value)}} />
            <Input label="Password" type="password" placeholder="Enter password" onChange={(e)=>{setPass(e.target.value)}} />
            <div className="d-flex justify-content-between">
                <CheckBox label="remember me" required={false}/>
                {/* <Link to={"/register"}  style={{fontSize: 13}}>
                    create account
                </Link> */}
            </div>
            <CustomButton text="Login" onClick={onSubmit}/>
        </Form>
        </div>
        </>
    
     );
}

export default Login;