import {Alert} from 'react-bootstrap';
import CustomButton from './CustomButton';
function CustomAlert({heading,text,color,onClick})
{
    return <Alert variant={color} className='text-center'>
                <Alert.Heading>{heading}</Alert.Heading>
                <p>
                    {text}
                </p>
                {onClick && <CustomButton text="YES!" onClick={onClick}/>}
            </Alert>
}

export default CustomAlert;