import {shorterText} from "../lib/helperFunctions";
import useReviews from '../hooks/useReviews';
import {Link} from "react-router-dom";
import { getFileRequest } from '../requests/apiRequests';
import {useRef, useEffect} from 'react';
const styles = {
    container: {color: "white", backgroundColor: "#1E2235", borderRadius: 8, overflow: "hidden"},
    footer:{padding: 8},
    footerDiscription: {display: "flex", flexDirection: "column", alignItems: "center", color: "white"},
    imgContainer: {height: 180, overflow: "hidden"},
    imgStyle: {width: "100%", height:"100%"},
    cityText: {fontSize:12}
}    

function Card({school, onSelect, handleSelectStyle} ) {
    const cardElem = useRef(null);
    const {reviews, total, average} = useReviews(school);
    useEffect(()=>{
        cardElem.current?.addEventListener("touchstart", e => {
            if(e.targetTouches.length >= 2){
                cardElem.current.style.border = "4px solid red"; //update se;ected card border
                onSelect(school); // add school to selected school list
                }
            });
    }, []);
    return (
    <div ref={cardElem} style={{...styles.container, border: (handleSelectStyle(school) === true) ? "4px solid red":""}} onMouseDownCapture={()=>{onSelect(school)}} >
        <Link to={`/driving-schools/${school.user_ID}`} style={{textDecoration:"none", color: "white"}}>
            <div style={styles.imgContainer}>
                <img src={getFileRequest(`/files/image/${school.cover_img}`)} loading="lazy" alt={school.school_name} style={styles.imgStyle}/>
                {/* <LazyLoadImage 
                    alt=""
                    height={"100%"}
                    src={require("../uploads/images/"+school.cover_img)} // use normal <img> attributes as props
                    width={"100%"}
                    effect="blur"
                    placeholderSrc={require("../uploads/images/"+school.cover_img)}
                /> */}
            </div>
            <div style={styles.footer}>
                <div>
                    <small style={{display: "flex", justifyContent: "end", alignItems: "center", fontSize:12}}><img src={require(`../assets/icons/filledstar.png`)} height={20}/>{parseFloat(average).toFixed(1)}({total})</small>
                </div>
                <div style={styles.footerDiscription}>
                    <div style={{textTransform: "capitalize"}}>{shorterText(school.school_name)}</div>
                    <div style={styles.cityText}>({shorterText(school.city,12)})</div>
                </div>
            </div>
        </Link>
    </div>
  );
}
export default Card;
