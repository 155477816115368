
import background from "../../assets/images/background.png";
import EditBookingForm from "../../components/EditBookingForm";
import { useFetchAuth, useCheckClient } from "../../hooks/auth";
const styles = {
  body: {
      display: "flex", 
      flexDirection: "column", 
      alignItems: "center", 
      backgroundImage: `url(${background})`, 
      backgroundPosition: 'top',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      width: '100vw',
      height: '100vh'
  },
  title: {
      color: "white", 
      textShadow: "3px 3px 12px #FB2576", 
      fontSize: "4vw", 
      textAlign: "center", 
      marginTop: "22vh"
  },
  form: {
      backgroundColor: "rgba(255, 255, 255, 0.9)",
      padding: "20px 15px",
      borderRadius: "8px",
      width: "380px",
      margin: "2em 0"
  }
}

function EditBooking() {
  useFetchAuth(); 
  useCheckClient();
  return ( 
    <div style={styles.body}>
        <EditBookingForm />
    </div>
     );
}

export default EditBooking;