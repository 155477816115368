import {useEffect, useState} from 'react';
import {authenticatedGetRequest} from '../requests/apiRequests'
function usePackage(token, school_ID, type, id) {
    const [packag, setPackage] = useState([]);
    const [loading, setLoading] = useState(false);
    const getPackage = async () => {
        try {
          setLoading(true); // Set loading before sending API request
          const { response, json } = await authenticatedGetRequest(`/packages/school/${school_ID}/${type}/${id}`, token);
          
          if(response.status === 200){
            setPackage(json);
            
         }else if(response.status === 401){
          console.log("access denied");
         }
       } catch (error) {
         console.error(error);
       }
       setLoading(false); // Stop loading
     }
     useEffect(() => {
        getPackage();
      }, []);    
    return ( {packag, loading} );
}

export default usePackage;