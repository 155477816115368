import { Nav, Navbar, Container, NavDropdown } from "react-bootstrap";

function Navigation({ menuItems }) {
    const base_url = window.location.origin;
    return (
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" fixed="top" sticky="top">
            <Container>
                <Navbar.Brand href={base_url}>
                    <img src={require(`../assets/images/logo-icon.png`)} height={45} alt="Logo" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto m-1">
                        {menuItems.leftItems.map((navItem, index) => (
                            <Nav.Link key={index} href={navItem.url}>{navItem.item}</Nav.Link>
                        ))}
                    </Nav>
                    {menuItems.rightItems.map((navItem, index) => (
                        navItem.isDropDown ? (
                            <NavDropdown className="m-1" title={navItem.item.title} id="basic-nav-dropdown" key={index}>
                                {navItem.item.dropDownList.map((dropDownItem, index) => (
                                    <NavDropdown.Item style={{ width: 140 }} href={dropDownItem.url} key={index}>
                                        {dropDownItem.itemName}
                                    </NavDropdown.Item>
                                ))}
                            </NavDropdown>
                        ) : (
                            <Nav className="m-1" key={index}>
                                <Nav.Link href={navItem.url} onClick={navItem.onClick}>
                                    {navItem.item}
                                </Nav.Link>
                            </Nav>
                        )
                    ))}
                    {/* {menuItems.hasDropDown && 
                    <NavDropdown title="Learner's Tests" id="basic-nav-dropdown">
                        
                        <NavDropdown.Item href="/learners-tests/test1">Test 1</NavDropdown.Item>
                        <NavDropdown.Item href="/learners-tests/test2">Test 2</NavDropdown.Item>
                    </NavDropdown>
                    } */}
                    {/* <Nav>
                        {menuItems.rightItems.map((navItem, index) => <Nav.Link key={index} href={navItem.url}>{navItem.item}</Nav.Link>)}
                    </Nav> */}
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Navigation;
