import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import DocumentMeta from 'react-document-meta';
import background from '../../assets/images/background.png';
import { getMetaData } from '../../lib/helperFunctions';
import { postRequest } from '../../requests/apiRequests';
import NavBar from "./NavBar";
import { toast } from 'react-toastify'; // Import toast for notifications
import { useFetchAuth, useCheckAdmin, useCheckSchool } from "../../hooks/auth";

const styles = {
  body: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundImage: `url(${background})`,
    backgroundPosition: 'top',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '100vw',
    height: '100vh',
  },
  title: {
    color: 'white',
    textShadow: '3px 3px 12px #FB2576',
    fontSize: '4vw',
    textAlign: 'center',
    marginTop: '22vh',
  },
  form: {
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    padding: '20px 15px',
    borderRadius: '8px',
    width: '380px',
    margin: '2em 0',
  },
};

function Activate() {
  useFetchAuth();
  useCheckAdmin();
  useCheckSchool();
  
  const { email } = useParams();
  const { activation_code } = useParams();
  const [activated, setActivated] = useState(false);
  const meta = getMetaData('Activate account');

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await postRequest(`/users/activate/${email}/${activation_code}`, {});
        if (response) {
          toast.success(`Account Activation Successful!`, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000
          });
          
          setActivated(true);
        } else {
          toast.error(`Account Activation Unsuccessful!`, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000
          });
          console.log('Failed to activate');
        }
      } catch (error) {
        console.log('Error:', error);
      }
    }

    fetchData();
  }, []);

  return (
    <>
    <NavBar />
      <div style={styles.body}>
      <DocumentMeta {...meta} />
      <h1 style={styles.title}>Activate Account</h1>
      <Form style={styles.form}>
        <div className="d-flex justify-content-between">
          {activated && (
            <div className="alert alert-success alert-dismissible fade show" role="alert">
              Account activated!
              <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          )}
          <br></br>
          <Link to="/login" style={{ fontSize: 13 }}>
            Login
          </Link>
        </div>
      </Form>
    </div>
    </>
    
  );
}

export default Activate;
