import { Link } from 'react-router-dom';
import React, { useState } from "react";
import { getMetaData } from "../../lib/helperFunctions";
import EnhancedTable from "../../components/EnhancedTable";
import { MdAddCircle } from 'react-icons/md';
import useVideo from "../../hooks/useVideo";
import { useAuthUser, useAuthHeader } from "react-auth-kit";
import { authenticatedPatchRequest } from '../../requests/apiRequests';
import { useFetchAuth, useCheckSchool, useCheckClient } from "../../hooks/auth";
import { ADMIN_RELATIVE_PATH } from '../../config/config';
import { Nav } from 'react-bootstrap';
import NavBar from './NavBar';
const styles = {
  body: {
    padding: "100px 50px 50px 50px",
  },
};

function TrainingVideos() {
  useFetchAuth();
  useCheckSchool(); 
  useCheckClient();
  
  const user = useAuthUser()();
  const authHeader = useAuthHeader();
  const [currentPage, setCurrentPage] = useState(1);
  const meta = getMetaData("Admin | Partner with SimplifyDrive");
  const trainingVideos = useVideo(authHeader());
  const videos = trainingVideos.video;
  console.log(videos)
  
  function handlePageChange(pageNumber) {
    setCurrentPage(pageNumber);
  }

  const onDelete = async (id) => {
  }

  const onChangeStatus = async (id) => {

    const formFields = {
      status: 1,
    };
    await authenticatedPatchRequest(`/videos`, formFields, authHeader())

  }

  const trainingVideosData = (videos, onChangeStatus) => {
    return videos.map((video, i) => ({
      id: i + 1,
      title: video.title,
      category: video.category,
      status: video.status,
      actions: (
        <>
          <button onClick={() => onChangeStatus(video.video_ID)} className="btn btn-outline-success border border-success m-1"><i className="fa fa-tick"  aria-hidden="true"></i>{ video.status ? "Susupend" : "Approve" }</button>
          <Link to={`${ADMIN_RELATIVE_PATH}/edit-video/${video.video_ID}`} className="btn btn-outline-primary border border-primary m-1"><i className="fa fa-eye" aria-hidden="true"></i> View</Link>
          {/* <button onClick={() => onDelete(video.video_ID)} className="btn btn-outline-danger border border-danger m-1"><i className="fa fa-trash"  aria-hidden="true"></i> Delete</button> */}
        </>
      ),
    }));
  };
  return (
    <>
     <NavBar/>
    <div className="App">
      
      <div className="dashboard-container">
        
        <div className="dashboard-header">
          <div className="dashboard-links">
          <div className="dashboard-links">
            <Link to={ADMIN_RELATIVE_PATH} className="dashboard-link">
              Dashboard
            </Link>
            <span className="dashboard-separator">/</span>
            <Link className="current-link">
              Training Videos
            </Link>
          </div>

          </div>
          <Link to={ `${ADMIN_RELATIVE_PATH}/add-video` } className="dashboard-link">
            <MdAddCircle size={35} />
          </Link>
        </div>
      <EnhancedTable 
        data={trainingVideosData(videos, onChangeStatus)}
        columns={["ID", "Title", "Category", "Status", "Actions"]}
        title="Training Videos"
        currentPage={currentPage}
        onPageChange={handlePageChange}
      
      />
    </div>
    </div>
    </>
   
  );
}

export default TrainingVideos;
