import { useEffect, useState } from 'react';
import { authenticatedGetRequest } from '../requests/apiRequests';
function useSubscription(id, product,token) {
    const [subscriptions,setSubcription] = useState({});  
    const [loading, setLoading] = useState(false);       
    const getSubcription = async () => {
        try {
          setLoading(true); // Set loading before sending API request
          if(id > 0){
            const {response, json} = await authenticatedGetRequest(`/clients/${id}/subscription/${product}`, token);
            if(response.status !== 404){
              const result = json[0];
              setSubcription(result); 
            }else{
              setSubcription({});
            }
          }else{
            setSubcription({});
          }
          
        } catch (error) {
          console.error(error);
        }
        setLoading(false); // Stop loading
      }
      useEffect(() => {
        getSubcription();
      }, []);          
    return ( {subscriptions, loading} );
}

export default useSubscription;