import {Form} from 'react-bootstrap';
function Select({label, size, itemsArray, name, value, onChange}) {
    return ( 
        <Form.Group className="mb-3" controlId={label}>
        <Form.Label><b>{label}</b></Form.Label>
        <Form.Select style={{width: (size===undefined)?"":size}} name={name} value={value} onChange={onChange}>
          {itemsArray.map(item => <option key={item} value={item}>{item}</option>)}
        </Form.Select>    
        </Form.Group>    
     );
}

export default Select;