import { useEffect, useState } from 'react';
import { authenticatedGetRequest } from '../requests/apiRequests';
function useSchoolsStatus(id, token) {
    const [schools, setSchools] = useState([]);
    const [schoolsLength, setSchoolsLength] = useState(0);    
    const [loading, setLoading] = useState(false);       
    const getSchool = async () => {
        try {
          setLoading(true); // Set loading before sending API request
          const {response, json} = await authenticatedGetRequest(`/schools/status/${id}`, token);
          if(response.status !== 404){
            setSchools(json);
            setSchoolsLength(json.length);
          }else{
            setSchools([]);
          }
        } catch (error) {
          console.error(error);
        }
        setLoading(false); // Stop loading
      }
      useEffect(() => {
        getSchool();
      }, []);          
    return ( {schools, schoolsLength, loading} );
}

export default useSchoolsStatus;