import MultipleChoice from "../../components/MultipleChoice";
import { useState, useEffect } from 'react';
import NavBar from "./NavBar";
import { useIsAuthenticated, useAuthUser, useAuthHeader } from 'react-auth-kit';
import useSubscription from "../../hooks/useSubscription";
import { shuffleArray, getMetaData } from "../../lib/helperFunctions";
import {useParams} from "react-router-dom";
import DocumentMeta from "react-document-meta";

function LearnersTest() {
  const meta = getMetaData("Learner's Simulation Test");
  const params = useParams();
  let testType = (params.hasOwnProperty("testType") ? params.testType : "test1");
  const [questions, setQuestions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const isAuthenticated = useIsAuthenticated()();
  const user = useAuthUser()();
  const authHeader = useAuthHeader();

  const [subscription, setSubscription] = useState(false);
  const [daysLeft, setDaysLeft] = useState();
  const [done, setDone] = useState(false);

  let userId
  if(isAuthenticated){
    userId = user?.userID; 
  }
  const { subscriptions } = useSubscription(userId, "Learner's Simulation Test", authHeader());
  useEffect(() => {
    try {
      const loadTestData = async() => {
        const {questions} = await import(`../../data/${testType}.js`);
        // console.log(questions);

        setQuestions(questions);

      }
      loadTestData();
      if(subscriptions){
        if (subscriptions.client_ID === userId) {
          
          const subscriptionDate = new Date(subscriptions.date_subscribed);
          const currentDate = new Date();
          const differenceInMilliseconds = currentDate - subscriptionDate;
          const differenceInDays = differenceInMilliseconds / (1000 * 3600 * 24);
          const daysLeft = 30 - Math.floor(differenceInDays);
          setDaysLeft(daysLeft);

          if(differenceInDays <= 30 && subscriptions.paid === 1){
            setSubscription(true);
          }
        }
    
      }else{
        setDaysLeft(0)
      }

      setDone(true)
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
 
    }, [subscriptions]);
    console.log("ran after...");
      if (!done || daysLeft === undefined) {
        return null; // or you can return a loading indicator or any other component
      }
    if (isLoading) {
      return <div>Loading...</div>; // Render a loading indicator
    }
    if(questions.length !== 0) {
      // Shuffle all options per question
      questions.forEach(obj => {
          shuffleArray(obj.options);
      });
      return (
        <>
          <NavBar/>
          <div className="App">
          <DocumentMeta {...meta} />
            <MultipleChoice questions={shuffleArray(questions)} subscription={subscription} daysLeft={daysLeft} testType={testType}/>
          </div>
        </>
        
      );
      }
  }
  
  export default LearnersTest;