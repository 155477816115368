import {useState, useRef} from 'react';
import {Modal, Card, Image, Form} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import useReviews from '../hooks/useReviews';
import {timeSince, shorterText} from "../lib/helperFunctions";
import CustomButton from './CustomButton';
import CustomModal from './CustomModal';
import Alert from './CustomAlert';
import ProfileAvatar from './ProfileAvatar';

import { postRequest, getRequest } from '../requests/apiRequests';
import Loader from './Loader';

import {useIsAuthenticated} from 'react-auth-kit';
import {useAuthUser} from 'react-auth-kit';
function Reviews(props) {
    const {school} = props;
    const isAuthenticated = useIsAuthenticated()();
    const user = useAuthUser()();

    const {reviews, total, average, loading} = useReviews(school);
    const [showModal, setShowModal] = useState(false);
    const handleClose = () => setShowModal(prev=>!prev);
    const handleShowModal = () => setShowModal(prev=>!prev); 
    // const [replyInput, setReplyInput] = useState(<></>);
    
    const rateRefs = useRef([]);
    // const replyRefs = useRef([]);
    const [showReplyInputs, setShowReplyInputs] = useState({});
    const readMoreRefs = useRef([]);
    const textRef = useRef();
    const rateNum = useRef(user?.userID); //user ID
    const handleReplyInput = (e) => {
        const {id} = e.target;
        setShowReplyInputs(prev => ({...prev,id}));
        // e.target.nextElementSibling.innerHTML = ReactDOMServer.renderToStaticMarkup(<Form.Control type='text' id='replyTxt' size="sm" onChange={()=>console.log("l")}placeholder="Write a reply..."/>);
    }  
    const fillStar = (e)=>{
        //set rate value;
        rateNum.current.value = parseInt(e.target.id) + 1;
        //clear star rates
        for(let j=e.target.id; j<=4; j++)
            rateRefs.current[j].src = require("../assets/icons/unfilledstar.png");
        //fill star rates
        for(let i=0; i<=e.target.id; i++)
            rateRefs.current[i].src = require("../assets/icons/filledstar.png");
    }
    const handleSubmit = async(e) => {
        e.preventDefault();
        const {response, json} = await getRequest(`/bookings/client/${user?.userID}/school/${school.user_ID}`);
        if(response.status === 200){
            const reviewObj = {
                booking_ID: json[0].booking_ID, //get client's booking ID
                score: rateNum.current.value,
                message: textRef.current.value
            }
            //post new review
            const rawResponse = await postRequest('/ratings', reviewObj);
            // const content = await rawResponse.json();
        }
        handleClose();
    }
    const ReviewForm = (
        <Form onSubmit={handleSubmit}>
            <Form.Group>
            {/* <Form.Label>Write your message</Form.Label> */}
            <div className='text-center' style={{marginBottom: "15px"}}>
                {[1,2,3,4,5].map((v,i)=>{
                    return <span><img ref={(el)=>rateRefs.current[i]=el} id={i} style={{cursor: "pointer"}} onClick={fillStar} src={(i===0)?require("../assets/icons/filledstar.png"):require("../assets/icons/unfilledstar.png")} height={40}/></span>
                })}
                <input type={"hidden"} ref={rateNum} value={1}/>
            </div>
            <Form.Control as="textarea" rows={3} required ref={textRef} onChange={(e)=>textRef.current.value = e.target.value} placeholder="Share how was your experience with this driving school"/>
            </Form.Group>
            <hr/>
            <CustomButton text="Cancel" onClick={handleClose} color="dark"/>
            <span> </span>
            <CustomButton text="Post"/>
        </Form>            
        );
    const handleShowFullMsg = (e) => {
        const {id} = e.target;
        e.target.innerHTML = "";
        console.log(readMoreRefs.current[id].innerHTML = reviews[id].message);
    }
    if(loading){return(<Loader/>);}     
    return (
        <>
            <Card.Title>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <div  style={{textTransform: "capitalize"}}>
                        <u>{school.school_name}</u>
                    </div>
                    {isAuthenticated && <CustomButton text="Write a review" onClick={handleShowModal}/>}
                </div>
                <CustomModal showModal={showModal} title={school.school_name} bodyContent={ReviewForm} handleClose={handleClose}/>
            </Card.Title>
            <Card.Text>
            {(reviews.length===0) ?   
                <Alert heading="Sorry, no reviews yet" text={`No client made a review regarding ${school.school_name}`} color="info"/>        
                : 
                <>
                    <div style={{display: "flex"}}>
                        <h5>{average.toFixed(1)}</h5><img src={require(`../assets/icons/${Math.floor(average)}star.png`)} height={10}/><small>({total}) reviews</small>
                    </div>
                    <hr/>
                    {reviews.map((review, index) =>(
                        <div style={{display: "flex", marginBottom: "30px"}} key={review.rating_ID}>
                            <ProfileAvatar profileImage={review.profile_img}/>
                            <div style={{marginLeft: "15px"}}>
                                <div>
                                    <b>{review.name+" "+review.surname}</b>
                                </div>
                                <div style={{display: "flex", justifyContent: "space-between"}} className="text-muted">
                                    <small>{(review.score).toFixed(1)}<img src={require(`../assets/icons/${review.score}star.png`)} height={10}/></small>                        
                                    <small>{timeSince(new Date(review.date_added))}</small>
                                </div>
                                <span ref={el=>readMoreRefs.current[index]=el}>{shorterText(review.message, 140)}</span>
                                {(review.message.length > 140) && 
                                <span className='text-muted' id={index} style={{cursor: "pointer"}} onClick={handleShowFullMsg}> see more</span>
                                }
                                <br/>
                                <Link style={{textDecoration:"none"}}>
                                    <small className='text-muted'>like</small> 
                                </Link>
                                <span> </span>
                                <small className='text-muted' style={{cursor: "pointer"}} id={index} onClick={handleReplyInput}>reply</small>
                                {(showReplyInputs?.id === index) && <Form.Control type='text' id='replyTxt' size="sm" onChange={()=>console.log("l")} placeholder="Write a reply..."/>}
                            </div>
                        </div>
                    ))}
                </>
            }
            </Card.Text>
        </>        
    );
}

export default Reviews;