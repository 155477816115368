import { Link } from 'react-router-dom';
import React, { useState } from "react";
import { getMetaData } from "../../lib/helperFunctions";
import ClientsTable from '../../components/ClientsTable';
import { MdAddCircle } from 'react-icons/md';
import useClient from "../../hooks/useClient";
import CustomModal from "../../components/CustomModal";
import MessageForm from '../../components/MessageForm';
import { useAuthUser, useAuthHeader } from "react-auth-kit";
import { authenticatedPatchRequest } from '../../requests/apiRequests';
import { useFetchAuth, useCheckSchool, useCheckClient } from "../../hooks/auth";
import { ADMIN_RELATIVE_PATH } from '../../config/config';
import { Nav } from 'react-bootstrap';
import NavBar from './NavBar';
const styles = {
  body: {
    padding: "100px 50px 50px 50px",
  },
};

function DeactiveClients() {
  useFetchAuth();
  useCheckSchool(); 
  useCheckClient();
  
  const user = useAuthUser()();
  const authHeader = useAuthHeader();
  const [currentPage, setCurrentPage] = useState(1);
  const meta = getMetaData("Admin | Partner with SimplifyDrive");
  const {client} = useClient(authHeader());
  const [recipients, setRecipients] = useState([])
  const [selectAll, setSelectAll] = useState(false);
  const [showMessagingModal, setShowMessagingModal] = useState(false);

  
  function handlePageChange(pageNumber) {
    setCurrentPage(pageNumber);
  }

  const handleMessagingModal = ()=>{
    setShowMessagingModal(prev=>!prev); 
  }

  const handleDelete = (chipIndex) => {
    setRecipients(recipients => recipients.filter((recipient,index)=>chipIndex !== index));
  }; 


  const getDeactiveClients = (array, active) => {
    return array.filter(item => item.status === active);
  };

  const deactiveClients = getDeactiveClients(client, 0);
 
  const getClientsData = (deactiveClients) => {
    return deactiveClients.map((client, i) => ({
      name: client.name,
      surname: client.surname,
      cell_number: client.cell_number,
    }));
  };

  const clientsData = getClientsData(deactiveClients);

  return (
    <>
    <NavBar/>
    <div className="App">
      
      <div className="dashboard-container">
        
        <div className="dashboard-header">
          <div className="dashboard-links">
          <div className="dashboard-links">
            <Link to={ADMIN_RELATIVE_PATH} className="dashboard-link">
              Dashboard
            </Link>
            <span className="dashboard-separator">/</span>
            <Link className="current-link">
              Deactive Clients
            </Link>
          </div>

          </div>
          <Link className="dashboard-link">
            <MdAddCircle size={35} />
          </Link>
        </div>
      {(clientsData.length > 0) &&
        <ClientsTable 
          data={clientsData}
          columns={Object.keys(clientsData[0])}
          title="Deactive Clients"
          currentPage={currentPage}
          onPageChange={handlePageChange}
        
        />
    }
    </div>
    </div>
    </>
    
  );
}

export default DeactiveClients;
