import React, { useState, useRef } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import jsPDF from "jspdf";
import "jspdf-autotable";
import './table.css'
import CustomModal from "./CustomModal";
import MessageForm from './MessageForm';
import Table from 'react-bootstrap/Table';

 
export default function ClientsTable({
  data,
  columns,
  title,
  currentPage,
  onPageChange,
  onSubcription
}) {
    const [recipients, setRecipients] = useState([]);
    const [showMessagingModal, setShowMessagingModal] = useState(false);
    const checkBoxRefs = useRef([]);

  const options = [50, 100];
  const [itemsPerPage, setItemsPerPage] = useState(50); // default value is 50
  const [searchTerm, setSearchTerm] = useState("");
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  
  const currentItems = data
  
    .filter((item) =>
      Object.values(item)
        .join(" ")
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    )
    .slice(startIndex, endIndex);

  const exportToCSV = () => {
    const csv = [      columns.join(","), // header row      ...data.map((item) => columns.map((col) => item[col]).join(",")),
    ].join("\n");

    const csvFile = new Blob([csv], { type: "text/csv" });
    const downloadLink = document.createElement("a");
    downloadLink.download = `${title}.csv`;
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const exportToPDF = () => {
    const doc = new jsPDF();

    doc.autoTable({
      head: [columns],
      body: data.map((item) => columns.map((col) => item[col])),
    });

    doc.save(`${title}.pdf`);
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(parseInt(event.target.value));
    onPageChange(1); // go back to the first page when changing items per page
  };
//   Check box
  const handleCheckboxChange = (e, client) => {
    const isChecked = e.target.checked;
    if (isChecked) {
        // add client to recipients
      setRecipients([...recipients, client]);
    } else {
        // remove client from recipients
      setRecipients(recipients.filter(recipient => recipient.email !== client.email));
    }
  };
  const handleCheckAll = (e) => {
    //clear recipients
    setRecipients([]);
    const isChecked = e.target.checked;
        data.map((client,i) => {
            if (isChecked) {
                checkBoxRefs.current[i].checked = true;
                recipients.push(client);
                setRecipients(recipients);
            }else{
                checkBoxRefs.current[i].checked = false;
                setRecipients([]);
            }
        });
        

  }
//   Modal
  const handleMessagingModal = ()=>{
    setShowMessagingModal(prev=>!prev); 
  }
  const handleDelete = (chipIndex) => {
    setRecipients(recipients => recipients.filter((recipient,index)=>chipIndex !== index));
  };   
  const handleSendMessage = (clients) => {
    setRecipients(clients);
    handleMessagingModal();
  };  
  return (
    <div className="content-wrapper">
      <div className="card">
        <div className="card-body">
          <h4 className="card-title">{title}</h4>
          <div className="row">
            <div className="col-12">
              <div className="table-responsive">
                <div className="row">
                  <div className="col-lg-4 row">

                    <div className="col-9">
                        <input
                        type="text"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="form-control mb-3"
                        />
                    </div>

                    <div className="col-3">
                        <div className="dropdown d-inline-block mr-2">
                            <button
                            className="btn border border-secondary dropdown-toggle"
                            type="button"
                            id="itemsPerPageDropdown"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            >
                            {itemsPerPage}
                            </button>
                            <div className="dropdown-menu" aria-labelledby="itemsPerPageDropdown">
                            {options.map((option) => (
                                <button
                                key={option}
                                className="dropdown-item"
                                onClick={handleItemsPerPageChange}
                                value={option}
                                >
                                {option}
                                </button>
                            ))}
                            </div>
                        </div>
                    </div>

                     
                  </div>
                  <div className="col-lg-8 text-right">
                    <button className="btn btn-dark mr-2" onClick={() => handleSendMessage(recipients)}>
                      <i className="fa fa-paper-plane" aria-hidden="true"></i> Send Bulk Message
                    </button>
                    <button className="btn btn-primary mr-2" onClick={() => window.print()}>
                    <i className="fa fa-print" aria-hidden="true"></i> Print
                    </button>
                    {/* <ReactHTMLTableToExcel
                      className="btn btn-success mr-2"
                      table="order-listing"
                      filename={`${title}`}
                      sheet="Sheet"
                      buttonText={
                        <>
                          <i className="fa fa-file-excel mr-1" aria-hidden="true"></i> Export to Excel
                        </>
                      }
                    /> */}
                    {/* <button className="btn btn-danger" onClick={exportToPDF}>
                      Export to PDF
                    </button>
                    <button className="btn btn-info ml-2" onClick={exportToCSV}>
                      Export to CSV
                    </button> */}
                  </div>
                </div>
                {/* starts the table */}
                <Table responsive size="sm" className="text-nowrap">
                  <thead>
                    <tr>
                      {/* display the header titles of the table */}
                      <th style={{padding: ".75rem .25rem", width: "40px"}}>
                          <input
                          type='checkbox'
                          //value={client.quote_ID}
                          // checked={recipients.some(recipient => recipient.quote_ID === client.quote_ID)}
                          onChange={(e) => handleCheckAll(e)}
                          />
                      </th>
                      {columns.map((column) => (
                        (column != "id" && column != "client_id") && <th key={column} style={{padding: ".75rem .25rem"}}>{column}</th>
                      ))}
                      <th style={{padding: ".75rem .25rem"}}>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* display each row and its columns */}
                    {currentItems.map((client, index) => (
                      <tr key={index}>
                        <th scope="row">
                            <input
                            type='checkbox'
                            // add this element
                            ref={el=>checkBoxRefs.current[index]=el}
                            value={client.email}
                            // checked={recipients.some(recipient => recipient.quote_ID === client.quote_ID)}
                            onChange={(e) => handleCheckboxChange(e, client)}
                            />
                        </th>
                        {/* display the columns */}
                        {columns.map((column) => (
                          (column != "id" && column != "client_id") && <td key={column}>{client[column.toLowerCase()]}</td>
                        ))}
                        <td>
                          {(title === "Subscriptions" && client.paid == 0) && <button onClick={() => onSubcription({subID:client.id, clientID: client.client_id, subType: client.type})} className="btn btn-sm btn-outline-info border border-info m-1"><i className="fa fa-tick"  aria-hidden="true"></i> Subscribe</button>}
                          <button onClick={() => handleSendMessage([client])} className="btn btn-sm btn-outline-primary border border-primary m-1">
                        <i className="fa fa-tick"  aria-hidden="true"></i> Send Message</button></td>
                      </tr>
                    ))}
                  </tbody>
                  
                </Table>

                <nav className="mt-3">
                    <ul className="pagination">
                        <li className={`page-item${currentPage === 1 ? " disabled" : ""}`}>
                            <a className="page-link" href="#" onClick={() => onPageChange(currentPage - 1)}> Previous</a>
                        </li>

                        {Array.from({length: Math.ceil(data.length / itemsPerPage),}).map((_, index) => {
                        if (index + 1 === 1 || index + 1 === Math.ceil(data.length / itemsPerPage)) {
                            // display the first or last page number
                            return (
                            <li className={`page-item${currentPage === index + 1 ? " active" : ""}`} key={index}>
                                <a className="page-link" href="#" onClick={() => onPageChange(index + 1)}>{index + 1}</a>
                            </li>
                            );
                        } else if (index + 1 === currentPage) {
                            // highlight the current page
                            return (
                            <li className="page-item active" key={index}>
                                <span className="page-link">{index + 1}
                                    <span className="sr-only">(current)</span>
                                </span>
                            </li>
                            );
                        } else if (
                            index + 1 >= currentPage - 2 && 
                            index + 1 <= currentPage + 2
                        ) {
                            // show only 5 page numbers around the current page
                            return (
                            <li className="page-item" key={index}>
                                <a className="page-link" href="#" onClick={() => onPageChange(index + 1)}>
                                    {index + 1}
                                </a>
                            </li>
                            );
                        } else if (
                            (index + 1 === currentPage - 3 && currentPage > 5) ||
                            (index + 1 === currentPage + 3 && currentPage < Math.ceil(data.length / itemsPerPage) - 4)
                        ) {
                            // display an ellipsis before or after the current page range
                            return (
                            <li className="page-item" key={index}>
                                <span className="page-link">...</span>
                            </li>
                            );
                        } else {
                            // don't show this page number
                            return null;
                        }
                        })}

                        <li className={`page-item${ currentPage === Math.ceil(data.length / itemsPerPage) ? " disabled" : "" }`}>
                            <a className="page-link" href="#" onClick={() => onPageChange(currentPage + 1)}>
                                Next
                            </a>
                        </li>
                    </ul>
                    </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomModal showModal={showMessagingModal} handleClose={handleMessagingModal} title="Send Message" bodyContent={<MessageForm recipients={recipients} onDelete={handleDelete} onSubmit={()=>{setShowMessagingModal(false); setRecipients([])}}/>}/>
    </div>
  ); 
}