import {useEffect, useState} from 'react';
import {authenticatedGetRequest} from '../requests/apiRequests'
function useAllBookings(token) {
    const [bookings,setBookings] = useState([]);
    const [bookingsLength, setBookingsLength] = useState(0);   
    const [loading, setLoading] = useState(false);
    const getBookings = async () => {
        try {
          setLoading(true); // Set loading before sending API request
          var {response, json} = await authenticatedGetRequest(`/bookings/`, token);
          if(response.status !== 404){
            setBookings(json);
            setBookingsLength(json.length);
          }

       } catch (error) {
         console.error(error);
       }
       setLoading(false); // Stop loading
     }
     useEffect(() => {
      getBookings();
      }, []);    
    return ( {bookings, bookingsLength, loading} );
}

export default useAllBookings;