import {useEffect, useState} from 'react';
import {authenticatedGetRequest} from '../requests/apiRequests'
function useDetailedBookings(type, token) {
    const [detailedBookings,setDetailedBookings] = useState([]);
    const [loading, setLoading] = useState(false);
    const getDetailedBookings = async () => {
        try {
          setLoading(true); // Set loading before sending API request
          var {response, json} = await authenticatedGetRequest(`/bookings/${type}`, token);
          if(response.status !== 404){
            setDetailedBookings(json);
          }else{
            setDetailedBookings([]);
          }

       } catch (error) {
         console.error(error);
       }
       setLoading(false); // Stop loading
     }
     useEffect(() => {
      getDetailedBookings();
      }, []);
    return ( detailedBookings );
}

export default useDetailedBookings;