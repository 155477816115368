import React from "react";
import { getMetaData } from "../../lib/helperFunctions";
import { useAuthUser, useAuthHeader } from "react-auth-kit";
import { useFetchAuth } from "../../hooks/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons"; // Update the import to use regular icons
import NavBar from "./NavBar";
import ProductCards from "../../components/ProductCards";

const styles = {
  body: {
    padding: "100px 50px 50px 50px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
  heading: {
    fontSize: "36px",
    color: "#fa0505", // Text color for canceled message
    marginBottom: "20px",
  },
  description: {
    fontSize: "18px",
    marginBottom: "20px",
  },
  icon: {
    fontSize: "48px",
    color: "#fa0505", // Color of the cancel icon
    marginBottom: "20px",
  },

};

function Cancel() {
  // useFetchAuth();

  const user = useAuthUser()();
  const authHeader = useAuthHeader();
  const meta = getMetaData("Client | Partner with SimplifyDrive");

  return (
    <>
    <NavBar />
    <div style={styles.body}>
      <FontAwesomeIcon icon={faTimesCircle} style={styles.icon} />
      <h1 style={styles.heading}>Payment Cancelled</h1>
      <p style={styles.description}>
        Your payment has been cancelled. If you need assistance, please contact our support team.
      </p>
      {/* Advertising Links as Cards */}
      <ProductCards />
    </div>
    </>
  );
}

export default Cancel;
