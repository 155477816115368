import Navigation from "../../components/Navigation";
import Button from "../../components/CustomButton";
import {useIsAuthenticated, useSignOut} from 'react-auth-kit';
import {useNavigate} from "react-router-dom";
import { ADMIN_RELATIVE_PATH, SCHOOL_RELATIVE_PATH, BASE_URL } from "../../config/config";
function NavBar() {
    const BASE_RELATIVE_PATH = BASE_URL + ADMIN_RELATIVE_PATH;
    const isAuthenticated = useIsAuthenticated()();
    const signOut = useSignOut();
    const navigate = useNavigate();
    const logout = () => {
        signOut();
        navigate(`${ADMIN_RELATIVE_PATH}/login`);
    }    
    const menuItems = {
        leftItems: !isAuthenticated ? [{}] : [{item:"Dashboard",url:`${BASE_RELATIVE_PATH}`}],
        rightItems: !isAuthenticated ? [{item:"Client App",url:`${BASE_URL}/login`},{item:"Driving School App",url:`${BASE_URL + SCHOOL_RELATIVE_PATH}/login`}] : [{item: <Button onClick={logout} text="Log Out" />, url:""}]
    }
    return <Navigation menuItems={menuItems}/>;
}
export default NavBar;