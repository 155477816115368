import { API_BASE_URL } from "../config/config";


export const filesPostRequest = async (path, formData) => {
  return await fetch(API_BASE_URL + path, {
    method: 'POST',
    body: formData
  });
}

export const authenticatedFilesPostRequest = async (path, formData, token) => {
  return await fetch(API_BASE_URL + path, {
    method: 'POST',
    headers: {
      'Authorization': token,
    },
    body: formData
  });
}

export const postRequest = async (path, rawObj) => {
  return await fetch(API_BASE_URL + path, {
    method: 'POST',
    headers: {
      'Accept':'application/json',
      'Content-Type':'application/json'
    },
    body: JSON.stringify(rawObj)
  });
}


export const authenticatedPostRequest = async (path, rawObj, token) => {
  return await fetch(API_BASE_URL + path, {
    method: 'POST',
    headers: {
      'Accept':'application/json',
      'Content-Type':'application/json',
      'Authorization': token,
    },
    body: JSON.stringify(rawObj)
  });
}

export const authenticatedPatchRequest = async (path, rawObj, token) => {
  return await fetch(API_BASE_URL + path, {
    method: 'PATCH',
    headers: {
      'Accept':'application/json',
      'Content-Type':'application/json',
      'Authorization': token,
    },
    body: JSON.stringify(rawObj)
  });
}

export const authenticatedGetRequest = async (path, token) => {
  const response = await fetch(API_BASE_URL + path, {
    method: 'GET',
    headers: {
      'Accept':'application/json',
      'Content-Type':'application/json',
      'Authorization': token,
    },
  });
  const json = await response.json();
  return {response, json}; 
}

export const getRequest = async (path) => {
  const response = await fetch(API_BASE_URL + path);
  const json = await response.json();
  return {response, json};  
}

export const getFileRequest = (path) => {
  const response = API_BASE_URL + path;
  return response;  
}
 