import {useEffect, useState} from 'react';
import { getRequest } from '../requests/apiRequests';
function useReviews(school) {
    const [reviews, setReviews] = useState([]) ;
    const [total, setTotal] = useState(0);
    const [average, setAverage] = useState(0.0);

    const [loading, setLoading] = useState(false);
    const getReviews = async () => {  
        try {
          setLoading(true); // Set loading before sending API request
          const {response, json} = await getRequest(`/ratings/school/${school.user_ID}`);
          setLoading(false); // Stop loading
          if(response.status !== 404){
            setReviews(json);
            //total reviews
            setTotal(json.length);
            //avarage reviews
            setAverage(json.reduce((total, next) => total + next.score, 0) / json.length);
          }else{
            setReviews([]);
          }
        } catch (error) {
          setLoading(false); // Stop loading in case of error
          console.error(error);
        }
      }
      useEffect(() => {
        getReviews();
      }, []);    
    return ( {reviews,total,average,setReviews,loading} );
}

export default useReviews;