import {useEffect, useState} from 'react';
import {getRequest} from '../requests/apiRequests'
function useSchools(province) {
    const [schools,setSchools] = useState([]);
    const [loading, setLoading] = useState(false);
    const getSchools = async () => {
        try {
          setLoading(true); // Set loading before sending API request
          const {response, json} = await getRequest(`/schools/province/${province}`);
          if(response.status === 200){
            setSchools(json);
         }else if(response.status === 401){
          console.log("access denied");
         }
       } catch (error) {
         console.error(error);
       }
       setLoading(false); // Stop loading
     }
     useEffect(() => {
        getSchools();
      }, []);    
    return ( {schools, loading} );
}

export default useSchools;