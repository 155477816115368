import React from "react";
import BookingForm from "../../components/BookingForm";
import NavBar from "./NavBar";

const styles = {
  container: {
    padding: "20px",
    display: "flex",
    justifyContent: "center",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
    backgroundColor: "#ffffff",
    maxWidth: "80vw",
    margin: "0 auto",
  },
  title: {
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "20px",
    color: "#333333",
    fontFamily: "Arial, sans-serif",
  },
};

function BookingLearners() {
  return (
    <>
    <NavBar />
    <div className="App">
        <BookingForm title="Learner's" />
      </div>
    </>
      
  );
}

export default BookingLearners;
