import { useEffect, useState } from 'react';
import { authenticatedGetRequest } from '../requests/apiRequests';

function useCoupons(token, coupon) {
    const [coupons, setCoupons] = useState([]); // Initialize as an empty array
    const [loading, setLoading] = useState(false);

    const getCoupons = async () => {
        setLoading(true); // Set loading before sending API request
        try {
            const { response, json } = await authenticatedGetRequest(`/coupons/${coupon}`, token);
            if (response.status !== 404) {
                setCoupons(json);
            } else {
                setCoupons([]); // Set to empty array on 404
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false); // Stop loading
        }
    };

    useEffect(() => {
        getCoupons();
    }, [token]); // Add token to the dependency array

    return { coupons, loading };
}

export default useCoupons;
