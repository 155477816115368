import React from 'react';
import NavBar from './NavBar';

function PrivacyPolicy(props) {
    return (
        <div className="App" style={{color: '#4b4b4b'}}>
            <NavBar />
            <h1 className='text-center'>Driving School Partner Privacy Policy</h1>
            <div className='text-center text-muted'><i><b>Effective Date: 25/01/2024</b></i></div>
            <br/>
            Thank you for partnering with <i><b>SimplifyDrive</b></i> ("we," "us," or "our") to provide quotations to users seeking driving school services. This Privacy Policy outlines how we collect, use, and safeguard the information you provide to us in the course of our collaboration.
            <br/><br/>

            <h3>1. Information We Collect</h3>
            <b>1.1 Contact Information:</b><br/>
            - To facilitate the quotation process, we collect contact information for your driving school, including but not limited to your business name, address, phone number, and email address.
            <br/>
            <b>1.2 Service Information:</b><br/>
            - We may collect information about the services offered by your driving school, including pricing, packages, and any other details necessary to provide accurate quotations.
            <br/><br/>

            <h3>2. How We Use Your Information</h3>
            <b>2.1 Quotation Services:</b><br/>
            - The information you provide is used to generate and deliver quotations to users who have expressed interest in driving school services.
            <br/>
            <b>2.2 Communication:</b><br/>
            - We may use your contact information to communicate with you about user inquiries, updates to our services, and other relevant information.
            <br/><br/>

            <h3>3. Information Sharing</h3>
            <b>3.1 Users Seeking Quotations:</b><br/>
            - Your driving school information, including pricing details, may be shared with users who have submitted requests for quotations in your area.
            <br/>
            <b>3.2 Legal Compliance:</b><br/>
            - We may disclose your information if required by law or in response to a subpoena or similar legal process.
            <br/><br/>

            <h3>4. Security</h3>
            We take reasonable measures to protect the information you provide from unauthorized access, disclosure, alteration, and destruction.
            <br/><br/>

            <h3>5. Your Choices</h3>
            <b>5.1 Updating Your Information:</b><br/>
            - You can update or correct your driving school information by contacting us at <b>school@simplifydrive.co.za</b>.
            <br/><br/>

            <h3>6. Changes to This Privacy Policy</h3>
            We reserve the right to update this Privacy Policy at any time. We will notify you of any changes by posting the new Privacy Policy on this page.
            <br/><br/>
            <h3>7. Contact Us</h3>
            If you have any questions or concerns about this Privacy Policy, please contact us at <b>school@simplifydrive.co.za</b>.<br/>
            By partnering with <i><b>SimplifyDrive</b></i>, you agree to the terms and conditions of this Privacy Policy.      
        </div>
    );
}

export default PrivacyPolicy;