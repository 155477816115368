import {Form} from "react-bootstrap";
import CustomButton from "./CustomButton";
const styles = {
    formContainer: {maxWidth: "500px"},
    heading: {textAlign: "center", marginBottom: "30px"} 
}    

function RequestForm(props) {
    const {province, handleSubmit, setQuery} = props;
    return ( 
        <div style={styles.formContainer}>
            {/* <h3 style={styles.heading}>{title} Quotation</h3> */}
            <Form>
                <Form.Group controlId="province" className="mb-3">
                    <Form.Label>Province:</Form.Label>
                    <Form.Control type="text" required placeholder="Province" value={province} disabled/>
                </Form.Group>
                <Form.Group controlId="city:" className="mb-3">
                    <Form.Label>City:</Form.Label>
                    <Form.Control type="text" required placeholder="City" onChange={e=> {setQuery(e.target.value)}}/>
                </Form.Group>
                <hr/>
                <CustomButton text="Send Request" size="md" onClick={handleSubmit}/>      
            </Form>
        </div>    
     );
}

export default RequestForm;