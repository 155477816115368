import React from 'react';
import { Link } from 'react-router-dom'; // Import Link for navigation
import StatusCard from '../../components/StatusCard';
import Navigation from '../../components/Navigation';
import { MdPendingActions, MdApproval, MdAddCircle } from 'react-icons/md';
import useBookings from "../../hooks/useBookings";
import { useFetchAuth, useCheckAdmin, useCheckSchool } from "../../hooks/auth";
import '../../App.css';
import { useAuthUser, useAuthHeader } from 'react-auth-kit';
import NavBar from './NavBar';
import Card from 'react-bootstrap/Card';
import { CLIENT_RELATIVE_PATH } from '../../config/config';
import MultipleChoice from '../../components/MultipleChoice';
import ProductCards from '../../components/ProductCards';
import Testimony from '../../components/Testimony';

function Dashboard() {
  useFetchAuth();
  useCheckAdmin();
  useCheckSchool();

  const user = useAuthUser()();
  const authHeader = useAuthHeader();

  const { bookings } = useBookings(authHeader());
  const quotesNum = 1;

  const getScheduled = (array, scheduled) => {
    return array.filter(item => item.scheduled === scheduled);
  };
  const sbookings = getScheduled(bookings, 1);

  return (
    <>
    <NavBar/>
    <div className="App">
      <div className="dashboard-container">
        <div className="dashboard-title-container">
          <h2 className="dashboard-title">Dashboard</h2>
        </div>
        
        <div className="dashboard-header">
          <div className="dashboard-links">

          </div>
          {/* <Link to={`${CLIENT_RELATIVE_PATH}/create-booking`} className="dashboard-link">
            <MdAddCircle size={35} />
          </Link> */}
        </div>
        {/* <div className="row">
          <div className="col-sm-3 mt-1">
            <StatusCard
              title="Road Signs & Markings"
              href={`${CLIENT_RELATIVE_PATH}/road-signs-booklets`}
              number={bookings.length}
              icon={<MdPendingActions color="green" />}
            />
          </div>
          <div className="col-sm-3 mt-1">
            <StatusCard
              title="Rules Of The Road"
              href={`${CLIENT_RELATIVE_PATH}/road-rules-booklets`}
              number={bookings.length}
              icon={<MdPendingActions color="green" />}
            />
          </div>
          <div className="col-sm-3 mt-1">
            <StatusCard
              title="Vehicle Controls"
              href={`${CLIENT_RELATIVE_PATH}/vehicle-controls-booklets`}
              number={bookings.length}
              icon={<MdPendingActions color="green" />}
            />
          </div>
        </div>     */}
        <div className="row">
          <div className="col-sm-3 mt-1">
            <StatusCard
              title="Total Bookings"
              href={`${CLIENT_RELATIVE_PATH}/bookings`}
              number={bookings.length}
              icon={<MdPendingActions color="green" />}
            />
          </div>

          <div className="col-sm-3 mt-1">
            <StatusCard
              title="Scheduled Bookings"
              href={`${CLIENT_RELATIVE_PATH}/scheduled-bookings`}
              number={sbookings.length}
              icon={<MdApproval color="#20c997" />}
            />
          </div>
        </div>

        <ProductCards />

      </div>
    </div>

    </>
  );
}

export default Dashboard;