import { Form } from "react-bootstrap";
import CustomButton from "./CustomButton";
import { useState } from "react";
import {useNavigate } from "react-router-dom";
import { SCHOOL_RELATIVE_PATH } from "../config/config";
const styles = {
  formContainer: { maxWidth: "500px" },
  heading: { textAlign: "center", marginBottom: "30px" },
};

function PackageSelect() {
  const [selectedPackage, setSelectedPackage] = useState("");
  const navigate = useNavigate();

  const handlePackageSelect = (event) => {
    setSelectedPackage(event.target.value);
  };

  const handleButtonClick = () => {
    if (selectedPackage !== "") {
      navigate(`${SCHOOL_RELATIVE_PATH}/add-package/${selectedPackage}`);
    }
  };

  return (
    <div style={styles.formContainer}>
      <Form>
        <Form.Group controlId="packageSelect">
          <Form.Label>Select Package</Form.Label>
          <Form.Control as="select" onChange={handlePackageSelect}>
            <option value="">Select a package</option>
            <option value="learners">Learners</option>
            <option value="licence">Licence</option>
            <option value="lesson">Lesson</option>
          </Form.Control>
        </Form.Group>
      </Form>
      <br/>
      {selectedPackage && (
        <CustomButton
          text={`Add ${selectedPackage} package`}
          size="md"
          color="primary"
          onClick={handleButtonClick}
        />
      )}
    </div>
  );
}

export default PackageSelect;
