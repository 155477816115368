import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Input from "./Input";
import TextArea from "./TextArea";
import CustomButton from "./CustomButton";
import useDetailedBookings from "../hooks/useDetailedBookings";
import useClient from "../hooks/useClient";
import useSchoolsStatus from "../hooks/useSchoolsStatus";
import { ToastContainer, toast } from "react-toastify";
import { getMetaData } from "../lib/helperFunctions";
import { authenticatedPatchRequest } from "../requests/apiRequests";
import { useAuthUser, useAuthHeader } from "react-auth-kit";
const styles = {
  body: {
    display: "flex",
    flexDirection: "column",
    width: "40vw",
    backgroundColor: "white",
    borderRadius: "8px",
    padding: "20px 15px",
    margin: "30vh",
  },
  rightButton: {
    marginLeft: "5px",
  },
};

function EditBookingForm() {
  const user = useAuthUser()();
  const authHeader = useAuthHeader();
  const { bookingId } = useParams();
  const { bookingType } = useParams();
  const [type, setType] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [file, setFile] = useState("");
  const [client_ID, setClient_ID] = useState("");
  const [client_name, setClientName] = useState("");
  const [client_cell, setClientCell] = useState("");
  const [client_email, setClientEmail] = useState("");
  const [school_ID, setSchool_ID] = useState("");
  const [school_name, setSchoolName] = useState("");
  const [price, setPrice] = useState("");
  const [loading, setLoading] = useState(true);
  const [start_date, setStartDate] = useState("");
  const [end_date, setEndDate] = useState("");
  const [start_time, setStartTime] = useState("");
  const [end_time, setEndTime] = useState("");
  const { client } = useClient(authHeader());
  const approvedSchools = useSchoolsStatus(1, authHeader());
  console.log(client);
  console.log(approvedSchools);

  const bookings = useDetailedBookings(bookingType, authHeader());

  useEffect(() => {
    const myBooking = bookings.find(
      (booking1) => booking1.booking_ID == bookingId
    );
    if (myBooking) {
      const c_id = myBooking.client_ID;
      const s_id = myBooking.school_ID;
      setClient_ID(c_id);
      setSchool_ID(s_id);
      setType(myBooking.type);
      setPrice(myBooking.price_booked_at);
      setDate(myBooking.date_created);

      // Check if client data is available
      if (client && client.length > 0) {
        const myClient = client.find((client) => client.user_ID == c_id);
        if (myClient) {
          setClientName(myClient.name + " " + myClient.surname);
          setClientCell(myClient.cell_number);
          setClientEmail(myClient.email);
        }
      }

      // Check if approvedSchools data is available
      if (approvedSchools && approvedSchools.length > 0) {
        const mySchool = approvedSchools.find(
          (school) => school.school_ID == s_id
        );
        if (mySchool) {
          setSchoolName(mySchool.name);
        }
      }

      if (type === "lesson") {
        setStartDate(myBooking.start_date);
        setEndDate(myBooking.end_date);
        setStartTime(myBooking.start_time);
        setEndTime(myBooking.end_time);
      } else {
        setDate(myBooking.date);
        setTime(myBooking.time);
        setFile(myBooking.ID_copy);
      }
    }
  }, [bookings, bookingId]);

  // alert(description)

  const formattedDate = new Date().toISOString().substring(0, 10);
  const meta = getMetaData("Driving School | Partner with SimplifyDrive");

  const handleSubmit = async (e) => {
    setLoading(true);

    if (type === "") {
      toast.error("Booking type is empty!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 5000,
      });

      return;
    }

    const formFields =
      type === "lesson"
        ? {
            client_ID: client_ID,
            booking_ID: bookingId,
            school_ID: school_ID,
            school_name: school_name,
            client_email: client_email,
            client_name: client_name,
            price_booked_at: price,
            date_created: date,
            type: type,
            start_date: start_date,
            end_date: end_date,
            start_time: start_time,
            end_time: end_time,
            schedule: 1,
          }
        : {
            client_ID: client_ID,
            booking_ID: bookingId,
            school_ID: school_ID,
            school_name: school_name,
            client_email: client_email,
            client_name: client_name,
            price_booked_at: price,
            date_created: date,
            type: type,
            date: date,
            time: time,
            ID_copy: file,
            schedule: 1,
          };
    try {
      const response = await authenticatedPatchRequest(
        `/bookings/${type}/${bookingId}`,
        formFields,
        authHeader()
      );
      console.log(response);

      if (response.status === 200) {
        setLoading(false);
        toast.success("Booking scheduled successfully!", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
        });
      } else {
        toast.error(
          "There was an error in scheduling the booking! " + response.status,
          {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
          }
        );
      }
    } catch (error) {
      console.error(error);
    }
  };
  const parentDir = "/driving-school";
  return (
    <>
      <ToastContainer />

      <div className="App" style={styles.body}>
        <div className="dashboard-container">
          {/* <div className="dashboard-title-container">
          <h2 className="dashboard-title">Schedule {type}</h2>
        </div> */}

          <div className="dashboard-header">
            <div className="dashboard-links">
              <Link to={`${parentDir}`} className="dashboard-link">
                Dashboard
              </Link>
              <span className="dashboard-separator">/</span>
              <Link className="current-link">Schedule {type}</Link>
            </div>
          </div>
          <hr />
          {/* <Input label="Type:" name="Type" type="text" placeholder="Enter Type" value={type} onChange={(e) => setType(e.target.value)}/> */}

          {type != "lesson" ? (
            <>
              <Input
                label="Date:"
                name="Date"
                type="date"
                placeholder="Select Date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
              />
              <Input
                label="Time:"
                name="Time"
                type="time"
                placeholder="Select Time"
                value={time}
                onChange={(e) => setTime(e.target.value)}
              />
            </>
          ) : (
            <>
              <Input
                label="Start Date:"
                name="Start Date"
                type="date"
                placeholder="Select Start Date"
                value={start_date}
                onChange={(e) => setStartDate(e.target.value)}
              />
              <Input
                label="End Date:"
                name="End Date"
                type="date"
                placeholder="Select End Date"
                value={end_date}
                onChange={(e) => setEndDate(e.target.value)}
              />

              <Input
                label="Start Time:"
                name="Start Time"
                type="time"
                placeholder="Select Start Time"
                value={start_time}
                onChange={(e) => setStartTime(e.target.value)}
              />
              <Input
                label="End Time:"
                name="End Time"
                type="time"
                placeholder="Select End Time"
                value={end_time}
                onChange={(e) => setEndTime(e.target.value)}
              />
            </>
          )}

          <div className="d-flex">
            <CustomButton
              text="Schedule Booking"
              color={`primary`}
              onClick={handleSubmit}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default EditBookingForm;
