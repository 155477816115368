import { useState} from 'react';
import { Link } from 'react-router-dom'; // Import Link for navigation
import StatusCard from '../../components/StatusCard';
import CustomModal from '../../components/CustomModal';
import PackageSelect from '../../components/PackageSelect';
import NavBar from './NavBar';
import { MdPendingActions, MdApproval, MdFilterListAlt,
   MdAddCircle, MdArchive } from 'react-icons/md';
import useQuotes from '../../hooks/useQuotes';
import usePackages from '../../hooks/usePackages';
import useBookings from "../../hooks/useBookings";
import '../../App.css';
import { useFetchAuth, useCheckClient } from "../../hooks/auth";


import {useAuthUser, useAuthHeader} from 'react-auth-kit';
import { SCHOOL_RELATIVE_PATH } from '../../config/config';
function Dashboard() {
  //get current user id
  useFetchAuth(); 
  useCheckClient();
  
  const user = useAuthUser()();
  const authHeader = useAuthHeader()

  console.log(user)

  const {bookings} = useBookings(authHeader());
  const {quotations, loading} = useQuotes(authHeader());
  const {packages} = usePackages(authHeader());
  const quotesNum = quotations.length;
  const packagesNum = packages.length;
  const [showModal, setShowModal] = useState(false);

  const getScheduled = (array, scheduled) => {
    return array.filter(item => item.scheduled === scheduled);
  };
  const sbookings = getScheduled(bookings, 1);

  const nbookings = getScheduled(bookings, 0);

  const handleRequestModal = ()=>{
      setShowModal(prev=>!prev);
  }
    return ( 
      <div className="App">
      <NavBar />
      <div className="dashboard-container">
        <div className="dashboard-title-container">
          <h2 className="dashboard-title">Dashboard</h2>
        </div>
        
        <div className="dashboard-header">
          <div className="dashboard-links">

          </div>
          <Link className="dashboard-link">
            <MdAddCircle size={35} onClick={handleRequestModal}/>
          </Link>
        </div>
        <div className="row">
          <div className="col-sm-3 mt-1">
            <StatusCard href={`${SCHOOL_RELATIVE_PATH}/new-bookings`} title="New Bookings" number={nbookings.length} icon={<MdPendingActions color='green'/>}/>
          </div>
          <div className="col-sm-3 mt-1">
            <StatusCard title="Scheduled Bookings" href={`${SCHOOL_RELATIVE_PATH}/scheduled-bookings`} number={sbookings.length} icon={<MdApproval color='#20c997'/>}/>
          </div>
          {/* <div className="col-sm-3 mt-1">
            <StatusCard title="Without Licence" number={56} icon={<MdAddTask color='#6f42c1'/>}/>
          </div> */}
          <div className="col-sm-3 mt-1">
            <StatusCard  href={`${SCHOOL_RELATIVE_PATH}/packages`} title="Packages" number={packagesNum} icon={<MdArchive color='#6f42c1'/>}/>
          </div>
          <div className="col-sm-3 mt-1">
            <StatusCard  href={`${SCHOOL_RELATIVE_PATH}/quotes`} title="Total Leads" number={quotesNum} icon={<MdFilterListAlt color='#dc3545'/>}/>
          </div>          
        </div>  
        <CustomModal showModal={showModal} handleClose={handleRequestModal} title="Add Package" bodyContent={<PackageSelect />}/>     
      </div>
      </div>   
     );
}

export default Dashboard;