import {useState, useRef, useEffect} from 'react';
import GridList from '../components/GridList';
import useSchools from '../hooks/useSchools';
import {useParams} from "react-router-dom";
import {Form, Button} from 'react-bootstrap';
import axios from "axios";

import '../App.css';
import Card from '../components/Card';
import { MdSearch, MdClose, MdRefresh } from 'react-icons/md';
import { postRequest } from '../requests/apiRequests';
import Loader from '../components/Loader';
import CustomModal from '../components/CustomModal';
import RequestForm from '../components/RequestForm';
import MultiRequestsForm from '../components/MultiRequestsForm';
import DocumentMeta from "react-document-meta";
import {getMetaData} from "../lib/helperFunctions";

import FloatButton from '../components/FloatButton';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import SaveIcon from '@mui/icons-material/Save';
import PrintIcon from '@mui/icons-material/Print';
import ShareIcon from '@mui/icons-material/Share';

import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Alert from '../components/CustomAlert';
import NavBar from './client/NavBar';

function Schools() {
    const [showMultiRequestsModal, setShowMultiRequestsModal] = useState(false);
    const actions = [
        { icon: <FileCopyIcon />, name: 'Request Quotations' },
        // { icon: <FilterAltIcon />, name: 'Advanced Filters' },
        // { icon: <PrintIcon />, name: 'Print' },
        // { icon: <ShareIcon />, name: 'Share' },
      ];

    const {province} = useParams();
    const provinceDescription = "Driving Schools in " + province;
    const meta = getMetaData(provinceDescription + " | Verified by SimplifyDrive");

    const searchRef = useRef();
    const [showAlert, setShowAlert] = useState(false);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const {schools, loading} = useSchools(province);
    const [query, setQuery] = useState("");
    const [searchInput, setSearchInput] = useState(<MdSearch/>);
    const [showSearchInput, setShowSearchInput] = useState(false);
    const [selectedSchools, setSelectedSchools] = useState([]);
    const [ip, setIP] = useState("");


    const handleSearchInput = () => {
        setShowSearchInput(prev => !prev);
        setCount(count => count + 1);
        // clear search results
        setQuery("");
        // console.log(searchRef.current);
    }
    const search = (schools) => {
        return schools.filter(school => school.city.toLowerCase().includes(query.toLocaleLowerCase()));
    }

    const getIpData = async () => {
        const res = await axios.get("https://api.ipify.org/?format=json");
        setIP(res.data.ip);
      };

    const [count, setCount] = useState(0);
    useEffect(() => {

        getIpData();

        if(count > 0){
            //focus on search input
            if(showSearchInput) return searchRef.current.focus();
            setShowAlert(false);
        }
      }, [count]);  
    
      const handleRequest = async (e) => {
        e.preventDefault();
        const rawObj = {
            "province": province,
            "city": query,
            "type": "school",
            "IP_Address": ip,
        };
    
        try {
            const response = await postRequest('/stats', rawObj);
            if (response.status === 200) {
                setShowAlert(true);
                const json = await response.json();
            } else if (response.status === 401) {
                console.log("access denied");
            }
        } catch (error) {
            console.error(error);
        }
        setShowSuccessAlert(prev=>!prev);
        if(showModal) setShowModal(prev=>!prev); //close modal if opened
    };
    
    
    const handleRequestModal = ()=>{
        setShowModal(prev=>!prev);
    }
    const handleMultiRequestsModal = ()=>{
        setShowMultiRequestsModal(prev=>!prev); 
    }    
    const handleSelect = (school) => {
        //check for duplications
        //add school to the selected list
        setSelectedSchools(prev => [...prev, {school}]);
    }
    //   
    const handleDelete = (chipIndex) => {
        setSelectedSchools(selectedSchools => selectedSchools.filter((school,index)=>chipIndex !== index));
    };  
    const handleSelectStyle = (school) => {      
        return selectedSchools.some(schoolObj => schoolObj.school.user_ID === school.user_ID);
    }
    // loading
    if(loading){return(<div className="App"><Loader/></div>)} 
    return (
        <>
        <NavBar/>
        <div className='App'>
            <DocumentMeta {...meta} />
           {/* <Navigation />  */}
            {schools &&
            <div className="App">         
                <div style={{display: "flex", justifyContent: "space-between"}} className="p-2 text-muted">
                    <div><b>{provinceDescription}</b></div>
                    <div>
                        <span>filter by city</span> <span>
                            {!showSearchInput ? 
                                <MdSearch onClick={handleSearchInput}/> 
                            : 
                            <div style={{display: "flex"}}>
                                <Form.Control type='text' ref={searchRef} size="sm" placeholder="search city..." onChange={e=> setQuery(e.target.value)}/>
                                <MdClose style={{margin: "10px", fontSize:"20px"}} onClick={handleSearchInput}/>            
                            </div>
                            }
                        </span>
                    </div>
                </div>
            {/* filter search method not working when using this component */}
                {/* <GridList schools={search(schools)}/> */}
                <div className='p-2'>
                {(schools.length === 0) ? 
                // <p>Sorry, we have no registered driving schools in {province}</p> 
                showSuccessAlert ? <Alert heading="Thank you!" text="Your request was sent successfully" color="success"/> :
                <Alert heading={`Sorry, we have no registered driving schools in ${province}`} text={"Want us to add them?"} color={"info"} onClick={handleRequestModal}/>
                : 
                <>
                    {(search(schools).length === 0) ? 
                    <>
                        {!showAlert ?
                        <Alert heading={"No Driving schools found in this area"} text={`Want us to add driving schools in ${province}, ${query}?`} color={"info"} onClick={handleRequest}/>
                        :
                        <Alert heading="Thank you!" text="Your request was sent successfully" color="success"/>
                        }
                    </>
                    :
                    ""}
                    <div className="grid-style">
                        {search(schools).map(school => 
                        <Card school={school} key={school.user_ID} onSelect={handleSelect} handleSelectStyle={handleSelectStyle}/>)}        
                    </div>
                    {(search(schools).length !== 0) && <div className='text-center mt-4'>
                        {/* <Button><b>Load more schools <MdRefresh/></b></Button> */}
                    </div>}
                </>
                }
            </div>
            </div> 
            }
            <CustomModal showModal={showMultiRequestsModal} handleClose={handleMultiRequestsModal} title="Request Multiple Quotations" bodyContent={<MultiRequestsForm selectedSchools={selectedSchools} onDelete={handleDelete} onSubmit={()=>{setShowMultiRequestsModal(false); setSelectedSchools([])}}/>}/>
            <CustomModal showModal={showModal} handleClose={handleRequestModal} title="Add Driving Schools in:" bodyContent={<RequestForm province={province} handleSubmit={handleRequest} setQuery={setQuery} />}/>
            <FloatButton actions={actions} onClick={handleMultiRequestsModal}/>
        </div>
        </>
        
     );
}

export default Schools;

