import { Link } from 'react-router-dom';
import React, { useState } from "react";
import { getMetaData } from "../../lib/helperFunctions";
import ClientsTable from "../../components/ClientsTable"; 
import { MdAddCircle } from 'react-icons/md';
import useClient from "../../hooks/useClient";
import useQuotes from '../../hooks/useQuotes';
import { useAuthUser, useAuthHeader } from "react-auth-kit";
import { useFetchAuth, useCheckSchool, useCheckClient } from "../../hooks/auth";
import { ADMIN_RELATIVE_PATH } from '../../config/config';
import { Nav } from 'react-bootstrap';
import NavBar from './NavBar';
const styles = {
  body: {
    padding: "100px 50px 50px 50px",
  },
};

function UnregisteredClients() {
  useFetchAuth();
  useCheckSchool(); 
  useCheckClient(); 
  
  const user = useAuthUser()();
  const authHeader = useAuthHeader();
  const [currentPage, setCurrentPage] = useState(1);
  const meta = getMetaData("Admin | Partner with SimplifyDrive");
  const {client} = useClient(authHeader());
  const {quotations, loading} = useQuotes(authHeader());
  
  function handlePageChange(pageNumber) {
    setCurrentPage(pageNumber);
  }

  // const handleMessagingModal = ()=>{
  //   setShowMessagingModal(prev=>!prev); 
  // }

  // const handleDelete = (chipIndex) => {
  //   setRecipients(recipients => recipients.filter((recipient,index)=>chipIndex !== index));
  // }; 

  // Extract emails from client data
  const clientEmails = client.map(clientItem => clientItem.email);
  // Filter out quotations that have corresponding emails in the client data
  const quotationsNotInClient = quotations.filter(quote => !clientEmails.includes(quote.client_email));
  // Create a Map to store unique client_email values and their corresponding data
  const uniqueClientEmailsMap = new Map();
  // Populate the Map with unique client_email values and their corresponding data
  quotationsNotInClient.forEach(quote => {
    if (!uniqueClientEmailsMap.has(quote.client_email)) {
      uniqueClientEmailsMap.set(quote.client_email, quote);
    }
  });
  // Now, uniqueClientEmailsMap contains all the unique client_email values and their corresponding data from quotationsNotInClient
  const uniqueQuotationClients = Array.from(uniqueClientEmailsMap.values());

  const unregisteredClients = uniqueQuotationClients;
 
  const getClientsData = (unregisteredClients) => {
    return unregisteredClients.map((client,i) => ({
      name: client.client_name,
      email: client.client_email,
      cell_number: client.cell_number,
    }));
  };
  const clientsData = getClientsData(unregisteredClients);

  return (
    <>
    <NavBar/>
    <div className="App">
      
      <div className="dashboard-container">
        
        <div className="dashboard-header">
          <div className="dashboard-links">
          <div className="dashboard-links">
            <Link to={ADMIN_RELATIVE_PATH} className="dashboard-link">
              Dashboard
            </Link>
            <span className="dashboard-separator">/</span>
            <Link className="current-link">
              Prospects
            </Link>
          </div>

          </div>
          <Link className="dashboard-link">
            <MdAddCircle size={35} />
          </Link>
        </div>

      {(clientsData.length > 0) && 
        <ClientsTable 
          data={clientsData}
          columns={Object.keys(clientsData[0])}
          title="Prospects"
          currentPage={currentPage}
          onPageChange={handlePageChange}
        
        />
      }
    </div>
    </div>
    </>
  
  );
}

export default UnregisteredClients;
