import { Link, useParams } from 'react-router-dom';
import React, { useState } from "react";
import { getMetaData } from "../../lib/helperFunctions";
import EnhancedTable from "../../components/EnhancedTable";
import usePackages from "../../hooks/usePackages";
import { useAuthUser, useAuthHeader } from "react-auth-kit";
import { useFetchAuth, useCheckClient } from "../../hooks/auth";
import { MdAddCircle } from 'react-icons/md';
import NavBar from './NavBar';
import { SCHOOL_RELATIVE_PATH } from "../../config/config";

const styles = {
  body: {
    padding: "100px 50px 50px 50px",
  },
};

function DetailedPackages() {
  useFetchAuth(); 
  useCheckClient();
  
  const user = useAuthUser()();
  const authHeader = useAuthHeader();
  const { type } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const meta = getMetaData("Driving School | Partner with SimplifyDrive");
  const { packages, loading } = usePackages(authHeader(), type);

  const getPackageTpye = (array, type) => {
    return array.filter(item => item.type === type);
};

const myPackages = getPackageTpye(packages, type);
  
  function handlePageChange(pageNumber) {
    setCurrentPage(pageNumber);
  }
  const onDeletePackage = async (id) => {
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
  
    const options = {
      // weekday: "long",
      day: "numeric",
      month: "long",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
  
    if (!isNaN(date)) {
      // Check if the date is valid
      return date.toLocaleDateString(undefined, options);
    } else {
      // If the date is not valid, return it as is
      return dateString;
    }
  };

  const packagesData = (packages, onDeletePackage) => {
    return packages.map((pack, i) => ({
      id: i + 1,
      price: pack.price,
      type: type != 'test' ? pack.type : pack.test_type,
      date: formatDate(pack.date_added),
      actions: (
        <>
          <Link to={`${SCHOOL_RELATIVE_PATH}/edit-package/${pack.package_ID}/${pack.type}`} className="btn btn-outline-primary border border-primary m-1"><i className="fa fa-eye" aria-hidden="true"></i> View</Link>
          <button onClick={() => onDeletePackage(pack.package_ID)} className="btn btn-outline-danger border border-danger m-1"><i className="fa fa-trash"  aria-hidden="true"></i> Delete</button>
        </>
      ),
    }));
  };
  return (
    <div className="App">
      <NavBar/>
      <div className="dashboard-container">
        {/* <div className="dashboard-title-container">
          <h2 className="dashboard-title">Detailed Scheduled Bookings</h2>
        </div> */}
        
        <div className="dashboard-header">
          <div className="dashboard-links">
          <div className="dashboard-links">
            <Link to={SCHOOL_RELATIVE_PATH} className="dashboard-link">
              Dashboard
            </Link>
            <span className="dashboard-separator">/</span>
            <Link to={`${SCHOOL_RELATIVE_PATH}/packages`} className="dashboard-link">
            My Packages
            </Link>
            <span className="dashboard-separator">/</span>
            <Link className="current-link">
            {type.charAt(0).toUpperCase() + type.slice(1)} Packages
            </Link>
          </div>

          </div>
          <Link className="dashboard-link">
            <MdAddCircle size={35} />
          </Link>
        </div>
      <EnhancedTable 
        data={packagesData(myPackages, onDeletePackage)}
        columns={["ID", "Price", "Type", "Date", "Actions"]}
        title={type.charAt(0).toUpperCase() + type.slice(1)+" Packages"}
        currentPage={currentPage}
        onPageChange={handlePageChange}
      
      />
    </div>
    </div>
  );
}

export default DetailedPackages;
