import React, { useState } from "react";
import Input from "./Input";
import CustomButton from "./CustomButton";
import Select from "./Select";
import { filesPostRequest } from "../requests/apiRequests";
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import { useFetchIPInfo } from "../hooks/auth";
const styles = {
  body: {
    display: "flex",
    flexDirection: "column",
    width: "70vw",
    backgroundColor: "white",
    borderRadius: "8px",
    padding: "20px 15px",
    margin: "30vh",
  },
  rightButton: {
    marginLeft: "5px",
  },
  error: {
    color: "red",
  },
};
const MultiStepForm = () => {
  const formData = new FormData();
  let navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [formFields, setFormFields] = useState({ type: "s", status: 0 });
  const [attachments, setAttachments] = useState([]);
  // const [certificates, setCertificates] = useState([]);
  const [cover_img, setCoverImage] = useState(null);
  const [sliders, setSlideImage] = useState([]);
  const [files, setFiles] = useState(null);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [ipInfo, setIpInfo] = useState(null);

  useFetchIPInfo(setIpInfo)

  const prevStep = () => setStep((prev) => prev - 1);

 

  const handleChange = (event) => {
    setFormFields({ ...formFields, [event.target.name]: event.target.value });
    //formData.append(event.target.name, event.target.value);
  };

  // const handleCertificatesInputChange = (event) => {
  //   setCertificates(event.target.files);
  // }

  const validateImageExtension = (filename) => {
    const validExtensions = ["jpg", "jpeg", "png", "gif"];
    const extension = filename.split(".").pop().toLowerCase();
    return validExtensions.includes(extension);
  };

  const handleCoverImageInputChange = (event) => {
    const image = event.target.files[0];
    if (image) {
      const validExtensions = validateImageExtension(image.name);

      if (validExtensions) {
        const reader = new FileReader();

        reader.onload = function (e) {
          const img = new Image();
          img.src = e.target.result;

          img.onload = function () {
            const width = img.width;
            const height = img.height;

            if (
              width >= 500 &&
              width <= 600 &&
              height >= 500 &&
              height <= 600
            ) {
              setCoverImage(image);
            } else {
              toast.error(
                "Image dimensions must be between 500-600px (width) and 500-600px (height)",
                {
                  position: toast.POSITION.TOP_CENTER,
                  autoClose: 5000,
                }
              );
            }
          };
        };

        reader.readAsDataURL(image);
      } else {
        toast.error(
          "Invalid image format. Supported formats: jpg, jpeg, png, gif",
          {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
          }
        );
      }
    }
  };

  const handleSlideImagesInputChange = (event) => {
    const images = event.target.files;
  
    if (images.length > 0) {
      const validImages = [];
  
      Array.from(images).forEach((image) => {
        const validExtensions = validateImageExtension(image.name);
  
        if (!validExtensions) {
          toast.error("Invalid file extension", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
          });
          return;
        }
  
        const reader = new FileReader();
  
        reader.onload = function (e) {
          const img = new Image();
          img.src = e.target.result;
  
          img.onload = function () {
            const width = img.width;
            const height = img.height;
  
            if (
              width >= 1000 &&
              width <= 1600 &&
              height >= 600 &&
              height <= 900
            ) {
              // Image is valid, add it to the array
              validImages.push(image);
               
            } else {
              toast.error(
                "Image dimensions must be between 1000-1600px (width) and 600-900px (height)",
                {
                  position: toast.POSITION.TOP_CENTER,
                  autoClose: 5000,
                }
              );
            }
          };
        };
  
        reader.readAsDataURL(image); // Read the file as a data URL
      });

      setSlideImage(validImages); // Update state with the valid images
    }
  };
  

  const validateStepOne = () => {
    const errors = {};

    // Validate email format using regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formFields.email) {
      errors.email = "Email address is required";
    } else if (!emailRegex.test(formFields.email)) {
      errors.email = "Invalid email address format";
    }

    // Validate password and confirm password match
    if (!formFields.password) {
      errors.password = "Password is required";
    } else if (formFields.password.length < 5) {
      errors.password = "Passwords must be at least 5 characters long";
    } else if (formFields.password !== formFields.password2) {
      errors.password2 = "Passwords do not match";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const validateStepTwo = () => {
    const errors = {};

    // Validate city
    if (!formFields.city) {
      errors.city = "City is required";
    }

    // Validate code
    if (!formFields.code) {
      errors.code = "Postal Code is required";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const validateStepThree = () => {
    const errors = {};

    // Validate school_name
    if (!formFields.school_name) {
      errors.school_name = "School Name is required";
    }

    // Validate About School
    if (!formFields.about_school) {
      errors.about_school = "About School is required";
    }

    // Validate certificates
    // if (!certificates) {
    //   errors.certificates = "Select Instructor Certificates";
    // }

    // Validate coverImg
    if (!cover_img) {
      errors.cover_img = "Select Cover Image";
    }

    // Validate sliders
    if (!sliders) {
      errors.sliders = "Select Slide Image";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const nextStep = () => {
    if (step === 1) {
      if (validateStepOne()) {
        setStep((prev) => prev + 1);
      }
    } else if (step === 2) {
      if (validateStepTwo()) {
        setStep((prev) => prev + 1);
      }
    }
  };

  const handleAttachment = (e) => {
    // e.target.files.map((file,i) => {
    //   console.log(e.target.files[i]);
    //   //({ ...formFields, [file.name]: e.target.files[i] });
    // });
    const files = Array.from(e.target.files);
    setAttachments([...attachments, attachments.concat(files)]);
    console.log(attachments);

    //setFiles(e.target.files[0]);
    //setFormFields({ ...formFields, [event.target.name]: event.target.value });
  };

  const submitForm = async (e) => {
    if (validateStepThree()) {
      console.log(formFields);

      formData.append("ip_address", ipInfo.ip);
      formData.append("country", ipInfo.country);
      formData.append("user_province", ipInfo.region);
      formData.append("user_city", ipInfo.city);

      // for (let i = 0; i < certificates.length; i++) {
      //     formData.append("certificates", certificates[i]);
      // }

      for (let i = 0; i < sliders.length; i++) {
        formData.append("sliders", sliders[i]);
      }

      formData.append("cover_img", cover_img);

      console.log(formData);
      // return

      Object.entries(formFields).forEach(([key, value]) => {
        formData.append(key, value);
      });

      formData.append("province", selected);

      try {
        const response = await filesPostRequest("/users/register", formData);
        console.log(response);
        if (response.status === 200) {
          setLoading(false);
          setStep((prev) => prev - 1);

          navigate("/driving-school/login");

          toast.success("User was registerd successful! We will review your account and come back to you.", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
          });

        } else {
          setLoading(false);
          setStep((prev) => prev - 1);

          toast.error("User was not registerd!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
          });
        }
      } catch (error) {
        console.error(error);
      }
    }
  };
  const provinces = [
    "Gauteng",
    "Mpumalanga",
    "Limpopo",
    "Eastern Cape",
    "Kwazulu Natal",
    "North West",
    "Cape Town",
    "Northen Cape",
    "Free State",
  ];
  const [selected, setSelected] = useState(provinces[0]);
  switch (step) {
    case 1:
      return (
        <>
          <div style={styles.body}>
            <h4 className="text-center">1. Account Information</h4>
            <hr />
            <Input
              label="Email address:"
              name="email"
              type="email"
              placeholder="Enter email"
              onChange={handleChange}
              value={formFields.email || ""}
            />
            {errors.email && <span style={styles.error}>{errors.email}</span>}
            <Input
              label="Password:"
              name="password"
              type="password"
              placeholder="Enter Password"
              onChange={handleChange}
              value={formFields.password || ""}
            />
            {errors.password && (
              <span style={styles.error}>{errors.password}</span>
            )}
            <Input
              label="Confirm Password:"
              name="password2"
              type="password"
              placeholder="Confirm Password"
              onChange={handleChange}
              value={formFields.password2 || ""}
            />
            {errors.password2 && (
              <span style={styles.error}>{errors.password2}</span>
            )}
            <hr />
            <div className="d-flex">
              <CustomButton text="Next" onClick={nextStep} />
            </div>
          </div>
        </>
      );
    case 2:
      return (
        <>
          <div style={styles.body}>
            <h4 className="text-center">2. Main Business Location</h4>
            <hr />
            <Select
              itemsArray={provinces}
              name="province"
              value={selected}
              onChange={(e) => {
                setSelected(e.target.value);
              }}
              label="Select Province:"
            />
            {errors.province && (
              <span style={styles.error}>{errors.province}</span>
            )}
            {/* <Input label="Province:" type="text" placeholder="Enter province" name="province" onChange={handleChange} value={formFields.province || ''}/> */}
            <Input
              label="City:"
              type="text"
              placeholder="Enter city name"
              name="city"
              onChange={handleChange}
              value={formFields.city || ""}
            />
            {errors.city && <span style={styles.error}>{errors.city}</span>}
            <Input
              label="Postal Code:"
              type="text"
              placeholder="Enter postal code"
              name="code"
              onChange={handleChange}
              value={formFields.code || ""}
            />
            {errors.code && <span style={styles.error}>{errors.code}</span>}
            <hr />
            <div className="d-flex">
              <CustomButton text="Prev" color="dark" onClick={prevStep} />
              <CustomButton
                text="Next"
                style={styles.rightButton}
                onClick={nextStep}
              />
            </div>
          </div>
        </>
      );
    case 3:
      return (
        <>
          <div style={styles.body}>
            <h4 className="text-center">3. Supporting Documents</h4>
            <hr />
            <Input
              label="Driving School Name:"
              type="text"
              placeholder="School Name"
              name="school_name"
              onChange={handleChange}
              value={formFields.school_name || ""}
            />
            {errors.school_name && (
              <span style={styles.error}>{errors.school_name}</span>
            )}
            <Input
              type="text"
              label="About School"
              name="about_school"
              placeholder="write a short description about the driving school"
              onChange={handleChange}
              value={formFields.about_school || ""}
            />
            {errors.about_school && (
              <span style={styles.error}>{errors.about_school}</span>
            )}
            {/* <Input label="Instructor(s) Certificate:" type="file" name="certificates[]" onChange={handleCertificatesInputChange} multiple />
            {errors.certificates && ( <span style={styles.error}>{errors.certificates}</span> )} */}
            <Input
              label="Profile Image:"
              type="file"
              name="cover_img"
              onChange={handleCoverImageInputChange}
            />
            {errors.coverImg && (
              <span style={styles.error}>{errors.cover_img}</span>
            )}
            <Input
              label="Slide Images:"
              type="file"
              naeme="sliders[]"
              onChange={handleSlideImagesInputChange}
              multiple
            />
            {errors.sliders && (
              <span style={styles.error}>{errors.sliders}</span>
            )}
            <hr />
            <div className="d-flex">
              <CustomButton text="Prev" color="dark" onClick={prevStep} />
              <CustomButton
                text="Submit"
                style={styles.rightButton}
                onClick={submitForm}
              />
            </div>
          </div>
        </>
      );
    default:
      return <h4 className="text-center">Form submitted successfully</h4>;
  }
};

export default MultiStepForm;
