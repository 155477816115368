import {Link} from "react-router-dom";
import { CLIENT_RELATIVE_PATH } from "../../config/config";
import NavBar from "./NavBar";
import ListGroup from 'react-bootstrap/ListGroup';

function RoadSignsBooklets() {
    const range = (min, max) => Array.from({ length: max - min + 1 }, (_, i) => min + i);
    const booklets = range(1, 15);
    return (
      <div className="App">
        <NavBar/>
        <h1>Signs Of The Road</h1>
        <ListGroup as="ol" numbered>
            {booklets.map(bookletNum => <ListGroup.Item as="li"><Link to={`${CLIENT_RELATIVE_PATH}/road-signs-b${bookletNum}`} style={{textDecoration:"none"}}>Booklet {bookletNum}</Link></ListGroup.Item>)}
        </ListGroup>
      </div>
    );
  }
  
  export default RoadSignsBooklets;