import {Card} from 'react-bootstrap';
import {Link} from "react-router-dom";
import CustomButton from './CustomButton';
import { MdDone } from 'react-icons/md';
import { CLIENT_RELATIVE_PATH, BASE_URL } from '../config/config';
function LessonCard(props) {
    const {packageOpt} = props;
    const features = packageOpt.features.split(',');
    //  className="text-center"
    return (
        <Card>
            <Card.Header>
                {packageOpt.name}
                <br/>
                <small style={{fontWeight: 'bold'}}>(R{packageOpt.price})</small>
            </Card.Header>
            <Card.Body>
            <Card.Title>{(packageOpt.lesson_num == 1)?"Single Lesson":packageOpt.lesson_num+" Lessons"}</Card.Title>
            <Card.Text>
                {features.map((feature,index) => <div key={index}><small><MdDone/> {feature}</small></div>)}
                {/* <br/>
                <div className="text-center">
                    <small>** Car Included **</small>
                </div> */}
            </Card.Text>
            </Card.Body>
            <Card.Footer className="text-center">
            {/* <Button variant="danger" className="btn btn-sm">Book</Button> */}
            <Link to={`${BASE_URL + CLIENT_RELATIVE_PATH}/booking/school/${packageOpt.school_ID}/lesson/${packageOpt.package_ID}`}>
            <CustomButton text="Book"/>
            </Link>
            </Card.Footer>
        </Card>
    );
}

export default LessonCard;