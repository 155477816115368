import { useEffect, useState} from 'react';
import PieChart from "../../components/PieChart";
import {Table} from 'react-bootstrap';
import { Link } from 'react-router-dom'; // Import Link for navigation
import StatusCard from '../../components/StatusCard';
import { MdPendingActions, MdApproval, MdFilterListAlt, MdAddTask,
   MdAddCircle } from 'react-icons/md';
import { authenticatedGetRequest } from '../../requests/apiRequests';
import useSchoolsStatus from '../../hooks/useSchoolsStatus';
import useSubscriptions from "../../hooks/useSubscriptions";
import useQuotes from '../../hooks/useQuotes';
import useBookings from "../../hooks/useBookings";
import useClient from "../../hooks/useClient";
import useVideo from "../../hooks/useVideo";
import useStudyMaterial from "../../hooks/useStudyMaterial";
import '../../App.css';
import { useFetchAuth, useCheckSchool, useCheckClient } from "../../hooks/auth";
import {useAuthUser, useAuthHeader} from 'react-auth-kit';
import NavBar from './NavBar';
function Dashboard() {
    useFetchAuth();
    useCheckSchool(); 
    useCheckClient();
    const user = useAuthUser()();
    const authHeader = useAuthHeader()

    // Function to filter the latest subscriptions for each client and subscription_name
    const filterLatestSubscriptions = (subscriptions) => {
      const latestSubscriptions = {};

      subscriptions.forEach(subscription => {
        const key = `${subscription.client_ID}-${subscription.subscription_name}`;
        if (!latestSubscriptions[key] || new Date(subscription.date_subscribed) > new Date(latestSubscriptions[key].date_subscribed)) {
          latestSubscriptions[key] = subscription;
        }
      });

      return Object.values(latestSubscriptions);
    }

    // const [lables, setLabels] = useState([]);
    const [searchedData, setSearchedData] = useState([]);
    const [searchedCities, setSearchedCities] = useState([]);

    const {quotations, loading} = useQuotes(authHeader());
    const quotesNum = quotations.length;

    const {subscriptions, subscriptionsLength} = useSubscriptions(authHeader());
    // const subscriptionss = filterLatestSubscriptions(subscriptions);

    const subscription = subscriptionsLength;

    const pendingSchools = useSchoolsStatus(0, authHeader());
    const pendingNum = pendingSchools.schoolsLength;

    const approvedSchools = useSchoolsStatus(1, authHeader());
    const approvedNum = approvedSchools.schoolsLength;

    const {bookings} = useBookings(authHeader());
    const allBookingsNum = bookings.length

    const {video} = useVideo(authHeader());
    const videoNum = video.length;

    const {material} = useStudyMaterial(authHeader());
    const studyMaterialNum = material.length;

    const {client} = useClient(authHeader());

    // Extract emails from client data
    const clientEmails = client.map(clientItem => clientItem.email);

    // Filter out quotations that have corresponding emails in the client data
    const quotationsNotInClient = quotations.filter(quote => !clientEmails.includes(quote.client_email));

    // Create a Map to store unique client_email values and their corresponding data
    const uniqueClientEmailsMap = new Map();

    // Populate the Map with unique client_email values and their corresponding data
    quotationsNotInClient.forEach(quote => {
      if (!uniqueClientEmailsMap.has(quote.client_email)) {
        uniqueClientEmailsMap.set(quote.client_email, quote);
      }
    });

    // Now, uniqueClientEmailsMap contains all the unique client_email values and their corresponding data from quotationsNotInClient
    const uniqueQuotationClients = Array.from(uniqueClientEmailsMap.values());

    const getActiveClients = (array, active) => {
      return array.filter(item => item.status === active);
    };

    const getNonActiveClients = (array, active) => {
      return array.filter(item => item.status === active);
    };



    const activeClients = getActiveClients(client, 1).length;
    const deactiveClients = getNonActiveClients(client, 0).length;
    const unregisteredClients = uniqueQuotationClients.length;


    const getSearchedCities = async () => {
        try {
          const {response, json} = await authenticatedGetRequest('/admin/stats/city', authHeader());
         if(response.status === 200){
            setSearchedCities(json);
         }else if(response.status === 401){
          console.log("access denied");
         }
       } catch (error) {
         console.error(error);
       }
     }

      let dataObj = {}; 
      const [chartData, setChartData] = useState({});
      const getSearchedData = async () => {
          try {
            const {response, json} = await authenticatedGetRequest('/admin/stats/searched', authHeader());
          if(response.status === 200){
              setSearchedData(json);
              // 
              dataObj["searched"] = json;
          }else if(response.status === 401){
            console.log("access denied");
          }
        } catch (error) {
          console.error(error);
        }
      }

     const getSchoolData = async (callback) => {
      try {
        const {response, json} = await authenticatedGetRequest('/admin/stats/schools', authHeader());
       if(response.status === 200){
          dataObj["schools"] = json;
          callback(dataObj)
       }else if(response.status === 401){
        console.log("access denied");
       }
     } catch (error) {
       console.error(error);
     }
   }
   const getChartStats = async() => {
    getSearchedData();
    const callback = async(obj) => {
      setChartData({
        labels: obj.searched.map(data => data.province),
        datasets: [
            {
                label: 'Searched Schools',
                backgroundColor: ['#439A97','#F8F988','#31E1F7','#FFADBC','#6D67E4','#285430','#DC3535','#460C68','rgb(255, 99, 132)'],
                borderColor: ['#439A97','#F8F988','#31E1F7','#FFADBC','#6D67E4','#285430','#DC3535','#460C68','rgb(255, 99, 132)'],
                borderWidth: 2,
                data: await obj.searched.map(data => data.count),
            }
          //   ,
          //   {
          //     label: 'Registered Schools',
          //     backgroundColor: '#5AED44',
          //     borderColor: '#5AED44',
          //     borderWidth: 2,
          //     data: await obj.schools.map(data => data.count),
          // },
        ]
      });
     }
    getSchoolData(callback);
   }
     useEffect(() => {
      getChartStats();
      getSearchedCities();
      }, []);    
    return ( 
      <>
      <NavBar />
      <div className="App">
        
      <div className="dashboard-container">
        
        <div className="dashboard-header">
          <div className="dashboard-links">

          </div>
          <Link className="dashboard-link">
            <MdAddCircle size={35} />
          </Link>
        </div>
        <div className="row">
          <div className="col-sm-3 mt-1">
            <StatusCard title="Pending Driving Schools" number={pendingNum} icon={<MdPendingActions color='green'/>} href={"schools/status/pending"}/>
          </div>
          <div className="col-sm-3 mt-1">
              <StatusCard title="Approved Driving Schools" number={approvedNum} icon={<MdApproval color='#20c997'/>} href={"schools/status/approved"}/>
          </div>
          <div className="col-sm-3 mt-1">
            <StatusCard title="Total Bookings Made" number={allBookingsNum} icon={<MdAddTask color='#6f42c1'/>} href={"bookings/total"}/>
          </div>
          <div className="col-sm-3 mt-1">
            <StatusCard title="Total Leads" number={quotesNum} icon={<MdFilterListAlt color='#dc3545'/>} href={"leads/total"}/>
          </div>          
        </div>  


        <div className="row">
          <div className="col-sm-3 mt-1">
            <StatusCard title="Active Clients" number={activeClients} icon={<MdPendingActions color='green'/>} href={"clients/status/active"}/>
          </div>
          <div className="col-sm-3 mt-1">
              <StatusCard title="Deactive Clients" number={deactiveClients} icon={<MdApproval color='#20c997'/>} href={"clients/status/deactive"}/>
          </div>
          <div className="col-sm-3 mt-1">
            <StatusCard title="Unregistered Clients" number={unregisteredClients} icon={<MdAddTask color='#6f42c1'/>} href={"clients/unregistered"}/>
          </div>
          <div className="col-sm-3 mt-1">
            <StatusCard title="Subscriptions" number={subscription} icon={<MdFilterListAlt color='#6f42c1'/>} href={"test-subscriptions"}/>
          </div>          
        </div> 


        <div className="row">
          <div className="col-sm-3 mt-1">
            <StatusCard title="Training Videos" number={videoNum} icon={<MdPendingActions color='green'/>} href={"training-videos"}/>
          </div>  
          <div className="col-sm-3 mt-1">
              <StatusCard title="Study Material" number={studyMaterialNum} icon={<MdApproval color='#20c997'/>} href={"study-material"}/>
          </div>      
        </div> 

        <div className='row mt-5'>
        {(Object.keys(chartData).length !==0) && 
        <>
          <h5>Recorded Searches</h5>
          <div className="col-lg-7 col-12">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Province</th>
                  <th>City</th>
                  <th>Requests</th>
                </tr>
              </thead>
              <tbody>
                {searchedCities.map(city => (
                <tr key={city.city} style={{textTransform: "capitalize"}}>
                  <td>{city.province}</td>
                  <td>{city.city}</td>
                  <td>{city.count}</td>
                </tr>
                ))}         
              </tbody>
            </Table>
          </div>
          <div className="col-lg-5 col-12" style={{backgroundColor: "white", borderRadius: "8px"}}>
            <PieChart chartData={chartData} />
          </div>
        </>
        }
      </div>      
      </div> 
      </div>
      </>
        
     );
}

export default Dashboard;