import { useEffect, useState } from 'react';
import { authenticatedGetRequest } from '../requests/apiRequests';
function useClient(token) {
  
    const [client,setClient] = useState([]);  
    const [loading, setLoading] = useState(false);       
      const getClient = async () => {
        try {
          setLoading(true); // Set loading before sending API request
          const {response, json} = await authenticatedGetRequest(`/users/client`, token);
          if(response.status !== 404){
            // const result = json[0];
            setClient(json);
          }else{
            setClient({});
          }
        } catch (error) {
          console.error(error);
        }
        setLoading(false); // Stop loading
      }
      useEffect(() => {
        getClient();
      }, []);          
    return ( {client, loading} );
}

export default useClient;