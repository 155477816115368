function shorterText(text, length=18){
    return (text.length > length) ? text.slice(0, length)+"..." : text; 
}
function getMetaData(title, length=18){
  return {
    title: title,
    description: "A plateform for requesting quotations or book learner's test, driving licence and driving lessons in your nearest city",
    canonical: 'https://simplifydrive.co.za/',
    meta: {
        charset: 'utf-8',
        name: {
            keywords: "learner's test,driving licence,driving lessons"
        }
      }   
  }; 
}

function timeSince(date) {
    var aDay = 24*60*60*1000;
    var dateDiff = date - aDay;

    var seconds = Math.floor((new Date() - dateDiff) / 1000);
  
    var interval = seconds / 31536000;
  
    if (interval > 1) {
      return (Math.floor(interval) == 1) ? Math.floor(interval) + " year ago" : Math.floor(interval) + " years ago";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return (Math.floor(interval) == 1) ? Math.floor(interval) + " month ago" : Math.floor(interval) + " months ago";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return (Math.floor(interval) == 1) ? Math.floor(interval) + " day ago" : Math.floor(interval) + " days ago";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return (Math.floor(interval) == 1) ? Math.floor(interval) + " hour ago" : Math.floor(interval) + " hours ago";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return (Math.floor(interval) == 1) ? Math.floor(interval) + " minute ago" : Math.floor(interval) + " minutes ago";
    }
    return (Math.floor(interval) == 1) ? Math.floor(seconds) + " second ago" : Math.floor(seconds) + " seconds ago";
  }

function genRandomPath(strLen){
  return "/" + Math.random().toString(36).substring(2,strLen);
}
function shuffleArray(array){
  return array.sort(() => Math.random() - 0.5);
}
function getPercentage(numerator, denominator){
  return Math.round(numerator/denominator*100*10)/10;
}
  module.exports = {shorterText, timeSince, getMetaData, genRandomPath, shuffleArray, getPercentage};