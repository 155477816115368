import { Link } from 'react-router-dom';
import React, { useState } from "react";
import { getMetaData } from "../../lib/helperFunctions";
import { MdAddCircle } from 'react-icons/md';
import EnhancedTable from "../../components/EnhancedTable";
import useAllBookings from "../../hooks/useAllBookings";
import { useAuthUser, useAuthHeader } from "react-auth-kit";
import { useFetchAuth, useCheckSchool, useCheckClient } from "../../hooks/auth";
import { ADMIN_RELATIVE_PATH } from '../../config/config';
import NavBar from '../client/NavBar';
const styles = {
  body: {
    padding: "100px 50px 50px 50px",
  },
};

function Bookings() {
  useFetchAuth();
  useCheckSchool(); 
  useCheckClient();
  
  const user = useAuthUser()();
  const authHeader = useAuthHeader();
  const [currentPage, setCurrentPage] = useState(1);
  const meta = getMetaData("Admin | Partner with SimplifyDrive");
  const { bookings, loading } = useAllBookings(authHeader());
  console.log(bookings)
  
  function handlePageChange(pageNumber) {
    setCurrentPage(pageNumber);
  }
  const onDeleteBooking = async (id) => {

   

  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
  
    const options = {
      // weekday: "long",
      day: "numeric",
      month: "long",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
  
    if (!isNaN(date)) {
      // Check if the date is valid
      return date.toLocaleDateString(undefined, options);
    } else {
      // If the date is not valid, return it as is
      return dateString;
    }
  };

  const bookingsData = (bookings, onDeleteBooking) => {
    return bookings.map((book, i) => ({
      id: i + 1,
      type: book.type,
      price: `R ${book.price_booked_at}`,
      date_booked: formatDate(book.date_created),
      payment: (
        <>
          {book.paid === 1 ? (
            <button className="btn btn-outline-success border border-success m-1">
              <i className="fa fa-paper-plane" aria-hidden="true"></i> Paid In Full
            </button>
          ) : (
            <button className="btn btn-outline-danger border border-danger m-1">
              <i className="fa fa-paper-plane" aria-hidden="true"></i> Not Paid
            </button>
          )}
        </>),
      actions: (
        <>
          <Link to={`${ADMIN_RELATIVE_PATH}/edit-booking/${book.booking_ID}`} className="btn btn-outline-primary border border-primary m-1"><i className="fa fa-eye" aria-hidden="true"></i> View</Link>
          <button onClick={() => onDeleteBooking(book.booking_ID)} className="btn btn-outline-danger border border-danger m-1"><i className="fa fa-trash"  aria-hidden="true"></i> Delete</button>
        </>
      ),
    }));
  };
  return (
    <>
    <NavBar/>
    <div className="App">
      
      <div className="dashboard-container">
        
        <div className="dashboard-header">
          <div className="dashboard-links">
          <div className="dashboard-links">
            <Link to={ADMIN_RELATIVE_PATH} className="dashboard-link">
              Dashboard
            </Link>
            <span className="dashboard-separator">/</span>
            <Link className="current-link">
            Bookings
            </Link>
          </div>

          </div>
          <Link className="dashboard-link">
            <MdAddCircle size={35} />
          </Link>
        </div>
      <EnhancedTable 
        data={bookingsData(bookings, onDeleteBooking)}
        columns={["ID", "Type", "Price",  "Date_Booked", "Payment", "Actions"]}
        title="Bookings"
        currentPage={currentPage}
        onPageChange={handlePageChange}
      
      />
    </div>
    </div>
    </>
    
  );
}

export default Bookings;
