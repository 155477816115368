import React, { useState } from "react";
import Navigation from "../../components/Navigation";
import Button from "../../components/CustomButton";
import CustomModal from "../../components/CustomModal";
import WhatsAppGroupDetails from '../../components/WhatsAppGroupDetails';
import { useIsAuthenticated, useSignOut } from 'react-auth-kit';
import { useNavigate } from "react-router-dom";
import { SCHOOL_RELATIVE_PATH, CLIENT_RELATIVE_PATH, BASE_URL } from "../../config/config";

function NavBar() {
    const BASE_RELATIVE_PATH = BASE_URL;
    const isAuthenticated = useIsAuthenticated()();
    const signOut = useSignOut();
    const navigate = useNavigate();
    const [showWGModal, setShowWGModal] = useState(false);

    const handleWGModal = () => {
        setShowWGModal(prev => !prev);
    }

    const handleSendWhatsApp = () => {
        handleWGModal();
    };

    const logout = () => {
        signOut();
        navigate(`/login`);
    }

    const dropDown1 = {
        title: "Learner's Tests",
        dropDownList: [
            { url: "/learners-tests/test1", itemName: "Code 8" },
            { url: "/learners-tests/test2", itemName: "Code 10 & 14" },
        ]
    };

    const menuItems = {
        hasDropDown: true,
        leftItems: !isAuthenticated ? [{ item: "Privacy Policy", url: `${BASE_RELATIVE_PATH}/client-data-policy` }] : [{ item: "Dashboard", url: `${BASE_RELATIVE_PATH}/dashboard` }],
        rightItems: !isAuthenticated ? [
            { item: dropDown1, url: "/learners-tests/test1", isDropDown: true },
            { item: "Training Videos", url: `${BASE_URL + CLIENT_RELATIVE_PATH}/training-videos` },
            { item: "Join WhatsApp Group", onClick: handleSendWhatsApp },
            { item: "Driving School App", url: `${BASE_URL + SCHOOL_RELATIVE_PATH}/login` },
            { item: "Login", url: "/login" }
        ] : [
            { item: dropDown1, url: "/learners-tests/test1", isDropDown: true },
            { item: "Training Videos", url: `${BASE_URL + CLIENT_RELATIVE_PATH}/training-videos` },
            { item: "Join WhatsApp Group", onClick: handleSendWhatsApp },
            { item: <Button onClick={logout} className="custom-button" text="Log Out" />, url: "" }
        ]
    }

    return (
        <>
            <Navigation menuItems={menuItems} />
            <CustomModal showModal={showWGModal} handleClose={handleWGModal} title="Join WhatsApp Group" bodyContent={<WhatsAppGroupDetails onSubmit={() => { setShowWGModal(false); }} />} />
        </>
    );
}

export default NavBar;
