import React, { useState } from "react";
import Input from "./Input";
import Select from "./Select";
import CustomButton from "./CustomButton";
import Loader from "./Loader";
import { authenticatedFilesPostRequest } from "../requests/apiRequests";
import { Link, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuthUser, useAuthHeader } from "react-auth-kit";
import { ADMIN_RELATIVE_PATH } from "../config/config";
const styles = {
  body: {
    display: "flex",
    flexDirection: "column",
    width: "40vw",
    backgroundColor: "white",
    borderRadius: "8px",
    padding: "20px 15px",
    margin: "30vh",
  },
  rightButton: {
    marginLeft: "5px",
  },
};
const StudyMaterialForm = () => {
  const categories = [
    "Rules of the road",
    "Road signs",
    "Road markings",
    "Vehicle controls",
    "Signals",
  ];

  
  const formData = new FormData();
  const [formFields, setFormFields] = useState({ category: categories[0] });
  const [file, setFile] = useState([]);
  const [loading, setLoading] = useState("");
  const user = useAuthUser()();
  const authHeader = useAuthHeader();

  const handleChange = (event) => {
    setFormFields({ ...formFields, [event.target.name]: event.target.value });
    // formData.append(event.target.name, event.target.value);
  };

  const handleFileInputChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleSubmit = async (e) => {
    setLoading(true);

    formData.append("filename", file);
    Object.entries(formFields).forEach(([key, value]) => {
      formData.append(key, value);
    });

    try {
      const response = await authenticatedFilesPostRequest(
        "/study-material",
        formData,
        authHeader()
      );
      console.log(response);

      if (response.status === 200) {
        setLoading(false);
        toast.success("Study material added successfully!", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
        });
      } else {
        toast.error("There was an error in adding the study material!", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  // loading
  if (loading) {
    return (
      <div className="App">
        <Loader />
      </div>
    );
  }

  return (
    <>
      <ToastContainer />
      <div style={styles.body}>
        <h4 className="text-center">Add Study Material</h4>
        <div className="dashboard-header">
          <div className="dashboard-links">
            <Link to={ADMIN_RELATIVE_PATH} className="dashboard-link">
              Dashboard
            </Link>
            <span className="dashboard-separator">/</span>
            <Link
              to={`${ADMIN_RELATIVE_PATH}/study-material`}
              className="current-link"
            >
              Study Material
            </Link>
            <span className="dashboard-separator">/</span>
            <Link className="current-link">Add study material</Link>
          </div>
        </div>
        <hr />

        <Input
          label="Title"
          name="title"
          type="text"
          placeholder="Enter Title"
          required
          onChange={handleChange}
          value={formFields.title || ""}
        />

        <Select
          itemsArray={categories}
          name="category"
          value={formFields.category}
          onChange={handleChange}
          label="Select Category"
        />

        <Input
          label="Study Material File"
          type="file"
          name="file"
          onChange={handleFileInputChange}
        />

        <div className="d-flex">
          <CustomButton
            text="Add Study Material"
            color={`primary`}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </>
  );
};

export default StudyMaterialForm;
