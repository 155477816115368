import { useState, useEffect } from 'react';
import { Form } from "react-bootstrap";
import Button from "./CustomButton";
import Chip from './Chip';
import { toast } from 'react-toastify';
import { authenticatedPostRequest } from '../requests/apiRequests';
import { useAuthHeader } from 'react-auth-kit';

const styles = { 
    formContainer: {maxWidth: "500px"},
    heading: {textAlign: "center", marginBottom: "30px"}  
}    

function MessageForm({ recipients, onDelete, onSubmit }) {
    const authHeader = useAuthHeader();

    const [message, setMessage] = useState("");
    const [send_sms, setSMS] = useState(false);
    const [send_email, setEmail] = useState(false);
    const [myContacts, setMyContacts] = useState([]);
    const [myEmails, setMyEmails] = useState([]);

    useEffect(() => {
        // Update myContacts and myEmails arrays when recipients prop changes
        const contacts = recipients.map(recipient => recipient.cell_number);
        const emails = recipients.map(recipient => recipient.client_email ? recipient.client_email : recipient.email );
        setMyContacts(contacts);
        setMyEmails(emails);
    }, [recipients]);

    const handleChange = (e) => {
        const { name, checked } = e.target;
        if (name === 'sms') {
            setSMS(checked);
        } else if (name === 'email') {
            setEmail(checked);
        }
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        const obj = {
            message: message,
            send_sms: send_sms,
            send_email: send_email,
            recipients: recipients
        }

        // const obj = {
        //     message: message,
        //     send_sms: send_sms,
        //     send_email: send_email,
        //     myContacts: myContacts,
        //     myEmails: myEmails
        // }

        try {
            const response = await authenticatedPostRequest('/message', obj, authHeader());

            console.log(response)

            if (response.status === 200) {
                toast.success(`Message sent to recipients!`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000
                });
            } else if(response.status === 222){
                toast.success(`Only SMS message sent to recipients!`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000
                });
            } else if(response.status === 223){
                toast.success(`Only email message sent to recipients!`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000
                });
            } else {
                toast.error(`Failed to send message to recipients`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000
                });
            }
            onSubmit();
        } catch (error) {
            console.error('Error while sending requests:', error);
            toast.error('An error occurred while sending requests', {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 5000
            });
        }
    };

    return (
        <>
            <div style={styles.formContainer}>
                <Form>
                    <Form.Group controlId="message" className="mb-3">
                        <Form.Label><b>Message:</b></Form.Label><br />
                        <Form.Label><b>Use [name] for name:</b></Form.Label>
                        <Form.Control as="textarea" rows={3} name="message" required onChange={(e) => setMessage(e.target.value)} value={message} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label><b>Contact Methods:</b></Form.Label>
                        <Form.Check
                            type="checkbox"
                            label="SMS"
                            name="sms"
                            checked={send_sms}
                            onChange={handleChange}
                        />
                        <Form.Check
                            type="checkbox"
                            label="Email"
                            name="email"
                            checked={send_email}
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <hr />
                    {recipients.map((recipient, index) =>
                        <Chip text={recipient.client_name ? recipient.client_name : recipient.name} onClick={() => onDelete(index)} key={index} />
                    )}
                    <hr />
                    <Button disabled={!message || !(send_sms || send_email)} onClick={handleSubmit} text="Send Message" />
                </Form>
            </div>
        </>
    );
}

export default MessageForm;
