import React from 'react';
import { Container } from 'react-bootstrap';
import { useAuthHeader } from 'react-auth-kit';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ImageGallery } from 'react-image-grid-gallery';

const styles = {
    formContainer: { width: "100%", padding: "0 0px" },
    heading: { textAlign: "center", marginBottom: "0px" },
};

const testimonialImages = Array.from({ length: 29 }, (_, i) => ({
    src: require(`../assets/testimonials/certificates/${i + 1}.jpg`),
    alt: `Testimonial ${i + 1}`,
    caption: `This is testimonial ${i + 1}`,
}));

function GridTestimony() {
    const authHeader = useAuthHeader();

    const handleImageClick = (image) => {
        window.open(image, "_blank");
    };

    return (
        <Container fluid style={styles.formContainer}>
            <h2 className='mb-3' style={styles.heading}>Our Happy Customers</h2>
            <ImageGallery
                imagesInfoArray={testimonialImages}
                columnCount={"auto"}
                columnWidth={230}
                gapSize={24}
                onClick={(image) => handleImageClick(image.src)}
            />
        </Container>
    );
}

export default GridTestimony;
