import * as React from 'react';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import {Box, SpeedDial, SpeedDialIcon, SpeedDialAction} from '@mui/material';
function FloatButton({actions, onClick}) {
    return (
        // height: 0, transform: 'translateZ(0px)', flexGrow: 1
        <Box sx={{ position: 'fixed', top: 0,right: 0  }}>
            <SpeedDial
            ariaLabel="SpeedDial basic example"
            sx={{ position: 'fixed', bottom: '10vh', right: 16 }}
            icon={<AppRegistrationIcon />}
            >
            {actions.map((action) => (
                <SpeedDialAction
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.name}
                onClick={onClick}
                />
            ))}
            </SpeedDial>
        </Box>        
     );
}

export default FloatButton;



