import { useState, useEffect } from 'react';
import NavBar from "./NavBar";
import { useIsAuthenticated, useAuthUser, useAuthHeader } from 'react-auth-kit';
import useVideo from "../../hooks/useVideo";
import useSubscription from "../../hooks/useSubscription";
import useStudyMaterial from "../../hooks/useStudyMaterial";
import {useParams} from "react-router-dom";
import { Container } from "react-bootstrap";
import Videos from '../../components/Videos';
import GridTestimony from '../../components/GridTestimony';
import {getMetaData} from "../../lib/helperFunctions";
import DocumentMeta from "react-document-meta";

function LearningVideos() {
  const meta = getMetaData("Learner's Training Videos");
  const params = useParams();
  let code = (params.hasOwnProperty("code") ? params.code : "Code 8");
  const [isLoading, setIsLoading] = useState(true);
  const isAuthenticated = useIsAuthenticated()();
  const user = useAuthUser()();
  const authHeader = useAuthHeader();

  const [subscription, setSubscription] = useState(false);
  const [daysLeft, setDaysLeft] = useState();
  const [done, setDone] = useState(false);


  const {video} = useVideo(authHeader());
  const videos = video;

  let userId
  if(isAuthenticated){
    userId = user?.userID; 
  }
  const { subscriptions } = useSubscription(userId, "Training Videos", authHeader());
  const { material } = useStudyMaterial(authHeader());
  useEffect(() => {
    try {
      
      if(subscriptions){
        if (subscriptions.client_ID === userId) {
          
          const subscriptionDate = new Date(subscriptions.date_subscribed);
          const currentDate = new Date();
          const differenceInMilliseconds = currentDate - subscriptionDate;
          const differenceInDays = differenceInMilliseconds / (1000 * 3600 * 24);
          const daysLeft = 30 - Math.floor(differenceInDays);
          setDaysLeft(daysLeft);

          if(differenceInDays <= 30 && subscriptions.paid === 1){
            setSubscription(true);
          }
        }
    
      }else{
        setDaysLeft(0) 
      }

      setDone(true)
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }

    }, [subscriptions]);
    console.log("ran after...");
      if (!done || daysLeft === undefined) {
        return null; // or you can return a loading indicator or any other component
      }
    if (isLoading) {
      return <div>Loading...</div>; // Render a loading indicator
    }
   
      return (
        <>
          <NavBar/>
          <div className="App">
          <DocumentMeta {...meta} />
          <Videos videos={videos} code={code} subscription={subscription} daysLeft={daysLeft} material={material} />
          {daysLeft <= 0 || subscription === false &&  (

            <Container className="container-custom">
              <GridTestimony slidesToShow={4} />
            </Container>
          )}
          
          </div>
        </>
        
      );
  }
  
  export default LearningVideos;