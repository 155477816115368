import { useEffect, useState } from 'react';
import { authenticatedGetRequest } from '../requests/apiRequests';

function useProducts(token) {
    const [products, setProducts] = useState([]); // Initialize as an empty array
    const [loading, setLoading] = useState(false);

    const getProducts = async () => {
        setLoading(true); // Set loading before sending API request
        try {
            const { response, json } = await authenticatedGetRequest(`/products`, token);
            if (response.status !== 404) {
                setProducts(json);
            } else {
                setProducts([]); // Set to empty array on 404
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false); // Stop loading
        }
    };

    useEffect(() => {
        getProducts();
    }, [token]); // Add token to the dependency array

    return { products, loading };
}

export default useProducts;
