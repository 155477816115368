import { useState } from 'react';
import {Form} from "react-bootstrap";
import CustomButton from "./CustomButton";
import { postRequest } from '../requests/apiRequests';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const styles = {
    formContainer: {maxWidth: "500px"},
    heading: {textAlign: "center", marginBottom: "30px"} 
}    
function QuoteForm({school_id, isLicence, onSubmit}) {
    const [formData, setFormData] = useState({school_ID:school_id, type: isLicence?"licence":"learners"});
    const [loading, setLoading] = useState(false);
    const handleChange = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
      };
      const handleSubmit = async(e) => {
        e.preventDefault();
        try {
            // console.log(formData);
            setLoading(true);

            let mobileNumber = formData.cell_number || '';
            if (mobileNumber.startsWith('0') && mobileNumber.length === 10) {
                mobileNumber = '27' + mobileNumber.slice(1); // Replace the leading '0' with '27'
            }
    
            if (!/^(?!\+)(\b27\d{9}\b)$/.test(mobileNumber)) {
            toast.error('Please enter a valid SA mobile number starting with 0 or the country code.', {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 5000
            });
            return
            }

            const response = await postRequest('/quote', formData);
            console.log(response);
            if(response.status === 200){
                setLoading(false);
                toast.success("Quotation sent to your email!", {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000
                });
              }else{
                setLoading(false);
                toast.error("Failed to generate quotation!", {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000
                });
              }
            // if(response.status !== 404){
            //     setFormData(json);
            //   }else{
            //     setFormData([]);
            //   }
             // Delay the execution of onSubmit by 5 seconds
            setTimeout(() => {
                onSubmit(); // hide modal after 5 seconds
            }, 5000);
        } catch (error) {
            console.error(error);
          }
          //setLoading(false); // Stop loading
        }    
    return ( 
        <>
        <ToastContainer />
        <div style={styles.formContainer}>
            {/* <h3 style={styles.heading}>{title} Quotation</h3> */}
            <Form>
                <Form.Group controlId="name" className="mb-3">
                    <Form.Label>Name:</Form.Label>
                    <Form.Control type="text" name="client_name" required placeholder="Name" onChange={handleChange} value={formData.client_name || ''}/>
                </Form.Group>
                <Form.Group controlId="contacts:" className="mb-3">
                    <Form.Label>Cell Number:</Form.Label>
                    <Form.Control type="text" name="cell_number" required placeholder="Cell Number" onChange={handleChange} value={formData.cell_number || ''}/>
                    {
                    (() => {
                    let mobileNumber = formData.cell_number || '';
                    if (mobileNumber.startsWith('0') && mobileNumber.length === 10) {
                        mobileNumber = '27' + mobileNumber.slice(1); // Replace the leading '0' with '27'
                    }

                    return (
                        mobileNumber.length > 0 && !/^(?!\+)(\b27\d{9}\b)$/.test(mobileNumber) &&
                        <p className="alert alert-warning">
                        Please enter a valid SA mobile number starting with 0 or the country code.
                        </p>
                    );
                    })()
                }
                </Form.Group>
                <Form.Group controlId="email" className="mb-3">
                    <Form.Label>Email Address:</Form.Label>
                    <Form.Control type="text" name="client_email" required placeholder="Email" onChange={handleChange} value={formData.client_email || ''}/>
                </Form.Group>
                {/* {isLicence && 
                <Form.Group controlId="licence" className="mb-3">
                    <Form.Label>Select Licence Code:</Form.Label>
                    <select  className="form-select" required onChange={handleChange} name="code" value={formData.code || ''}>
                        <option value="Code 8">Code 08 - C</option>
                        <option value="Code 10">Code 10 - C1</option>
                        <option value="Code 14">Code 14 - EC</option>
                        <option value="PDP">PrDP</option>
                    </select>
                </Form.Group>
                } */}
                <hr/>
                <CustomButton disabled={loading} text="Request Quote" size="md" onClick={handleSubmit}/>      
            </Form>
        </div>  
        </>
              
     );
}

export default QuoteForm;