import { useEffect, useState } from 'react';
import { getRequest } from '../requests/apiRequests';
function useSchool(id) {
    const [school,setSchool] = useState({});  
    const [sliders,setSliders] = useState([]); 
    const [loading, setLoading] = useState(false);       
    const getSchool = async () => {
        try {
          setLoading(true); // Set loading before sending API request
          const {response, json} = await getRequest(`/schools/${id}`);
          if(response.status !== 404){
            const result = json[0];
            setSchool(result);
            //get slider images
            setSliders(result.slider_pics.split(","));
          }else{
            setSchool({});
          }
        } catch (error) {
          console.error(error);
        }
        setLoading(false); // Stop loading
      }
      useEffect(() => {
        getSchool();
      }, []);          
    return ( {school, sliders, loading} );
}

export default useSchool;