import { Link } from 'react-router-dom';
import React, { useState } from "react";
import { getMetaData } from "../../lib/helperFunctions";
import { MdAddCircle } from 'react-icons/md';
import EnhancedTable from "../../components/EnhancedTable";
import useQuotes from "../../hooks/useQuotes";
import { useAuthUser, useAuthHeader } from "react-auth-kit";
import { useFetchAuth, useCheckSchool, useCheckClient } from "../../hooks/auth";
import { ADMIN_RELATIVE_PATH } from '../../config/config';
import NavBar from './NavBar';
const styles = {
  body: {
    padding: "100px 50px 50px 50px",
  },
};

function AdminQuotes() {
  
  useFetchAuth();
  useCheckSchool(); 
  useCheckClient();

  const user = useAuthUser()();
  const authHeader = useAuthHeader();
  const [currentPage, setCurrentPage] = useState(1);
  const meta = getMetaData("Admin | Partner with SimplifyDrive");
  const {quotations, loading} = useQuotes(authHeader());
  
  function handlePageChange(pageNumber) {
    setCurrentPage(pageNumber);
  }
  const onDeleteQuotation = async (id) => {

   

  }

  const quotationsData = (quotations, onDeleteQuotation) => {
    return quotations.map((quotation, i) => ({
      id: i + 1,
      name: quotation.client_name,
      school: quotation.school_name,
      cell: quotation.cell_number,
      email: quotation.client_email,
      type: quotation.type,
      actions: (
        <>
          <button onClick={() => onDeleteQuotation(quotation.school_ID)} className="btn btn-outline-danger border border-danger m-1"><i className="fa fa-trash"  aria-hidden="true"></i> Delete</button>
        </>
      ),
    }));
  };
  return (
    <>
    <NavBar/>
      <div className="App">
      <div className="dashboard-container">
        
        <div className="dashboard-header">
          <div className="dashboard-links">
          <div className="dashboard-links">
            <Link to={ADMIN_RELATIVE_PATH} className="dashboard-link">
              Dashboard
            </Link>
            <span className="dashboard-separator">/</span>
            <Link className="current-link">
            Quotations
            </Link>
          </div>

          </div>
          <Link className="dashboard-link">
            <MdAddCircle size={35} />
          </Link>
        </div>
      <EnhancedTable 
        data={quotationsData(quotations, onDeleteQuotation)}
        columns={["ID", "Name", "School", "Cell", "Email", "Type", "Actions"]}
        title="Quotations"
        currentPage={currentPage}
        onPageChange={handlePageChange}
      
      />
    </div>
    </div>
    </>
    
  );
}

export default AdminQuotes;
