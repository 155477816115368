import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { Form, Container, Row, Col, Table } from "react-bootstrap";
import CustomButton from "./CustomButton";
import { toast } from "react-toastify";
import useClient from "../hooks/useClient";
import usePackage from "../hooks/usePackage";
import { authenticatedFilesPostRequest } from "../requests/apiRequests";
import { useAuthUser, useAuthHeader } from "react-auth-kit";
import Card from "react-bootstrap/Card";
import crypto from "crypto-browserify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons"; // Update the import to use regular icons

const styles = {
  formContainer: { maxWidth: "80vw", paddingTop: 10 },
  packageContainer: { marginBottom: "20px" },

  body: {
    padding: "100px 50px 50px 50px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
  heading: {
    fontSize: "36px",
    color: "#fa0505", // Text color for canceled message
    marginBottom: "20px",
  },
  description: {
    fontSize: "18px",
    marginBottom: "20px",
  },
  icon: {
    fontSize: "48px",
    color: "#fa0505", // Color of the cancel icon
    marginBottom: "20px",
  },
  error: {
    color: "red",
  },
};

function BookingForm(props) {
  const formData = new FormData();

  const { title } = props;

  const { schoolID } = useParams();
  const { packageID } = useParams();
  const { packageType } = useParams();
  const { bookingID } = useParams();
  const isbookingIDSet = bookingID !== undefined ? bookingID : 0;

  const user = useAuthUser()();
  const authHeader = useAuthHeader();

  const formattedDate = new Date().toISOString().substring(0, 10);
  const [step, setStep] = useState(1);
  const [name, setName] = useState("");
  const [fname, setFName] = useState("");
  const [lname, setLName] = useState("");
  const [cell, setCell] = useState("");
  const [email, setEmail] = useState("");
  const [type, setType] = useState("");
  const [school, setSchool] = useState(null);
  const [schoolName, setSchoolName] = useState("");
  const [supportDoc, setSupportDoc] = useState("");
  const [price, setPrice] = useState(0);
  const [date, setDate] = useState(formattedDate);
  const [selectedDays, setSelectedDays] = useState([" "]);
  const [rangeStartDate, setRangeStartDate] = useState(null);
  const [rangeEndDate, setRangeEndDate] = useState(null);
  const [rangeStartTime, setRangeStartTime] = useState(null);
  const [rangeEndTime, setRangeEndTime] = useState(null);
  const [errors, setErrors] = useState({});

  const userId = user?.userID;

  const { client } = useClient(authHeader());
  const { packag } = usePackage(authHeader(), schoolID, packageType, packageID);

  const generateSignature = (data, passPhrase = null) => {
    // Create parameter string
    let pfOutput = "";
    for (let key in data) {
      if (data.hasOwnProperty(key)) {
        if (data[key] !== "") {
          pfOutput += `${key}=${encodeURIComponent(data[key].trim()).replace(
            /%20/g,
            "+"
          )}&`;
        }
      }
    }

    // Remove the last ampersand
    let getString = pfOutput.slice(0, -1);
    if (passPhrase !== null) {
      getString += `&passphrase=${encodeURIComponent(passPhrase.trim()).replace(
        /%20/g,
        "+"
      )}`;
    }

    return crypto.createHash("md5").update(getString).digest("hex");
  };

  // Initialize myData only once using useRef
  const myDataRef = useRef(null);

  useEffect(() => {
    const myClient = client.find((client) => client.user_ID == userId);
    if (myClient) {
      setName(myClient.name + " " + myClient.surname);
      setFName(myClient.name);
      setLName(myClient.surname);
      setCell(myClient.cell_number);
      setEmail(myClient.email);
    }

    const mypPackage = packag.find((packag) => packag.package_ID == packageID);
    if (mypPackage) {
      setSchool(mypPackage.school_ID);
      setSchoolName(mypPackage.school_name);
      setPrice(mypPackage.price);
      // setPrice(mypPackage.price + (mypPackage.price*0.15));
      if (packageType != "lesson") {
        setType(mypPackage.test_type);
      } else {
        setType(mypPackage.type);
      }
    }

    if (!myDataRef.current && price) {
      const datenow = Date.now();

      // merchant_id: "10000100",
      // merchant_key: "46f0cd694581a",

      myDataRef.current = {
        merchant_id: "12920595",
        merchant_key: "tm1f3kg4naztk",
        return_url: "https://simplifydrive.co.za/client/return/",
        cancel_url: "https://simplifydrive.co.za/client/cancel/",
        notify_url: "https://simplifydrive.co.za/api/payfast/notify",
        name_first: fname,
        name_last: lname,
        email_address: email,
        m_payment_id: String(datenow),
        amount: String(price.toFixed(2)),
        item_name: "#" + datenow,
        custom_str1: String(isbookingIDSet),
        custom_str2: "Booking",
      };

      // const myPassphrase = "jt7NOE43FZPn";
      const myPassphrase = "SimplestDrive_1";
      myDataRef.current.signature = generateSignature(
        myDataRef.current,
        myPassphrase
      );
    }
  }, [client, packag, userId, price]);

  const nextStep = () => setStep((prev) => prev + 1);

  const prevStep = () => setStep((prev) => prev - 1);

  const handleSupportDoc = (event) => {
    setSupportDoc(event.target.files[0]);
  };

  const handleDaysChange = (event) => {
    const selected = Array.from(
      event.target.selectedOptions,
      (option) => option.value
    );
    setSelectedDays(selected);
  };

  const packageDetails1 = [{ item: type, price: price }];

  // const packageDetails1 = [
  //   { item: type, price: price, discount: "0.00", vat: (0.15 * price)},
  // ];

  const packageDetails2 = [
    { item: "Item 4", price: "120.00", vat: "18.00", discount: "12.00" },
    { item: "Item 5", price: "65.00", vat: "9.75", discount: "6.50" },
    { item: "Item 6", price: "90.00", vat: "13.50", discount: "9.00" },
  ];

  const validate = () => {
    const errors = {};

    // Validate supportDoc
    if (packageType !== "lesson") {
      if (!supportDoc) {
        errors.supportDoc = "Select Supporting Document";
      }

      setErrors(errors);
    }

    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    if (isbookingIDSet) {
      nextStep();
    } else if (validate()) {
      const currentTime = new Date();
      const currentDate = new Date(); // This initializes currentDate with the current date and time

      const formFields = {
        client_ID: userId,
        school_ID: school,
        school_name: schoolName,
        client_email: email,
        client_name: name,
        package_ID: packageID,
        type: type.trim(),
        m_payment_id: myDataRef.current.m_payment_id,
        price_booked_at: price.toFixed(2),
        date_created: new Date().toISOString().slice(0, 19).replace("T", " "),
        // datee: currentDate.toISOString().slice(0, 10), // Format current date as "YYYY-MM-DD"
        // timee: currentTime.toLocaleTimeString(), // Format current time as "HH:MM:SS" (adjust locale as needed)
        days: selectedDays.join(", "),
        start_date: rangeStartDate,
        end_date: rangeEndDate,
        start_time: rangeStartTime,
        end_time: rangeEndTime,
      };

      formData.append("ID_copy", supportDoc);
      Object.entries(formFields).forEach(([key, value]) => {
        formData.append(key, value);
      });

      try {
        const response = await authenticatedFilesPostRequest(
          `/bookings/`,
          formData,
          authHeader()
        );
        console.log(response);

        if (response.status === 200 || response.status === 201) {
          nextStep();
        } else {
          toast.error("There was an error in booking! " + response.status, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
          });
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  switch (step) {
    case 1:
      return (
    <>
      <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <Card style={{ display: 'flex',  width: '100vw', // Default width for mobile
        height: '100%', padding: '20px 10px', 
        margin: '10px 10px',
        // Media query for desktops and laptops
        '@media (min-width: 768px)': {
            width: '60vw', // Adjusted width for desktops and laptops
        }
        }}>
            <h2>{type.charAt(0).toUpperCase() + type.slice(1)} Booking</h2>
            <Form.Group controlId="name" className="mb-3">
              <Form.Label>Name:</Form.Label>
              <Form.Control type="text" value={name} required placeholder="Name" />
            </Form.Group>
            <Form.Group controlId="contacts:" className="mb-3">
              <Form.Label>Cell Number:</Form.Label>
              <Form.Control type="text" value={cell} required placeholder="Cell Number" />
            </Form.Group>
            <Form.Group controlId="email" className="mb-3">
              <Form.Label>Email Address:</Form.Label>
              <Form.Control type="text" value={email} required placeholder="Email" />
            </Form.Group>

            { !isbookingIDSet ? 
            (
            <>
                  <Form.Group controlId="selectedDays" className="mb-3">
                    <Form.Label>Select Days (optional)</Form.Label>
                    <Form.Control as="select" multiple onChange={handleDaysChange}>
                        <option value="Monday">Monday</option>
                        <option value="Tuesday">Tuesday</option>
                        <option value="Wednesday">Wednesday</option>
                        <option value="Thursday">Thursday</option>
                        <option value="Friday">Friday</option>
                    </Form.Control>
                </Form.Group>
                <Form.Group controlId="rangeDate" className="mb-3">
                    
                    <Row>
                        <Col>
                        <Form.Label>Start Date (optional)</Form.Label>
                        <Form.Control
                            type="date"
                            placeholder="Start Date"
                            value={rangeStartDate}
                            onChange={(e) => setRangeStartDate(e.target.value)}
                        />
                        </Col>
                        <Col>
                        <Form.Label>End Date (optional)</Form.Label>
                        <Form.Control
                            type="date"
                            placeholder="End Date"
                            value={rangeEndDate}
                            onChange={(e) => setRangeEndDate(e.target.value)}
                        />
                        </Col>
                    </Row>
                </Form.Group>

                {packageType !== "lesson" ? (
                <Form.Group controlId="supportDoc" className="mb-3">
                    <Form.Label>Support Document:</Form.Label>
                    <Form.Control type="file" required onChange={handleSupportDoc} />
                    {errors.supportDoc && ( <span style={styles.error}>{errors.supportDoc}</span> )}
                </Form.Group>
                
                ) : (
                <>
                <Form.Group controlId="rangeTime" className="mb-3">
                    <Row>
                        <Col>
                        <Form.Label>Start Time:</Form.Label>
                        <Form.Control
                            type="time"
                            placeholder="Start Time"
                            value={rangeStartTime}
                            required
                            onChange={(e) => setRangeStartTime(e.target.value)}
                        />
                        </Col>
                        <Col>
                        <Form.Label>End Time:</Form.Label>
                        <Form.Control
                            type="time"
                            placeholder="End Time"
                            value={rangeEndTime}
                            required
                            onChange={(e) => setRangeEndTime(e.target.value)}
                        />
                        </Col>
                    </Row>

                    </Form.Group>
                </>
                )}
            
            </>
            ) : (<></>) }
            <CustomButton text={!isbookingIDSet ? "Submit" : "Confirm details"} style={styles.rightButton} onClick={handleSubmit} />
        </Card>
      </Container>
    </>
    
    )
     case 2:
       return (
         <>
            <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                {/* <h1 style={styles.heading}>{title} Make payment</h1> */}
                <Card style={{ display: 'flex',  width: '100vw', // Default width for mobile
                  height: '100%', padding: '20px 10px', 
                  margin: '10px 10px',
                  // Media query for desktops and laptops
                  '@media (min-width: 768px)': {
                      width: '60vw', // Adjusted width for desktops and laptops
                  }
                  }}>
                <h2>Package Details</h2>
                    <Table striped bordered hover>
                    <thead>
                        <tr>
                        <th>Item</th>
                        <th>Price</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(
                        packageDetails1.map((detail, index) => (
                            <tr key={index}>
                            <td>{detail.item}</td>
                            <td>{`R ${detail.price } `}</td>
                            </tr>
                        ))) }
                    </tbody>
                    </Table>

                {
                (() => {
                    let htmlForm = `<form action="https://www.payfast.co.za/eng/process" method="post">`;
                    for (let key in myDataRef.current) {
                    if (myDataRef.current.hasOwnProperty(key)) {
                        const value = String(myDataRef.current[key]);
                        if (value !== "") {
                        htmlForm += `<input name="${key}" type="hidden" value="${value.trim()}" />`;
                        }
                    }
                    }
                    htmlForm += '<input type="submit" class="btn btn-danger" value="Complete payment" /></form>';
                    return <div dangerouslySetInnerHTML={{ __html: htmlForm }} />;
                })()
                }
              </Card>
            </Container>
         </>
        )
    default:
      return (<>

      <div style={styles.body}>
            <FontAwesomeIcon icon={faTimesCircle} style={styles.icon} />
            <h1 style={styles.heading}>No Such Package Found</h1>
            <p style={styles.description}>
              Please make sure to check the URL and try again.
            </p>
            {/* Additional content or details can be added here */}
          </div>
      
      </>);
    }
}

export default BookingForm;
