import CustomButton from './CustomButton';
import {Modal} from 'react-bootstrap';
function CustomModal({showModal, title, bodyContent, handleClose}) {
    return ( 
        <Modal show={showModal} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title style={{textTransform: "capitalize"}}>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {bodyContent} 
            </Modal.Body>
        </Modal>        
     );
}

export default CustomModal;